import { Card, CardBody, CardHeader, CardTitle, StackLayout } from "@progress/kendo-react-layout";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { withRouter } from "../../providers/FSTF.WithRouter";
import { useContext, useEffect, useState } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
import { useNavigate } from "react-router-dom";
import IFSProduct from "../../types/IFSProduct";
import IFSAppDownload from "../../types/IFSAppDownload";
import IFSDocumentation from "../../types/IFSDocumentation";
import { Button } from "@progress/kendo-react-buttons";
import { fileConfigIcon, filePdfIcon, fileZipIcon } from '@progress/kendo-svg-icons';
import { IFSLicensePack } from "../../types/IFSLicensePack";
import { Loader } from "@progress/kendo-react-indicators";
import {differenceInDays} from 'date-fns';

type ProductListing = {
  product: IFSProduct;
  download?: IFSAppDownload;
  documentation?: IFSDocumentation;
}

export const FSTFDashboardLoggedIn = (props: any) => {
    const { isLoggedIn,user,isSiteAdmin, setPageTitle, dataProvider } = useContext(FSAppContext);
    const navigate = useNavigate();
    const [dashboardItems, setDashboardItems] = useState<ProductListing[] | undefined>(undefined);
    const [licenses, setLicenses] = useState<IFSLicensePack[] | undefined>(undefined);
    const [providerError, setProviderError] = useState<string | undefined>();

    useEffect(() => {
      if (!isLoggedIn) {
        navigate("/login");
      }

      else {
        setPageTitle("Dashboard");
      }
    }, [isLoggedIn]);
    
    useEffect(() => {

      dataProvider?.getDashboard().then((data) => {

        const productListing : ProductListing[] = data.products.map((product) => {

          const productDownload = data.downloads.find((download) => download.productId === product.id);
          const productDoc = data.documentation.find((doc) => doc.productId === product.id);

          return {
              product: product,
              download: productDownload,
              documentation: productDoc
            };
        });

        productListing.sort((ListingA: ProductListing, ListingB: ProductListing) => {
          if (ListingA.download !== undefined && ListingB.download !== undefined) {
            return new Date(ListingA.download.createdDate) < new Date(ListingB.download.createdDate) ? 1 : -1;
          }

          else if (ListingA.documentation !== undefined && ListingB.documentation !== undefined) {
            return new Date(ListingA.documentation.createdDate) < new Date(ListingB.documentation.createdDate) ? 1 : -1;
          }

          return 0;
        });

        setDashboardItems(productListing);
        setLicenses(data.licensePacks);

        setProviderError(undefined);
      }).catch((error: Error) => {
        if (error.message === "401") {
          navigate("/logout");
        }
        else {
          setProviderError("Unable to retrieve dashboard data - try again later");
        }
      });
    }, []);

  const handleAppDownload = (product: IFSProduct, download: IFSAppDownload) => {
    navigate(`/product/${product.id}/download?autodownload=${download.id}`, { state: { ...product} });
  }

  const handleDocDownload = (product: IFSProduct, doc: IFSDocumentation) => {
    navigate(`/product/${product.id}/documentation?autodownload=${doc.id}`, { state: { ...product} });
  }

  const handleLicenseDownload = (licensePack: IFSLicensePack) => {
    navigate(`/licenses/download/${licensePack.id}`);
  }

  const getUploadedTimeText = (dateOne: Date, dateTwo: Date) : string => {
    const diff = differenceInDays(dateOne, dateTwo);
    if (diff === 0) {
      return "Today";
    }

    return (diff > 1) ? `${diff} days ago` : `${diff} day ago`;
  };


  const getLicenseTimeText = (dateOne: Date, dateTwo: Date) : string => {
    const diff = differenceInDays(dateOne, dateTwo);
    if (diff === 0) {
      return "Expires Today";
    }

    return (diff > 1) ? `${diff} days left` : `${diff} day left`;
  };

  return (
    <FSTFDefaultTemplate>
      <StackLayout orientation="horizontal" gap={20}>
        <Card>
          <CardHeader>
            <CardTitle>Latest Product Downloads</CardTitle>
          </CardHeader>
          
          <CardBody>
            {dashboardItems === undefined && providerError === undefined && (
              <Loader type="infinite-spinner" size="medium" />
            )}

            {providerError !== undefined && (
              <p>{providerError}</p>
            )}
            
            {dashboardItems !== undefined && dashboardItems.map((item, index) => {
              return <p key={index}>{item.product.name}&nbsp; 
                {item.download !== undefined && (
                  <Button 
                    fillMode={"flat"} 
                    svgIcon={fileZipIcon} 
                    onClick={() => handleAppDownload(item.product, item.download!)}>
                      ({getUploadedTimeText(new Date(), new Date(item.download.createdDate))})
                  </Button>
                )} 
                
                {item.documentation !== undefined && (
                  <Button 
                    fillMode={"flat"} 
                    svgIcon={filePdfIcon} 
                    onClick={() => handleDocDownload(item.product, item.documentation!)}>
                      ({getUploadedTimeText(new Date(), new Date(item.documentation.createdDate))})
                  </Button>
                )}
              </p>
            })}
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Active Licenses</CardTitle>
          </CardHeader>
          
          <CardBody>
            {licenses === undefined && providerError === undefined && (
              <Loader type="infinite-spinner" size="medium" />
            )}

            {providerError !== undefined && (
              <p>{providerError}</p>
            )}

            {isSiteAdmin ? (
              <Button onClick={() => navigate("/customers")}>View Customer List</Button>
            ) : licenses !== undefined && licenses.length == 0 && (
                <p>No licenses available</p>
              )}    
            
            {!isSiteAdmin && licenses !== undefined && licenses.length > 0 && licenses.map((licensePack) => {
              const diff = getLicenseTimeText(new Date(licensePack.expireDate), new Date());
              return <p><Button 
                key={licensePack.id}
                fillMode={"flat"} 
                svgIcon={fileConfigIcon} 
                onClick={() => handleLicenseDownload(licensePack)}>
                  {licensePack.name}
                  &nbsp;
                  ({diff})
              </Button></p>
            })}        
            
          </CardBody>
        </Card>
      </StackLayout>     
      </FSTFDefaultTemplate>
  );
}

export default withRouter(FSTFDashboardLoggedIn);