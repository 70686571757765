import IFSUsers from "../types/IFSUsers";
import { FSTFDataProvider } from "./FSTF.DataProvider";
import { FSTFApiAuthProvider } from "./FSTF.ApiAuthProvider";

export class FSTFAuthProvider implements IFSAuthProvider {
   
    api= new FSTFApiAuthProvider();
    fake=new FSTFFakeAuthProvider();

    useFakeAuth: boolean = false;
    private _authProvider: IFSAuthProvider;

    constructor(useFakeAuthProp:boolean=false) {   
        this.useFakeAuth=useFakeAuthProp;
        this._authProvider=useFakeAuthProp?this.fake:this.api;
    }

    login(username: string, password: string): Promise<IFSUsers> {
        return this._authProvider.login(username, password);
    }
}

export class FSTFFakeAuthProvider implements IFSAuthProvider{
    
    provider=new FSTFDataProvider(true);
    login(username: string, password: string): Promise<IFSUsers> {
        return this.provider.getUsers()
            .then(users => {
                const user = users.filter(u => u.userName === username)[0];
                return user; 
            })
            .catch(error => {
                console.error("Error fetching users:", error);
                throw error; 
            });
    }
}

export interface IFSAuthProvider{
    login(username:string, password:string):Promise<IFSUsers>;
}