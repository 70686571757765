import {
  IFSLicensePack as FSLicensePack,
  IFSLicensePack,
} from "../types/IFSLicensePack";
import IFSAdministrationConfiguration from "../types/IFSAdministrationConfiguration";
import { IFSDataProvider } from "../types/IFSDataProvider";
import IFSProduct from "../types/IFSProduct";
import IFSDocumentation from "../types/IFSDocumentation";
import IFSCustomer from "../types/IFSCustomer";
import IFSUsers from "../types/IFSUsers";
import { IFSSiteRoles, IFSCustomerRoles, IFSRoles } from "../types/IFSRoles";
import IFSAppDownload from "../types/IFSAppDownload";
import IFSNote from "../types/IFSNote";
import IFSCustomerUsers from "../types/IFSCustomerUsers";
import IFSMessage from "../types/IFSMessage";
import { FSEnvironmentService } from "./FSTF.EnvironmentProvider";
import { FSLocalContentService } from "./FSTF.LocalContentProvider";
import { IFSLicenseSet } from "../types/IFSLicenseSet";
import { IFSLicenseRequest } from "../types/IFSLicenseRequest";
import IFSLicenseAgreement from "../types/IFSLicenseAgreement";
import IFSDashboard from "../types/IFSDashboard";

const API_VERSION = new FSEnvironmentService().getEnvironment().ApiVersion; //https://localhost:7141/api";
const BASE_URL = new FSEnvironmentService().getEnvironment().BaseUrl; //https://localhost:7141/api";

const FULL_API_URL = `${BASE_URL}/api/v${API_VERSION}`;
const LCP = new FSLocalContentService();

const ROUTES = {
  products: "products",
  licensePacks: "licensePacks",
  licenses: "licenses",
  users: "users",
  companys: "companys",
  roles: "roles",
  downloads: "downloads/",
  siteRoles: "siteRoles",
  notes: "notes/",
  customerusers: "companyusers",
  messages: "messages",
  licenseSets: "licenseset",
  eula: "eula",
  administration: "admin",
  dashboard: "dashboard",
};

function buildHeaders(method: string, body: any = "") {
  let h = {};
  if (method === "GET") {
    h = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LCP.GetLocalContent().AccessToken}`,
      },
    };
  } else {
    h = {
      method: method,
      body: body ? JSON.stringify(body) : "",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LCP.GetLocalContent().AccessToken}`,
      },
    };
  }
  console.log(h);
  return h;
}

export class FSTFApiProvider implements IFSDataProvider {
  async setFakeDataFlag(b: boolean) {}
  async setDataProvider(dp: IFSDataProvider) {}

  async deleteProduct(productId: string): Promise<boolean> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.products}/product/${productId}`;
      const response = await fetch(url, buildHeaders("DELETE"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      
      return true;
    } catch (error) {
      throw error;
    }
  }

  getReleases(): Promise<any> {
    throw new Error("getReleases Method not implemented in API Provider.");
  }
  async getUsers(): Promise<IFSUsers[]> {
    console.log("Get All Users");
    try {
      const url = `${FULL_API_URL}/${ROUTES.users}/users`;
      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const responseUsers: IFSUsers[] = await response.json();
      return responseUsers;
    } catch (error) {
      throw error;
    }
  }
  async getUser(id: string): Promise<IFSUsers> {
    const url = `${FULL_API_URL}/${ROUTES.users}/user/${id}`;
    try {
      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const product: IFSProduct = await response.json();
      return product;
    } catch (error) {
      console.error(`Failed to fetch product with id ${id}:`, error);
      throw error;
    }
  }

  async getProduct(id: string): Promise<IFSProduct> {
    const url = `${FULL_API_URL}/${ROUTES.products}/product/${id}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const product: IFSProduct = await response.json();
      return product;
    } catch (error) {
      console.error(`Failed to fetch product with id ${id}:`, error);
      throw error;
    }
  }

  async getProducts(): Promise<IFSProduct[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.products}/products`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const responseProducts: IFSProduct[] = await response.json();
      return responseProducts;
    } catch (error) {
      console.error("Failed to fetch products:", error);
      throw error;
    }
  }

  async getLicensePacksForCompany(companyId: string): Promise<FSLicensePack[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.companys}/licensepacks/${companyId}`;

      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const responseLicensePacks: IFSLicensePack[] = await response.json();
      return responseLicensePacks;
    } catch (error) {
      console.error("Failed to fetch licensepacks:", error);
      throw error;
    }
  }

  async getLicensePack(id: string): Promise<FSLicensePack> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.licenses}/licensepack/${id}`;
      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const responseLicensePacks: IFSLicensePack = await response.json();
      return responseLicensePacks;
    } catch (error) {
      console.error("Failed to fetch licensepacks:", error);
      throw error;
    }
  }

  async addLicensePack(licensePack: FSLicensePack): Promise<FSLicensePack> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.licenses}/licensepack`;
      const response = await fetch(url, buildHeaders("POST", licensePack));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const responseLicensePacks: IFSLicensePack = await response.json();
      return responseLicensePacks;
    } catch (error) {
      console.error("Failed to add licensepack:", error);
      throw error;
    }
  }

  async deleteLicensePack(id: string): Promise<void> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.licenses}/licensepack/${id}`;
      const response = await fetch(url, buildHeaders("DELETE"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to delete licensepack:", error);
      throw error;
    }
  }

  async editLicensePack(
    id: string,
    licensePack: FSLicensePack
  ): Promise<FSLicensePack> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.licenses}/licensepack/${id}`;
      const response = await fetch(url, buildHeaders("PUT", licensePack));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const responseLicensePacks: IFSLicensePack = await response.json();
      return responseLicensePacks;
    } catch (error) {
      console.error("Failed to edit licensepack:", error);
      throw error;
    }
  }

  async downloadLicensePack(
    id: string
  ): Promise<{ filename: string; blob: Blob }> {
    const defaultFilename = "trustedfilter_license.zip";

    try {
      const url = `${FULL_API_URL}/${ROUTES.licenses}/licensepack/${id}/download`;
      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const clonedResponse = response.clone();

      try {
        const licenseDownload = await response.blob();

        const ret = {
          filename: defaultFilename,
          blob: licenseDownload,
        };

        return ret;
      } catch (err) {
        console.error("failed to retrieve as blob");
        const text = await clonedResponse.text();
        return { filename: defaultFilename, blob: new Blob([text]) };
      }
    } catch (error) {
      console.error("Failed to download licensepack:", error);
      throw error;
    }
  }

  async getCustomers(): Promise<IFSCustomer[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.companys}/companys`;
      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async getCustomerRoles(): Promise<IFSCustomerRoles[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.roles}`;
      const response = await fetch(url, buildHeaders("GET"));
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const roles: IFSCustomerRoles[] = await response.json();
      return roles;
    } catch (error) {
      console.error("Failed to fetch Roles:", error);
      throw error;
    }
  }

  async getProductDocumentation(id: string): Promise<IFSDocumentation[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.products}/product/${id}/documentation`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const docs: IFSDocumentation[] = await response.json();
      return docs;
    } catch (error) {
      throw error;
    }
  }

  async saveProductDocumentation(
    newDoc: IFSDocumentation
  ): Promise<IFSDocumentation> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.products}/product/documentation`;
      const payload = new FormData();

      payload.append("ProductId", newDoc.productId);
      payload.append("Name", newDoc.filename);
      payload.append("Description", newDoc.description);
      payload.append("FileType", "documents");
      payload.append("Version", newDoc.version);
      payload.append("FileSource", newDoc.fileSource);

      const response = await fetch(url, {
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        console.log(response.status);
        throw new Error(`Error: ${response.status}`);
      }
      const docs: IFSDocumentation = await response.json();
      return docs;
    } catch (error) {
      throw error;
    }
  }

  async saveProductUpload(newUpload: IFSAppDownload): Promise<IFSAppDownload> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.products}/product/downloads`;
      const payload = new FormData();

      payload.append("ProductId", newUpload.productId);
      payload.append("Name", newUpload.filename);
      payload.append("Description", newUpload.description);
      payload.append("FileType", "applications");
      payload.append("Version", newUpload.version);
      payload.append("FileSource", newUpload.fileSource);

      const response = await fetch(url, {
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        console.log(response.status);
        throw new Error(`Error: ${response.status}`);
      }
      const download: IFSAppDownload = await response.json();
      return download;
    } catch (error) {
      throw error;
    }
  }

  async getProductDownloads(id: string): Promise<IFSAppDownload[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.products}/product/${id}/downloads`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const downloads: IFSAppDownload[] = await response.json();
      return downloads;
    } catch (error) {
      throw error;
    }
  }

  async downloadProductBinary(
    download: IFSAppDownload
  ): Promise<[Blob, Headers]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.products}/product/${download.productId}/downloads/${download.id}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const blob: Blob = await response.blob();
      const headers: Headers = response.headers;

      return [blob, headers];
    } catch (error) {
      throw error;
    }
  }

  async downloadProductDocumentation(
    documentation: IFSDocumentation
  ): Promise<Blob> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.products}/product/${documentation.productId}/documentation/${documentation.id}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const blob: Blob = await response.blob();
      return blob;
    } catch (error) {
      throw error;
    }
  }

  async addProduct(newProd: IFSProduct): Promise<IFSProduct> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.products}/product`;

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(newProd),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const added: IFSProduct = await response.json();
      return added;
    } catch (error) {
      throw error;
    }
  }
  async postCustomer(newCustomer: IFSCustomer): Promise<IFSCustomer> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.companys}/company`;

      const response = await fetch(url, buildHeaders("POST", newCustomer));

      if (!response.ok) {
        const errorResponse = await response.json();
        console.log("errorResponse" + errorResponse.response);
        throw new Error(`Error: ${errorResponse.response}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async putCustomer(updatedCustomer: IFSCustomer): Promise<IFSCustomer> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.companys}/company`;

      const response = await fetch(url, buildHeaders("PUT", updatedCustomer));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async postUser(newUser: IFSUsers): Promise<IFSUsers> {
    newUser.id = crypto.randomUUID();
    try {
      const url = `${FULL_API_URL}/${ROUTES.users}/user`;

      const response = await fetch(url, buildHeaders("POST", newUser));

      if (!response.ok) {
        return response.text().then(text => {
          throw new Error(`Error: ${response.status}:${text}`); 
      });

      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  //Update user
  async putCustomerUser(user: IFSUsers): Promise<IFSUsers> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.users}/user`;

      const response = await fetch(url, buildHeaders("PUT", user));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async getSiteRoles(): Promise<IFSSiteRoles[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.users}/siteroles`;

      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async addCustomerNote(note: IFSNote): Promise<IFSNote> {
    throw new Error("addCustomerNote Method not implemented in API Provider.");
  }

  async getCustomerNotes(customerId: string): Promise<IFSNote[]> {
    throw new Error("getCustomerNotes Method not implemented in API Provider.");
  }

  async getUserCustomers(userId: string): Promise<IFSCustomerUsers[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.users}/user/${userId}/companys`;
      console.log("url:" + url);
      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async getCustomerUsers(customerId: string): Promise<IFSCustomerUsers[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.companys}/company/${customerId}/users`;

      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async putCustomerUserRoles(
    updatedCustomerUser: IFSCustomerUsers
  ): Promise<IFSCustomerRoles[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.users}/roles`;

      const response = await fetch(
        url,
        buildHeaders("PUT", updatedCustomerUser)
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async postCustomerUserRoles(
    newCustomerUser: IFSCustomerUsers
  ): Promise<IFSCustomerRoles[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.users}/roles`;

      const response = await fetch(url, buildHeaders("POST", newCustomerUser));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async getCustomer(customerId: string): Promise<IFSCustomer> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.companys}/company/${customerId}`;
      console.log("getCustomer:" + url);
      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const added: IFSProduct = await response.json();
      return added;
    } catch (error) {
      throw error;
    }
  }

  async getCustomerMessages(customerId: string): Promise<IFSMessage[]> {
    throw new Error(
      "getCustomerMessages Method not implemented in API Provider."
    );
  }

  async sendCustomerMessage(
    customerId: string,
    message: IFSMessage
  ): Promise<IFSMessage> {
    throw new Error(
      "sendCustomerMessage Method not implemented in API Provider."
    );
  }

  async getLicenseSets(): Promise<IFSLicenseSet[]> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.licenseSets}/licensesets`;
      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const licenseSets: IFSLicenseSet[] = await response.json();
      return licenseSets;
    } catch (error) {
      throw error;
    }
  }
  async resetPasswordRequest(email: string): Promise<string> {
    try {
      const url = `${FULL_API_URL}/auth/reset-req`;
      console.log(url);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const resetReq = await response.json();
      const data: string = resetReq.Response;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(
    email: string,
    password: string,
    resetcode: string
  ): Promise<boolean> {
    try {
      const url = `${FULL_API_URL}/auth/reset`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          resetcode: resetcode,
        }),
      });

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      throw error;
    }
  }

  async generateLicensePack(
    licenseRequest: IFSLicenseRequest
  ): Promise<string> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.licenseSets}/generateLicensePack`;

      const response = await fetch(url, buildHeaders("POST", licenseRequest));

      if (response.ok) {
        return "true";
      } else {
        return "false";
      }
    } catch (error) {
      throw error;
    }
  }
  async deleteCustomer(deletedCustomer: IFSCustomer): Promise<string> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.companys}/company/${deletedCustomer.id}`;

      const response = await fetch(
        url,
        buildHeaders("DELETE", deletedCustomer.id)
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return response.statusText;
    } catch (error) {
      throw error;
    }
  }

  async deleteCustomerUserRoles(
    updatedCustomerUser: IFSCustomerUsers
  ): Promise<any> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.users}/roles`;

      const response = await fetch(
        url,
        buildHeaders("DELETE", updatedCustomerUser)
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async deleteUser(deletedUser: IFSUsers): Promise<any> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.users}/user/${deletedUser.id}`;

      const response = await fetch(url, buildHeaders("DELETE", deletedUser.id));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async checkLicenseAgreement(
    licenseAgreement: IFSLicenseAgreement
  ): Promise<number> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.eula}/checkforagreement/${licenseAgreement.licensePackId}`;

      const response = await fetch(url, buildHeaders("GET"));

      return response.status;
    } catch (error) {
      throw error;
    }
  }

  async addLicenseAgreement(
    licenseAgreement: IFSLicenseAgreement
  ): Promise<IFSLicenseAgreement> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.eula}/recordagreement`;

      const response = await fetch(url, buildHeaders("POST", licenseAgreement));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async getEula(): Promise<string> {
    try {
      const url = `${FULL_API_URL}/${ROUTES.eula}/eula`;

      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.text();
    } catch (error) {
      throw error;
    }
  }

  async getAdministrationConfiguration(): Promise<IFSAdministrationConfiguration> {
    const url = `${FULL_API_URL}/${ROUTES.administration}/config`;
    try {
      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const adminConfig: IFSAdministrationConfiguration = await response.json();
      return adminConfig;
    } catch (error) {
      console.error(`Failed to fetch administration:`, error);
      throw error;
    }
  }

  async toggleDownloadState(download: IFSAppDownload): Promise<IFSAppDownload> {
    try {

      const url = `${FULL_API_URL}/${ROUTES.products}/product/${download.productId}/downloads/${download.id}`;

      const response = await fetch(url, buildHeaders("PUT", download));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async toggleDocumentationState(doc: IFSDocumentation): Promise<IFSDocumentation> {
    try {

      const url = `${FULL_API_URL}/${ROUTES.products}/product/${doc.productId}/documentation/${doc.id}`;

      const response = await fetch(url, buildHeaders("PUT", doc));

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async getDashboard(): Promise<IFSDashboard> {
    try {

      const url = `${FULL_API_URL}/${ROUTES.dashboard}`;

      const response = await fetch(url, buildHeaders("GET"));

      if (!response.ok) {
        throw new Error(String(response.status));
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

}
