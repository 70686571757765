import { useContext, useEffect, useState } from "react";
import IFSProduct from "../../types/IFSProduct";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import IFSDocumentation from "../../types/IFSDocumentation";

import { FSAppContext } from "../../providers/FSTF.Context";
import { saveAs } from '@progress/kendo-file-saver';
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Card, CardHeader, CardTitle, CardActions } from "@progress/kendo-react-layout";
import FileUploadForm from "../molecules/FSTF.FileUploadForm";
import { NotificationPopup } from "../atoms/FSTF.NotificationPopup";
import DownloadPanelBar, { Downloadable } from "../atoms/FSTF.DownloadPanelBar";
import { isCompositeFilterDescriptor } from "@progress/kendo-data-query";

type UploadOutcome = {
  successful: boolean;
  message: string;
}

export const FSTFProductDocumentation = (props: any) => {
  const {user,dataProvider, setPageTitle, isSiteAdmin} = useContext(FSAppContext);
  const {id} = useParams();
  
  const [documentation, setDocumentation] = useState<Downloadable[]>([]);
  const [uploadVisible, setUploadVisible] = useState<boolean>(false); 
  const [dataProviderErr, setDataProviderError] = useState<String>();
  const [uploadOutcome, setUploadOutcome] = useState<UploadOutcome | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const provider = dataProvider;
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [search, setSearch] = useSearchParams();
  const [doAutoDownload, setDoAutoDownload] = useState<boolean>(true);
  const product = useLocation().state as IFSProduct;
  
  if (product === null || product === undefined
    || product.id !== id) {
    navigate("/products");
  }
  
  useEffect(() => {

    if (product !== null && product !== undefined) {
      setPageTitle(`${product.name} - Documentation`);
    }
    
    provider?.getProductDocumentation(String(id)).then((data) => {
      data.sort((a: IFSDocumentation, b: IFSDocumentation) => {
        return (new Date(a.createdDate) < new Date(b.createdDate)) ? 1 : -1;
      });

      setDocumentation(data.map((d) => ({ download: d, isDownloading: false })));
      setDataProviderError(undefined);
      setIsLoading(false); 
    }).catch((reason) => {
        setDataProviderError("Unable to retrieve product documentation - try again later"); 
        setDocumentation([]);
    });
    
  }, [id]);

  useEffect(() => {
    if (doAutoDownload && search.get("autodownload")) {
      const requested = documentation.find((doc: Downloadable) => doc.download.id === search.get("autodownload"));
      if (requested !== undefined) {
        handleDownload(requested);
        setDoAutoDownload(false);
      }
    }
  }, [documentation]);

  const canUpload = user?.siteRoles?.find((role) => role.toLowerCase() === "siteadmin") !== undefined;

  const toggleUploadVisibility = () => {
    setUploadVisible(!uploadVisible);
  }

  const handleSubmit = (e: any) => {
    if (e.fileUploadField && e.fileUploadField.length > 0) {
      setIsUploading(true);
      toggleUploadVisibility();

      const theFile = e.fileUploadField[0].getRawFile() as File;
      const newDoc = {
        productId: product!.id,
        fileSource: theFile,
        description: e.description,
        filename:theFile.name,
        version: e.version
      } as IFSDocumentation

     dataProvider?.setFakeDataFlag(false);
     dataProvider?.saveProductDocumentation(newDoc)
      .then((data) => { 
        setIsUploading(false);
        setDocumentation([{download: data, isDownloading: false}, ...documentation]);
        setUploadOutcome({
          successful: true, 
          message: `${newDoc.filename} uploaded successfully`
        });
      })
      .catch((error) => {
        setIsUploading(false);
        setUploadOutcome({
          message: `An error occurred while uploading ${newDoc.filename} - try again later`,
          successful: false
        });
      });
    
    }
  }

  const handleDownload = (requested: Downloadable) => {
    if (requested.isDownloading) {
      return;
    }

    setDocumentation(documentation.map((d) => d.download.id === requested.download.id ? {...d, isDownloading: true} : d));

    provider?.downloadProductDocumentation(requested.download)
    .then((blob) => {
      saveAs(blob, requested.download.filename);
    }).catch((error) => {
      window.alert("An error occurred while downloading documentation - try again later");
    }).finally(() => {
      setDocumentation(documentation.map((d) => d.download.id === requested.download.id ? {...d, isDownloading: false} : d));
    });
  }

  const toggleActiveState = (doc: Downloadable) => {
    provider?.toggleDocumentationState(doc.download)
      .then((result: IFSDocumentation) => {
        //exchange the item to preserve item order
        const exchanged = documentation.map((d) => d.download.id === result.id ? {download: result, isDownloading: false} : d);
        setDocumentation(exchanged);
      })
      .catch((error) => {
        window.alert(
          "An error occurred while updating documentation state - try again later"
        );
      });
  };

  
  return (
    <FSTFDefaultTemplate>
      {
        dataProviderErr !== undefined 
        ? (<div>{dataProviderErr}</div>) 
        : ( 
            <div className="k-card-list">
              <Card>
                <CardHeader>
                  <CardTitle>Documentation Downloads for {product?.name}</CardTitle>          
                </CardHeader>

                {
                  canUpload && (
                    <CardActions orientation="horizontal">
                      <Button onClick={toggleUploadVisibility}>Add Documentation</Button>
                    </CardActions>
                  )
                }

              </Card>

              {
                canUpload && uploadVisible && (
                  <Dialog title={"Add Documentation"} onClose={toggleUploadVisibility}>
                                        
                    <p>
                      Select a file and add a brief description for users
                    </p>

                    <FileUploadForm 
                      handleSubmit={(e) => {handleSubmit(e)}} 
                      fileDescriptionFieldLabel="Description"
                      fileUploadFieldLabel="Documentation File"
                      fileVersionFieldLabel="File Version"
                    />         

                    <DialogActionsBar>
                      <Button
                        rounded={"medium"}
                        fillMode={"solid"}
                        onClick={toggleUploadVisibility}>
                          Cancel
                        </Button>
                    </DialogActionsBar>
                  </Dialog>
                        
                )
              }

              { documentation.length > 0 && (
                <DownloadPanelBar
                  activeClassName="liveDocumentationItem"
                  archivedClassName="archivedDocumentationItem"
                  downloadables={documentation}
                  handleToggleActive={toggleActiveState}
                  handleDownload={handleDownload}
                  isSiteAdmin={isSiteAdmin}
                />
              )}

              { documentation.length === 0 && isLoading && (
                <div>Loading...please wait</div>  
              )}
            
              { documentation.length === 0 && !isLoading && (
                <div>No documentation found for product</div>  
              )} 

              { documentation.length === 0 && dataProviderErr !== undefined && (
                <div>{dataProviderErr}</div>
              )} 

            { isUploading && (
              <Dialog title={"Uploading File"} closeIcon={false}>
                <p>
                  Please wait while the file is uploaded
                </p>
              </Dialog>
           )}

            {uploadOutcome !== undefined && (
              <NotificationPopup
                message={uploadOutcome.message}
                successful={uploadOutcome.successful}
                onClose={() => setUploadOutcome(undefined)}
              />
            )}
            </div>
          )}

    </FSTFDefaultTemplate>
  );
};