import { DropDownButton } from "@progress/kendo-react-buttons";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";

import {plusIcon} from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";

interface ConfigPluginBarProps {
  pluginTypes: { [key: string]: { value: string; icon: string } }[];
  onAddPlugin: (pluginType: string) => void;
}

const ConfigPluginBar: React.FC<ConfigPluginBarProps> = ({
  pluginTypes,
  onAddPlugin,
}) => {
  function handleAddNewPlugin(pluginType: string) {
    onAddPlugin(pluginType);
  }

  const items = pluginTypes.map((obj) => {
    var key = Object.keys(obj)[0];
    return (
      <span key={key} onClick={() => handleAddNewPlugin(key)}>
        <SvgIcon icon={plusIcon} />
        {obj[key].value}
      </span>
    );
  });

  return (
    <div key="pluginbar">
      <AppBar>
        <AppBarSection>
          <h3>TrustedFilter™ Plugins</h3>
        </AppBarSection>

        <AppBarSpacer
          style={{
            width: 32,
          }}
        />

        <AppBarSection>
          <DropDownButton text="Add New" items={items} svgIcon={plusIcon} />
        </AppBarSection>
      </AppBar>
    </div>
  );
};

export default ConfigPluginBar;
