import { useState, useEffect, useContext } from "react";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import IFSCustomer from "../../types/IFSCustomer";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import {
  setGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import { DataResult, process, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { FSTFDataProvider } from "../../providers/FSTF.DataProvider";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Card, CardBody, CardFooter, CardHeader, GridLayout, GridLayoutItem, StackLayout } from "@progress/kendo-react-layout";
import { FSTFCustomerUsers } from "./FSTF.CustomerUsers";
import { FSAppContext } from "../../providers/FSTF.Context";
import { useNavigate } from "react-router-dom";
import {
  clipboardTextIcon,
  editToolsIcon,
  envelopIcon,
  trackChangesIcon,
  trashIcon,
  userIcon,
  xIcon,
} from "@progress/kendo-svg-icons";
import { FormUpload } from "../atoms/FSTF.UploadFileFormElement";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { FormTextInput } from "../atoms/FSTF.TextAreaFormElement";
import IFSNote from "../../types/IFSNote";
import { ListView, ListViewItemProps } from "@progress/kendo-react-listview";
import { downloadIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import { PageChangeEvent, Pager } from "@progress/kendo-react-data-tools";
import { saveAs } from "@progress/kendo-file-saver";
import IFSCustomerUsers from "../../types/IFSCustomerUsers";
import IFSMessage from "../../types/IFSMessage";
import { UserMessageForm } from "../molecules/FSTF.MessagingForm";
import { SITEPERMISSION, USERROLEPERMISSION } from "../../types/FSPermissionTypes";


const noteTextValidator = (value: string) =>
  !value ? "Note text is required" : "";

interface NoteFormProps {
  handleSubmit: (e: any) => void;
}

const AddNoteForm: React.FC<NoteFormProps> = (props: NoteFormProps) => {
  const [key, setFormKey] = useState<number>(1);

  //change key to reset form before passing to user handler
  //https://www.telerik.com/kendo-react-ui/components/knowledge-base/form-reset-and-change-initial-values/
  const internalHandle = (e: any) => {
    setFormKey(key + 1);

    props.handleSubmit(e);
  };

  return (
    <>
    <Form
      key={key}
      onSubmit={(e) => internalHandle(e)}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: 500 }}>
          <Field
            id={"noteText"}
            name={"noteText"}
            label={"Note Text"}
            component={FormTextInput}
            validator={noteTextValidator}
          />

          <Field
            id={"noteFile"}
            name={"noteFile"}
            label={"Attachment (optional)"}
            component={FormUpload}
          />

          <div className="k-form-buttons">
            <Button
              themeColor={"primary"}
              type={"submit"}
              disabled={!formRenderProps.allowSubmit}
            >
              Add Note
            </Button>
          </div>
        </FormElement>
      )}
    />
    </>
  );
};

const CustomerDetailForm = ({
  onSubmit,
  onCancel,
  selectedCustomer,
  type,
}: any) => {
  const [customerDetail, setCustomerDetail] = useState<IFSCustomer>({});

  useEffect(() => {
    if (Object.keys(selectedCustomer).length > 0) {
      setCustomerDetail(selectedCustomer);
    }
  }, [selectedCustomer]);

  const handleChange = (e: any) => {
    setCustomerDetail({ ...customerDetail, [e.target.name]: e.target.value });
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSubmit(customerDetail);
  };
  return (
    <Dialog title={type} onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <div>
          <Label editorId="name">Name:</Label>
          <Input
            name="name"
            value={customerDetail.name || ""}
            onChange={handleChange}
            required={true}
          />
        </div>
        <div>
          <Label editorId="address">Address:</Label>
          <Input
            name="address"
            value={customerDetail.address || ""}
            onChange={handleChange}
            required={true}
          />
        </div>
        <div>
          <Label editorId="city">City:</Label>
          <Input
            name="city"
            value={customerDetail.city || ""}
            onChange={handleChange}
            required={true}
          />
        </div>
        <div>
          <Label editorId="postcode">Postcode:</Label>
          <Input
            name="postcode"
            value={customerDetail.postcode || ""}
            onChange={handleChange}
            required={true}
          />
        </div>
        <div>
          <Label editorId="phone">Phone:</Label>
          <Input
            name="phone"
            value={customerDetail.phone || ""}
            onChange={handleChange}
            required={true}
          />
        </div>
        <div>
          <Label editorId="email">Email:</Label>
          <Input
            name="email"
            value={customerDetail.email || ""}
            onChange={handleChange}
            type={"email"}
            required={true}
          />
          <span className="fs-form-help">Main business email for the customer company</span>
        </div>
        <div>
          
          <Label editorId="type">Type:</Label>
          <Input
            name="type"
            value={customerDetail.type || ""}
            onChange={handleChange}
            required={true}
          />
          <span className="fs-form-help">Enter how this customer company is categorised.</span>
        </div>
        <div>
          <Label editorId="description">Salesforce Reference:</Label>
          <Input
            name="description"
            value={customerDetail.description || ""}
            onChange={handleChange}
            required={true}
          />
          <span className="fs-form-help">Add the customer salesforce reference.</span>
        </div>
        <DialogActionsBar>
          <Button type="submit">{type} Customer</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActionsBar>
      </form>
    </Dialog>
  );
};
const initialDataState: State = {
  take: 10,
  skip: 0,
  group: [],
};
const processWithGroups = (data:any, dataState: State) => {
  const newDataState = process(data, dataState);

  setGroupIds({ data: newDataState.data, group: dataState.group });

  return newDataState;
};
export const FSTFCustomers = (props: any) => {
  const { isSiteAdmin, isValidCustomerAccess, user, dataProvider, useFakeData, setPageTitle } = useContext(FSAppContext);
  const [showCustomerDetailForm, setShowCustomerDetailForm] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [filterValue, setFilterValue] = useState<string>();
  const [selectedCustomer, setSelectedCustomer] = useState<IFSCustomer>({});
  const [type, setType] = useState("");
  const [allCustomers, setAllCustomers] = useState<IFSCustomer[]>([]);
  const [filteredData, setFilteredData] = useState<IFSCustomer[]>([]);
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [dataResult, setDataResult] = useState(
    process(filteredData, dataState)
  );
  const [providerError, setProviderError] = useState<String>();
  const [notesProviderError, setNotesProviderError] = useState<String>();
  const [showCustomerNotes, setShowCustomerNotes] = useState<boolean>(false);
  const [customerNotes, setCustomerNotes] = useState<IFSNote[]>([]);
  const [notePages, setNotesPage] = useState({ skip: 0, take: 5 });
  const [customerUsersRole, setCustomerUsersRole] = useState<IFSCustomerUsers[]>([]);
  const [showMessagesWindow, setShowMessagesWindow] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isSiteAdmin && !isValidCustomerAccess) {
      navigate("/");
    }
  }, [isSiteAdmin, isValidCustomerAccess, navigate]);

  const provider=dataProvider?dataProvider: new FSTFDataProvider(useFakeData);
  
  useEffect(() => {
    setPageTitle("Customers");
  }, []);
  
  useEffect(() => {
    if ((providerError as string)?.length > 0) {
      console.log("Provider Error detected", providerError);
      if ((providerError as string).includes("401")) {
        navigate("/logout");
      }
      
    }

    if (!showCustomerNotes) return;

    provider
      .getCustomerNotes(String(selectedCustomer.id))
      .then((data) => {
        data.sort((a: IFSNote, b: IFSNote) => {
          return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
        });
        setCustomerNotes(data);
        setNotesProviderError(undefined);
      })
      .catch((error) => {
        setNotesProviderError("Couldn't load customer notes, try again later");
      });
  }, [showCustomerNotes]);

  const onFilterChange = (ev:any) => {
    let value = ev.value;
    setFilterValue(ev.value);
  };
  const isPermissionGranted = (permission: string, customerId: string) => {
    if (isSiteAdmin) return true;

    const roleCheck = (role: string) =>
      ["customeradmin", "accountmanager", "companymanager", "companyadmin"].includes(role.toLowerCase());
    
    
    const licenseroleCheck = (role: string) =>
      ["licensemanager" , "companyviewer"].includes(role.toLowerCase());

    switch (permission) {
      case USERROLEPERMISSION.ADDCustomer:
      case USERROLEPERMISSION.DELETECustomer:
        return false;

      case USERROLEPERMISSION.EDITCustomer:
      case USERROLEPERMISSION.USER:
        return customerUsersRole?.some(
          (cust) =>
            cust.userId === user.id &&
            cust.customerId === customerId &&
            cust.active !== false &&
            cust.roles?.some(roleCheck)
        )

      case USERROLEPERMISSION.NOTESCustomer:
      case USERROLEPERMISSION.MESSAGECustomer:
        return customerUsersRole?.some(
          (cust) =>
            cust.userId === user.id &&
            cust.customerId === customerId &&
            cust.active !== false &&
            cust.roles?.some((role) => {
              return role.toLowerCase() === "accountmanager";
            })
        );

      case USERROLEPERMISSION.LICENSE:
        return customerUsersRole?.some(
          (cust) =>
            cust.userId === user.id &&
            cust.customerId === customerId &&
            cust.active !== false &&
            cust.roles?.some(licenseroleCheck)
        );

      default:
        return false;
    }
  };

  const handlePageChange = (e: PageChangeEvent) => {
    setNotesPage({
      skip: e.skip,
      take: e.take,
    });
  };

  const { skip, take } = notePages;

  const getCustomers = () => {
    if (isSiteAdmin) {
      provider
        .getCustomers()
        .then((customerdata) => {
          const filtered = customerdata.filter((item) => item.active !== false);
          setProviderError(undefined);
          setAllCustomers(filtered);
        })
        .catch((error) => {
          setProviderError("Unable to retrieve customer list ("+error+")");
        });
    } else {
      if (isValidCustomerAccess && user.id) {
        const desiredRoles = ["customeradmin", "accountmanager","companymanager","companyadmin","companyviewer"];
        provider
          .getUserCustomers(user.id)
          .then((data) => {
            const filtered = data.filter(
              (customer) =>
                customer.active !== false &&
                customer.roles?.some((role) =>
                  desiredRoles.includes(role.toLowerCase())
                )
            );
            setCustomerUsersRole(filtered);
            return Promise.all(
              filtered.map((customer) => {
                const customerId = customer.customerId
                  ? customer.customerId
                  : "";
                return provider
                  .getCustomer(customerId)
                  .then((customerData) => {
                    return Array.isArray(customerData)
                      ? customerData[0]
                      : customerData;
                  });
              })
            );
          })
          .then((customersData) => {
            const activeCustomers = customersData.filter(
              (customerData) => customerData && customerData.active !== false
            );
            setAllCustomers(activeCustomers);
          })
          .catch((error) => {
            setProviderError(
              "Unable to retrieve customer's active  list " + error.message
            );
          });
      }
    }
  };

  useEffect(() => {
    getCustomers();
  }, [user]);

  const handleAddClick = () => {
    setProviderError(undefined);
    setType("Add");
    setShowCustomerDetailForm(true);
    setSelectedCustomer({});
    setShowUserDetail(false);
  };

  const handleFormSubmit = (finalCustomerDetail: IFSCustomer) => {
    if (finalCustomerDetail) {
      finalCustomerDetail.modifiedBy = user.userName;
      if (
        finalCustomerDetail.id === undefined ||
        finalCustomerDetail.id === null ||
        finalCustomerDetail.id === ""
      ) {
        finalCustomerDetail.active = true;
        finalCustomerDetail.createdBy = user.userName;
        new FSTFDataProvider(useFakeData)
          .postCustomer(finalCustomerDetail)
          .then((newCustomer) => { 
            setAllCustomers(prevCustomers => [newCustomer, ...prevCustomers]);
          })
          .catch((error) => {
            setProviderError("Unable to add new customer " + error.message);
          });
      } else {
        new FSTFDataProvider(useFakeData)
          .putCustomer(finalCustomerDetail)
          .then((updatedCustomer) => { 
            setAllCustomers(currentCustomers => 
              currentCustomers.map(customer => 
                customer.id === updatedCustomer.id ? updatedCustomer : customer
              )
            );
          })
          .catch((error) => {
            setProviderError("Unable to update customer " + error.message);
          });
      }
    }

    setShowCustomerDetailForm(false);
    setSelectedCustomer({});
    setShowUserDetail(false);
  };

  const handleCancel = () => {
    setProviderError(undefined);
    setShowCustomerDetailForm(false);
    setSelectedCustomer({});
    setShowUserDetail(false);
  };

  const EditClick = (props: any) => (
    <td>
      {isPermissionGranted(
        USERROLEPERMISSION.EDITCustomer,
        props.dataItem.id
      ) && (
        <div title="Edit Customer">
          <SvgIcon
            icon={editToolsIcon}
            onClick={(e) => handleEditClick(e, props.dataItem)}
          />
        </div>
      )}
    </td>
  );

  const handleEditClick = (event: any, item: IFSCustomer) => {
    setProviderError(undefined);
    setSelectedCustomer(item);
    setShowCustomerDetailForm(true);
    setShowUserDetail(false);
    setType("Edit");
  };

  const DeleteClick = (props: any) => (
    <td>
      {isPermissionGranted(
        USERROLEPERMISSION.DELETECustomer,
        props.dataItem.id
      ) && (
        <div title="Delete Customer">
          <SvgIcon
            icon={trashIcon}
            onClick={() => handleDeleteClick(props.dataItem)}
          />
        </div>
      )}
    </td>
  );

  const handleDeleteClick = (item: IFSCustomer) => {
    setProviderError(undefined);
    setShowCustomerDetailForm(false);
    setShowUserDetail(false);
    item.modifiedBy = user.userName;
    item.active = false;
    const confirmDelete = window.confirm(
      `Are you sure to delete customer : ${item.name}?`
    );
    if (confirmDelete) {
      new FSTFDataProvider(useFakeData)
        .deleteCustomer(item)
        .then(() => { 
          setAllCustomers(currentCustomers => 
              currentCustomers.filter(customer => customer.id !== item.id)
          );
      }).catch((error) => {
          setProviderError("Unable to delete customer "+error.message);
        });
    }
  };

  const LicensesClick = (props: any) => (
    <td>
      {isPermissionGranted(USERROLEPERMISSION.LICENSE, props.dataItem.id) && (
        <div title="View Licenses">
          <SvgIcon
            onClick={() => handleLicensesClick(props.dataItem)}
            icon={clipboardTextIcon}
          />
        </div>
      )}
    </td>
  );

  const handleLicensesClick = (item: IFSCustomer) => {
    navigate(`/licenses/${item.id}`);
  };

  const UsersClick = (props: any) => (
    <td>
      {isPermissionGranted(USERROLEPERMISSION.USER, props.dataItem.id) && (
        <div title="Users">
          <SvgIcon
            icon={userIcon}
            onClick={() => handleUsersClick(props.dataItem)}
          />
        </div>
      )}
    </td>
  );

  const NotesClick = (props: any) => (
    <td>
      {isPermissionGranted(
        USERROLEPERMISSION.NOTESCustomer,
        props.dataItem.id
      ) && (
        <div title="Notes">
          <SvgIcon
            icon={trackChangesIcon}
            onClick={() => handleNotesClick(props.dataItem)}
          />
        </div>
      )}
    </td>
  );

  const handleNotesClick = (item: IFSCustomer) => {
    setSelectedCustomer(item);
    setShowCustomerNotes(true);
  };

  const handleUsersClick = (item: IFSCustomer) => {
    setProviderError(undefined);
    setSelectedCustomer(item);
    setShowUserDetail(true);
    setShowCustomerDetailForm(false);
  };

  const handleAddNote = (e: any) => {
    const postNote = (note: IFSNote) => {
      new FSTFDataProvider(useFakeData)
        .addCustomerNote(note)
        .then((retNote: IFSNote) => {
          setCustomerNotes([retNote, ...customerNotes]);
          setNotesProviderError(undefined);
        })
        .catch((error) => {
          setNotesProviderError("Unable to add customer note, try again later");
        });
    };

    const newNote = {
      createdBy: user.userName,
      createdDate: new Date().toDateString(),
      text: e.noteText,
      customerId: String(selectedCustomer.id),
    } as IFSNote;

    if (e.noteFile && e.noteFile.length > 0) {
      const theFile = e.noteFile[0].getRawFile() as File;
      const reader = new FileReader();

      reader.onloadend = () => {
        newNote.attachment = String(reader.result);
        newNote.attachmentName = theFile.name;
        postNote(newNote);
      };
      reader.readAsDataURL(theFile);
    } else {
      postNote(newNote);
    }
  };

  const renderListItem = (props: ListViewItemProps) => {
    let item = props.dataItem as IFSNote;

    return (
      <div>
        <p>
          {item.text}{" "}
          {item.attachment && (
            <>
              &nbsp;
              <Button fillMode={"flat"}>
                <SvgIcon
                  icon={downloadIcon}
                  onClick={() => requestNoteAttachmentDownload(item)}
                />
              </Button>
            </>
          )}
          <br />
          Added by {item.createdBy} ({item.createdDate})
        </p>
      </div>
    );
  };

  const requestNoteAttachmentDownload = (note: IFSNote) => {
    if (!note.attachment) return;

    saveAs(note.attachment, note.attachmentName || "Note attachment");
  };

  const closeNotesDialog = () => {
    setShowCustomerNotes(false);
    setNotesProviderError(undefined);
  };

  const MessageClick = (props:any) => (  
    <td>  
      {isPermissionGranted(
        USERROLEPERMISSION.MESSAGECustomer,
        props.dataItem.id
      ) && ( 
       <div title="Message">
          <SvgIcon
            icon={envelopIcon}
            onClick={() => handleMessageClick(props.dataItem)}
          />
        </div>
      )}
    </td> 
  );
  
  const handleMessageClick = (customer: IFSCustomer) => { 

    new FSTFDataProvider(useFakeData)
    .getCustomerMessages(String(customer.id))
    .then((data) => {
      setProviderError(undefined);
      setShowMessagesWindow(true);
      setSelectedCustomer(customer);
    }).catch((error) => {
      //TODO: Add Error handling
    })
  }

  const handleCloseMessages = () => {
    setShowMessagesWindow(false);
  }

  const handleLoadMessagesForCustomer = (customer : IFSCustomer) : Promise<IFSMessage[]> => {
    return new Promise((resolve, reject) => {
      new FSTFDataProvider(useFakeData)
      .getCustomerMessages(customer.id!)
        .then((messages) => resolve(messages))
        .catch((error) => reject(`Unable to load messages for ${customer.name}`))
    })
  }
  

  const handleSendMessage = async (message: IFSMessage) : Promise<IFSMessage> => {

    const newMessage = {
      ...message,
      createdBy: user.userName!,
      customerId: String(selectedCustomer.id),
      isUnread: true,
    } as IFSMessage;

    return new Promise(function(resolve, reject) {
      new FSTFDataProvider(useFakeData)
      .sendCustomerMessage(selectedCustomer.id!, newMessage)
      .then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject("Unable to send message, try again later");
      }) 
    });
  }
  
  return (
    <FSTFDefaultTemplate>
      <StackLayout gap={30} orientation="vertical">
      <Card className="fs-customers">
        
            {providerError && (
              <CardHeader>  
              <div className="error">
                <h3>{providerError}</h3>
              </div></CardHeader>
            )}
            <CardBody>
          {allCustomers && (
                <Grid
                  data={allCustomers}
                  resizable={true}
                  scrollable={"scrollable"}
                  sortable={true}
                  size={"small"}
                  className="fs-customer-grid"
                >
                  {/* <Column cell={MessageClick} width={"25px"}/>         */}
                  <Column cell={DeleteClick} className="fs-icon-button" width={"50px"}/>
                  <Column cell={EditClick} className="fs-icon-button" width={"50px"}/>
                  <Column cell={UsersClick} className="fs-icon-button" width={"50px"} />
                  {/* <Column cell={NotesClick} width="40px" width={50}/> */}
                  <Column cell={LicensesClick} className="fs-icon-button" width={"50px"}/>
                  <Column field="name" title="Name" />
                  <Column field="address" title="Address" />
                  {/* <Column field="city" title="City"/>
                  <Column field="postcode" title="Postcode" /> */}
                  <Column field="phone" title="Phone"  />
                  <Column field="email" title="Email" />
                  {/* <Column field="type" title="CustomerType" /> */}
                  {/* <Column
                    field="description"
                    title="CustomerTypeDescription"                    
                  /> */}
                  {/* <Column field="active" title="Active" /> */}
                </Grid>
            )}
            </CardBody>
            <CardFooter>
          <div>
            {isPermissionGranted(USERROLEPERMISSION.ADDCustomer, "") && (
                <Button onClick={handleAddClick} themeColor={"tertiary"}>Add Customer</Button>
              )}
          </div>
          </CardFooter>
          </Card>
        
        {!showUserDetail && showCustomerDetailForm && (
          <CustomerDetailForm
            onSubmit={handleFormSubmit}
            onCancel={handleCancel}
            selectedCustomer={selectedCustomer}
            type={type}
          />
        )}
      {showUserDetail && !showCustomerDetailForm && (
        <FSTFCustomerUsers selectedCustomer={selectedCustomer} />
      )}
      {showCustomerNotes && (
        <>
          <Dialog
            closeIcon={false}
            title={`Notes about ${selectedCustomer.name}`}
            onClose={closeNotesDialog}
          >
            {notesProviderError !== undefined && (
              <div className="error">{notesProviderError}</div>
            )}

            <ListView
              data={customerNotes.slice(skip, skip + take)}
              item={renderListItem}
              style={{ width: "100%" }}
            />

            <Pager
              skip={skip}
              take={take}
              onPageChange={handlePageChange}
              total={customerNotes.length}
            />

            <AddNoteForm handleSubmit={(e) => handleAddNote(e)} />

            <DialogActionsBar>
              <Button
                svgIcon={xIcon}
                onClick={() => {
                  setShowCustomerNotes(false);
                }}
              >
                Close
              </Button>
            </DialogActionsBar>
          </Dialog>
        </>
      )}
      
      {showMessagesWindow && (
        <UserMessageForm 
          handleLoadMessagesForCustomer={(customer) => {return handleLoadMessagesForCustomer(customer)}}
          title={`Messages - ${selectedCustomer!.name}`} 
          handleSubmit={(message) => { return handleSendMessage(message); } } 
          handleClose={() => handleCloseMessages()}
          userAccessibleCustomers={[selectedCustomer]} />
      )}
     </StackLayout>
    </FSTFDefaultTemplate>
  );
};
