import { useContext, useEffect } from "react";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { FSAppContext } from "../../providers/FSTF.Context";

export const FSTFAbout = (props: any) => {
  const {setPageTitle} = useContext(FSAppContext);
  
  useEffect(() => { 
    setPageTitle("About");
  }, []);

  return (
    <FSTFDefaultTemplate>
      <div>About</div>
    </FSTFDefaultTemplate>
  );
};
