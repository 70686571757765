import "./App.scss";
import {  HashRouter } from "react-router-dom";
import { GridLayout } from "@progress/kendo-react-layout";
import FSTFAppContextProvider from "./providers/FSTF.AppContextProvider";
import "reflect-metadata";
import { FSTFHeader } from "./components/organisms/FSTF.Header";
import { FSTFFooter } from "./components/organisms/FSTF.Footer";
import FSTFRoutes from "./components/organisms/FSTF.Routes";
//App Insights with ReactJS: https://docs.microsoft.com/azure/azure-monitor/app/javascript-react-plugin
import { reactPlugin } from "./service/FSTF.AppInsights";
import { FSDebugOutputPanel } from "./components/molecules/FSTF.Debug.Output";
import { WithAppInsights } from "./components/atoms/FSTF.AppInsightsWrapper";
import  "@progress/kendo-svg-icons";
import { IconsContext } from '@progress/kendo-react-common';
import { FSEnvironmentProvider } from "./providers/FSTF.EnvironmentProvider";

function App() {

  return WithAppInsights(<>
  <IconsContext.Provider value={{ type: 'svg' }}>
    <FSEnvironmentProvider>
        <FSTFAppContextProvider>
          <FSDebugOutputPanel/>
            <div className="TrustedFilterApp k-d-flex-col">
                <HashRouter>
                  <FSTFHeader />
                  <GridLayout>
                    <div className="app-main">
                      <FSTFRoutes/>
                    </div>
                  </GridLayout>
                </HashRouter>
                </div>
            <div className="push"></div>
            <FSTFFooter />
          </FSTFAppContextProvider>
      </FSEnvironmentProvider>
    </IconsContext.Provider>
  </>);
}



export default App;
