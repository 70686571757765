import { Button } from "@progress/kendo-react-buttons";
import { PageChangeEvent, Pager } from "@progress/kendo-react-data-tools";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TextAreaChangeEvent, TextArea } from "@progress/kendo-react-inputs";
import { ListViewItemProps, ListView } from "@progress/kendo-react-listview";
import { redoIcon, xIcon, paperPlaneIcon } from "@progress/kendo-svg-icons";
import { useState, useReducer, useEffect } from "react";
import IFSMessage from "../../types/IFSMessage";
import { SvgIcon } from "@progress/kendo-react-common";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import IFSCustomer from "../../types/IFSCustomer";

export interface UserMessageProps {
    title: string;
    handleSubmit:(e: IFSMessage) => Promise<IFSMessage>;
    handleLoadMessagesForCustomer(customer : IFSCustomer) : Promise<IFSMessage[]>;
    handleClose:() => void;
    userAccessibleCustomers: IFSCustomer[];
  }
  
  type CurrentMessageState = {
    parentMessages: IFSMessage[];
    parentChildMessageMap : Map<IFSMessage, IFSMessage[]>;
  }

  enum MessageLoaderState {
    LOADING = "loading",
    FINISHED = "finished"
  }

export const UserMessageForm = (props: UserMessageProps) => {    
  const {title, handleSubmit, handleClose, userAccessibleCustomers, handleLoadMessagesForCustomer} = props; 

	const initialPagerState = {skip: 0, take: 4};
  const [messagesPagerState, setMessagesPagerState] = useState(initialPagerState);
  const [providerError, setMessagesProviderError] = useState<string>();
  const [messageLoadingState, setMessageLoadingState] = useState<MessageLoaderState>(MessageLoaderState.LOADING);

  const [currentCustomer, setCurrentCustomer] = useState<IFSCustomer>(userAccessibleCustomers[0]);
  const [currentMessageState, setCurrentMessageState] = useState<CurrentMessageState>();
  const [currentCustomerAllMessages, setCurrentCustomerAllMessages] = useState<IFSMessage[]>([]);
  
  useEffect(() => {
    //if you switch the dropdown and provider is loading messages
    //it loads the wrong set of messages when provider returns
     //this flag is set to indicate not to change state if useEffect is out of
     //sync with the UI
     let switchedCustomers = false;

     setMessageLoadingState(MessageLoaderState.LOADING);
     handleLoadMessagesForCustomer(currentCustomer)
       .then((messages) => {
         if (!switchedCustomers) {
           setCurrentCustomerAllMessages(messages);
           setMessagesProviderError(undefined);
           setMessageLoadingState(MessageLoaderState.FINISHED);
         }
       }).catch((error) => { 
         if (!switchedCustomers) {
           setMessagesProviderError(error);       
           setMessageLoadingState(MessageLoaderState.FINISHED);
         }
       })
     
       return (() => { switchedCustomers = true });
   }, [currentCustomer]);

   useEffect(() => {
     const parentMessages = currentCustomerAllMessages.filter((m : IFSMessage) => m.parentMessageId === undefined);
     const parentChildMap = new Map<IFSMessage, IFSMessage[]>();

     const sorter = (messageA: IFSMessage, messageB: IFSMessage) : number => {
       return messageA.createdDate < messageB.createdDate ? 1 : -1;
     }

     parentMessages.sort(sorter);

     parentMessages.forEach((parent : IFSMessage) => {
       const children = currentCustomerAllMessages.filter((m : IFSMessage) => String(m.parentMessageId) === String(parent.id));
       children.sort(sorter);
       parentChildMap.set(parent, children);
     });

     setCurrentMessageState({ parentMessages: parentMessages, parentChildMessageMap: parentChildMap});
     setMessagesPagerState(initialPagerState);
     dispatchMessagingEvent({action: MessagingEvent.STOP_REPLY});
     
   }, [currentCustomerAllMessages]);

   const handlePageChange = (e: PageChangeEvent) => {
     setMessagesPagerState({
       skip: e.skip,
       take: e.take,
     });
   };
 
  const { skip, take } = messagesPagerState;
 
  const onDropDownSelectionChanged = (event: DropDownListChangeEvent) => {
    const target = event.target.value;
    const nextCustomer = userAccessibleCustomers.find((id) => {return id.name === target ? id : undefined});
    setCurrentCustomer(nextCustomer!);
  }

  const messagingHandler = (state: MessagingAction, action: MessagingAction) : MessagingAction => {
 
    switch (action.action) {
 
      case MessagingEvent.RESET:
        return {
          ...action,
          parentMessage: undefined,
          text: ""
        }
 
      case MessagingEvent.INPUT:
        return {
          ...action,
          parentMessage: state.parentMessage,
        }
 
       case MessagingEvent.START_REPLY:
        return {
          ...action,
          text: state.text
        }

      case MessagingEvent.STOP_REPLY:
        return {
          ...action,
          parentMessage: undefined,
          text: state.text
        }
      }
     throw new Error("event not handled");
  }

  enum MessagingEvent {
    START_REPLY = "reply",
    STOP_REPLY = "noreply",
    RESET = "reset",
    INPUT = "input"
  }
  
  type MessagingAction = {
    action:MessagingEvent;
    parentMessage?: IFSMessage;
    text?: string
  }
    
  const initialState = {action: MessagingEvent.RESET};
  const [messageState, dispatchMessagingEvent] = useReducer(messagingHandler, initialState);
  
  const changeEvent = (e: TextAreaChangeEvent) => {
    dispatchMessagingEvent({action: MessagingEvent.INPUT, text: e.value});
  }
  
  const closeReply = () => {
    dispatchMessagingEvent({ action: MessagingEvent.STOP_REPLY});
  }
  
  const openReply = (item: IFSMessage) => {
    dispatchMessagingEvent({action: MessagingEvent.START_REPLY, parentMessage: item})
  }
  
  const sendMessage = async () => {
    const newMessage = { 
      createdDate: Date.now(),
      parentMessageId: messageState.parentMessage?.id, 
      text: messageState.text!,
      customerId: currentCustomer.id!
    } as IFSMessage;
  
    await handleSubmit(newMessage)
    .then((result) => {
      dispatchMessagingEvent({action: MessagingEvent.RESET});
      setCurrentCustomerAllMessages([result, ...currentCustomerAllMessages]);
      setMessagesProviderError(undefined);
    }).catch((error) => {
      setMessagesProviderError(error);
    });
  }
  
  const disableSendButton = () : boolean => {
    //if send button can be enabled, we have to return false
    //because the return value is read into the `disabled` html property
    return !(messageState.text !== undefined && messageState.text.length > 0);
  }
  
  const formatDate = (date: number) : string => {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ]
    const d = new Date(date);
    return `${d.getDay()}-${months[d.getMonth()]}-${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  }

  const renderListItem = (props: ListViewItemProps) => {
    let item = props.dataItem as IFSMessage;
    const replying = messageState.parentMessage === item;
  
    const children = currentMessageState?.parentChildMessageMap.get(item);
  
    return (
      <div style = {{ borderStyle: replying ? "dotted" : "", wordBreak: "break-all", whiteSpace: "normal" }} >
        {item.text} { item.createdBy && ( 
          <>
            &nbsp;<Button fillMode={"link"} onClick={() => openReply(item)}>
            <SvgIcon icon={redoIcon}/></Button>
          </> 
        )}
        
        <br />
        
        {item.createdBy} ({formatDate(item.createdDate)})
        
        <ul>
          {children?.map(child => {
            return (
              <li key={child.id}>{child.text}
                <br/>
                <span>{child.createdBy} - {formatDate(child.createdDate)}</span>
              </li>
            )
          })}
        </ul>
        
      </div>
    );
  };

  return (
    <Dialog onClose={handleClose} title={title}>
      { userAccessibleCustomers.length > 1 && (
        <DropDownList 
          data={userAccessibleCustomers.map((c) => { return c.name})}
          defaultValue={userAccessibleCustomers[0].name}
          onChange={onDropDownSelectionChanged}
        />
      )}
      
      { messageLoadingState === MessageLoaderState.FINISHED && (
          <>
            <ListView
              data={currentMessageState?.parentMessages.slice(skip, skip + take)}
              item={renderListItem}  
            />
      
            <Pager
              skip={skip}
              take={take}
              onPageChange={handlePageChange}
              total={currentMessageState?.parentMessages.length || 0}
            />
          </>
        )}

        { messageLoadingState === MessageLoaderState.LOADING && (
          <p>Loading....</p>
        )}
  
        { messageState.parentMessage !== undefined && (
          <>
            <div>Replying: <SvgIcon icon={xIcon} onClick={() => closeReply()} /></div>
          </>
        )}

        <span style = {{display: "flex", alignItems: "end"}}>
          <TextArea 
            autoFocus={true}
            onChange={(e) => changeEvent(e)}
            value={messageState.text}
            rows={2}
          />
              
          <Button disabled={disableSendButton()} fillMode={"flat"} svgIcon={paperPlaneIcon} onClick={() => sendMessage()} />
        </span>
  
        { providerError !== undefined && (
          <div className="error">{providerError}</div>
        )}
          
        <DialogActionsBar>
          <Button onClick={handleClose}>Close</Button>
        </DialogActionsBar>        
    </Dialog>
  );
};
  