import { ConfigurationTemplate } from "../models/FSTF.ConfigurationTemplate";

export class ConfigurationTemplateManager {
  public async GetTemplates(
    pluginName: string,
    sectionName: string,
    mode: string
  ): Promise<ConfigurationTemplate> {
    try {
      if (mode.toLowerCase() === "section") {
        // check for a file containing mulitple configs...
        let responseMulti = await fetch(
          `/assets/templates/${pluginName.toLowerCase()}/${sectionName.toLowerCase()}.configs.json`
        );
        if (responseMulti.ok) {
          const contentType = responseMulti.headers.get("Content-Type");
          if (contentType && contentType.includes("application/json")) {
            var sections = await responseMulti.json();
            return new ConfigurationTemplate(true, "multi", sections);
          }
        }
        // if no multiple file then check for a single config file
        let response = await fetch(
          `/assets/templates/${pluginName.toLowerCase()}/${sectionName.toLowerCase()}.json`
        );

        if (!response.ok) {
          return new ConfigurationTemplate(false);
        }

        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          var section = await response.json();
          return new ConfigurationTemplate(true, "single", [section]);
        } else {
          return new ConfigurationTemplate(false);
        }
      }

      return new ConfigurationTemplate(true, "not-templated");
    } catch {
      return new ConfigurationTemplate(false);
    }
  }
}
