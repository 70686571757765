export class ConfigurationTemplate {
  public exists: boolean = false;
  public type?: string = "unset";
  public data?: Array<any> = [];

  constructor(exists: boolean, type?: string, sections?: Array<any>) {
    this.exists = exists;
    if (type) this.type = type;
    if (sections) this.data = sections;
  }
}
