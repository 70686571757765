import { IFSLicensePack as FSLicensePack } from "../types/IFSLicensePack";
import IFSAdministrationConfiguration from "../types/IFSAdministrationConfiguration";
import { IFSDataProvider } from "../types/IFSDataProvider";
import IFSProduct from "../types/IFSProduct";
import IFSDocumentation from "../types/IFSDocumentation";
import IFSCustomer from "../types/IFSCustomer";
import IFSUsers from "../types/IFSUsers";
import { IFSCustomerRoles, IFSRoles, IFSSiteRoles } from "../types/IFSRoles";
import IFSAppDownload from "../types/IFSAppDownload";
import IFSNote from "../types/IFSNote";
import IFSCustomerUsers from "../types/IFSCustomerUsers";
import IFSMessage from "../types/IFSMessage";
import { generateUniqueID } from "./FSTF.GenerateUniqueID";
import { IFSLicenseSet } from "../types/IFSLicenseSet";
import { Routes } from "react-router-dom";
import IFSResetRequest from "../types/IFSResetRequest";
import { IFSLicenseRequest } from "../types/IFSLicenseRequest";
import IFSLicenseAgreement from "../types/IFSLicenseAgreement";
import IFSDashboard from "../types/IFSDashboard";

const BASE_URL = "http://localhost:3001/";
//const BASE_URL= "https://4secure-pane-demo-api.azurewebsites.net"

const ROUTES = {
  products: "products/",
  licensePacks: "licensePacks/",
  users: "users/",
  companies: "companies/",
  documentation: "documentation/",
  customers: "customers/",
  roles: "roles/",
  downloads: "downloads/",
  siteRoles: "siteRoles/",
  notes: "notes/",
  customerusers: "customerusers/",
  messages: "messages/",
  licenseSets: "licensesets/",
};

export class FSTFFakeDataProvider implements IFSDataProvider {
  getDashboard(): Promise<IFSDashboard> {
    throw new Error("Method not implemented.");
  }
  toggleDocumentationState(download: IFSDocumentation): Promise<IFSDocumentation> {
    throw new Error("Method not implemented.");
  }
  toggleDownloadState(download: IFSAppDownload): Promise<IFSAppDownload> {
    throw new Error("Method not implemented.");
  }
  checkLicenseAgreement(
    licenseAgreement: IFSLicenseAgreement
  ): Promise<number> {
    throw new Error("Method not implemented in fake api.");
  }

  addLicenseAgreement(
    licenseAgreement: IFSLicenseAgreement
  ): Promise<IFSLicenseAgreement> {
    throw new Error("Method not implemented in fake api.");
  }

  downloadProductDocumentation(documentation: IFSDocumentation): Promise<Blob> {
    throw new Error("Method not implemented.");
  }
  downloadProductBinary(download: IFSAppDownload): Promise<[Blob, Headers]> {
    throw new Error("Method not implemented.");
  }

  async setFakeDataFlag(b: boolean) {}
  async setDataProvider(dp: IFSDataProvider) {}
  getReleases(): Promise<any> {
    throw new Error("Method not implemented.");
  }
  async getUsers(): Promise<IFSUsers[]> {
    try {
      const url = `${BASE_URL}${ROUTES.users}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const userData: IFSUsers[] = await response.json();
      return userData;
    } catch (error) {
      console.error("Failed to fetch Users:", error);
      throw error;
    }
  }
  async getUser(id: string): Promise<IFSUsers> {
    try {
      const url = `${BASE_URL}${ROUTES.users}?id=${id}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const userData: IFSUsers = await response.json();
      return userData;
    } catch (error) {
      console.error("Failed to fetch User:", error);
      throw error;
    }
  }

  getAdministrationConfiguration(): Promise<IFSAdministrationConfiguration> {
    return new Promise((resolve) => {
      resolve({
        email: "admin@4-secure.com",
        maintenanceMode: false,
        configuration: [{ key: "key1", value: "value1" }],
      });
    });
  }

  async getProduct(id: string): Promise<IFSProduct> {
    const url = `${BASE_URL}${ROUTES.products}${id}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const product: IFSProduct = await response.json();
      return product;
    } catch (error) {
      console.error(`Failed to fetch product with id ${id}:`, error);
      throw error;
    }
  }

  async getProducts(): Promise<IFSProduct[]> {
    try {
      const url = `${BASE_URL}${ROUTES.products}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const responseProducts: IFSProduct[] = await response.json();
      return responseProducts;
    } catch (error) {
      console.error("Failed to fetch products:", error);
      throw error;
    }
  }

  async getLicensePacksForCompany(id: string): Promise<FSLicensePack[]> {
    try {
      // licensepacks?customerId=${id} where customerId is the id of the account logged in
      const url = `${BASE_URL}${ROUTES.licensePacks}?customerId=${id}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const licensePacks: FSLicensePack[] = await response.json();
      return licensePacks;
    } catch (error) {
      console.error("Failed to fetch license packs:", error);
      throw error;
    }
  }

  async getLicensePack(id: string): Promise<FSLicensePack> {
    try {
      const url = `${BASE_URL}${ROUTES.licensePacks}${id}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const licensePack: FSLicensePack = await response.json();
      return licensePack;
    } catch (error) {
      console.error("Failed to fetch license pack:", error);
      throw error;
    }
  }

  async addLicensePack(licensePack: FSLicensePack): Promise<FSLicensePack> {
    try {
      const url = `${BASE_URL}${ROUTES.licensePacks}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(licensePack),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const newLicensePack: FSLicensePack = await response.json();
      return newLicensePack;
    } catch (error) {
      console.error("Failed to post license pack:", error);
      throw error;
    }
  }

  async deleteLicensePack(id: string): Promise<void> {
    try {
      const url = `${BASE_URL}${ROUTES.licensePacks}${id}`;
      const response = await fetch(url, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      return;
    } catch (error) {
      console.error("Failed to delete license pack:", error);
      throw error;
    }
  }

  async editLicensePack(
    id: string,
    licensePack: FSLicensePack
  ): Promise<FSLicensePack> {
    for (let i = 0; i < licensePack.licenses!.length; i++) {
      if (typeof licensePack.licenses![i]["id"] === "undefined") {
        licensePack.licenses![i] = {
          id: generateUniqueID(),
          ...licensePack.licenses![i],
        };
      }
    }

    try {
      const url = `${BASE_URL}${ROUTES.licensePacks}${id}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(licensePack),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const newLicensePack: FSLicensePack = await response.json();
      return newLicensePack;
    } catch (error) {
      console.error("Failed to post license pack:", error);
      throw error;
    }
  }

  async downloadLicensePack(
    id: string
  ): Promise<{ filename: string; blob: Blob }> {
    try {
      const url = `${BASE_URL}${ROUTES.licensePacks}${id}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const licensePack: string = await response.json();
      return {
        filename: "trustedfilter_license.tf",
        blob: new Blob([licensePack]),
      };
    } catch (error) {
      console.error("(FAKE)Failed to download license pack:", error);
      throw error;
    }
  }

  async getCustomers(): Promise<IFSCustomer[]> {
    try {
      const url = `${BASE_URL}${ROUTES.customers}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const allCustomers: IFSCustomer[] = await response.json();
      return allCustomers;
    } catch (error) {
      console.error("Failed to fetch customers:", error);
      throw error;
    }
  }

  async getCustomerRoles(): Promise<IFSCustomerRoles[]> {
    try {
      const url = `${BASE_URL}${ROUTES.roles}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const roles: IFSCustomerRoles[] = await response.json();
      return roles;
    } catch (error) {
      console.error("Failed to fetch Roles:", error);
      throw error;
    }
  }

  async getProductDocumentation(id: string): Promise<IFSDocumentation[]> {
    try {
      const url = `${BASE_URL}${ROUTES.documentation}?ProductId=${id}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const docs: IFSDocumentation[] = await response.json();
      return docs;
    } catch (error) {
      throw error;
    }
  }

  async saveProductDocumentation(
    newDoc: IFSDocumentation
  ): Promise<IFSDocumentation> {
    try {
      const url = `${BASE_URL}${ROUTES.documentation}?ProductId=${newDoc.productId}`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(newDoc),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.log(response.status);
        throw new Error(`Error: ${response.status}`);
      }
      const docs: IFSDocumentation = await response.json();
      return docs;
    } catch (error) {
      throw error;
    }
  }

  async saveProductUpload(newUpload: IFSAppDownload): Promise<IFSAppDownload> {
    try {
      const url = `${BASE_URL}${ROUTES.downloads}?ProductId=${newUpload.productId}`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(newUpload),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.log(response.status);
        throw new Error(`Error: ${response.status}`);
      }
      const dl: IFSAppDownload = await response.json();
      return dl;
    } catch (error) {
      throw error;
    }
  }

  async getProductDownloads(id: string): Promise<IFSAppDownload[]> {
    try {
      const url = `${BASE_URL}${ROUTES.downloads}?ProductId=${id}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const downloads: IFSAppDownload[] = await response.json();
      return downloads;
    } catch (error) {
      throw error;
    }
  }

  async addProduct(newProd: IFSProduct): Promise<IFSProduct> {
    try {
      const url = `${BASE_URL}${ROUTES.products}`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(newProd),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const added: IFSProduct = await response.json();
      return added;
    } catch (error) {
      throw error;
    }
  }
  async postCustomer(newCustomer: IFSCustomer): Promise<IFSCustomer> {
    try {
      if (
        newCustomer.id === undefined ||
        newCustomer.id === "" ||
        newCustomer.id === null
      ) {
        newCustomer.id = crypto.randomUUID();
      }
      const url = `${BASE_URL}${ROUTES.customers}`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(newCustomer),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.log(response.status);
        throw new Error(`Error: ${response.status}`);
      }
      const customer: IFSCustomer = await response.json();
      return customer;
    } catch (error) {
      throw error;
    }
  }
  async putCustomer(updatedCustomer: IFSCustomer): Promise<IFSCustomer> {
    try {
      const url = `${BASE_URL}${ROUTES.customers}${updatedCustomer.id}`;
      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(updatedCustomer),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.log(response.status);
        throw new Error(`Error: ${response.status}`);
      }
      const customer: IFSCustomer = await response.json();
      return customer;
    } catch (error) {
      throw error;
    }
  }
  async postUser(newUser: IFSUsers): Promise<IFSUsers> {
    try {
      if (
        newUser.id === undefined ||
        newUser.id === "" ||
        newUser.id === null
      ) {
        newUser.id = crypto.randomUUID();
      }
      const url = `${BASE_URL}${ROUTES.users}`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(newUser),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.log(response.status);
        throw new Error(`Error: ${response.status}`);
      }
      const user: IFSUsers = await response.json();
      return user;
    } catch (error) {
      throw error;
    }
  }
  async putCustomerUser(user: IFSUsers): Promise<IFSUsers> {
    try {
      const url = `${BASE_URL}${ROUTES.users}${user.id}`;
      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.log(response.status);
        throw new Error(`Error: ${response.status}`);
      }
      const customerUser: IFSUsers = await response.json();
      return customerUser;
    } catch (error) {
      throw error;
    }
  }

  async getSiteRoles(): Promise<IFSSiteRoles[]> {
    try {
      const url = `${BASE_URL}${ROUTES.siteRoles}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const roles: IFSSiteRoles[] = await response.json();
      return roles;
    } catch (error) {
      console.error("Failed to fetch site Roles:", error);
      throw error;
    }
  }

  async deleteProduct(productId: string): Promise<boolean> {
    try {
      const url = `${BASE_URL}${ROUTES.products}${productId}`;
      const response = await fetch(url, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error(`Error:  ${response.status}`);
      }

      return true;
    } catch (error) {
      throw error;
    }
  }

  async addCustomerNote(note: IFSNote): Promise<IFSNote> {
    try {
      const url = `${BASE_URL}${ROUTES.notes}?customerId=${note.customerId}`;
      const options = {
        method: "POST",
        body: JSON.stringify(note),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const respNote: IFSNote = await response.json();
      return respNote;
    } catch (error) {
      throw error;
    }
  }

  async getCustomerNotes(customerId: string): Promise<IFSNote[]> {
    try {
      const url = `${BASE_URL}${ROUTES.notes}?customerId=${customerId}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const notes: IFSNote[] = await response.json();
      return notes;
    } catch (error) {
      throw error;
    }
  }

  async getUserCustomers(userId: string): Promise<IFSCustomerUsers[]> {
    try {
      const url = `${BASE_URL}${ROUTES.customerusers}?userId=${userId}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const notes: IFSCustomerUsers[] = await response.json();
      return notes;
    } catch (error) {
      throw error;
    }
  }
  async getCustomerUsers(customerId: string): Promise<IFSCustomerUsers[]> {
    try {
      const url = `${BASE_URL}${ROUTES.customerusers}?customerId=${customerId}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const notes: IFSCustomerUsers[] = await response.json();
      return notes;
    } catch (error) {
      throw error;
    }
  }
  async putCustomerUserRoles(
    updatedCustomerUser: IFSCustomerUsers
  ): Promise<IFSCustomerRoles[]> {
    try {
      const url = `${BASE_URL}${ROUTES.customerusers}${updatedCustomerUser.id}`;
      const options = {
        method: "PUT",
        body: JSON.stringify(updatedCustomerUser),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const respNote: IFSCustomerRoles[] = await response.json();
      return respNote;
    } catch (error) {
      throw error;
    }
  }
  async postCustomerUserRoles(
    newCustomerUser: IFSCustomerUsers
  ): Promise<IFSCustomerRoles[]> {
    try {
      if (
        newCustomerUser.id === undefined ||
        newCustomerUser.id === "" ||
        newCustomerUser.id === null
      ) {
        newCustomerUser.id = crypto.randomUUID();
      }
      const url = `${BASE_URL}${ROUTES.customerusers}`;
      const options = {
        method: "POST",
        body: JSON.stringify(newCustomerUser),
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const respNote: IFSCustomerRoles[] = await response.json();
      return respNote;
    } catch (error) {
      throw error;
    }
  }
  async getCustomer(customerId: string): Promise<IFSCustomer> {
    try {
      const url = `${BASE_URL}${ROUTES.customers}?id=${customerId}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const customer: IFSCustomer = await response.json();
      return customer;
    } catch (error) {
      console.error("Failed to fetch customer:", error);
      throw error;
    }
  }

  async getCustomerMessages(customerId: string): Promise<IFSMessage[]> {
    try {
      const url = `${BASE_URL}${ROUTES.messages}?customerId=${customerId}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const messages: IFSMessage[] = await response.json();
      return messages;
    } catch (error) {
      throw error;
    }
  }

  async sendCustomerMessage(
    customerId: string,
    message: IFSMessage
  ): Promise<IFSMessage> {
    try {
      const url = `${BASE_URL}${ROUTES.messages}`;
      const options = {
        method: "POST",
        body: JSON.stringify(message),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const respMessage: IFSMessage = await response.json();

      return respMessage;
    } catch (error) {
      throw error;
    }
  }

  async getLicenseSets(): Promise<IFSLicenseSet[]> {
    try {
      const url = `${BASE_URL}${ROUTES.licenseSets}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const licenceSets: IFSLicenseSet[] = await response.json();

      return licenceSets;
    } catch (error) {
      throw error;
    }
  }
  async resetPasswordRequest(email: string): Promise<string> {
    try {
      const resetcode = crypto.randomUUID();
      const id = crypto.randomUUID();
      const createdDate = new Date();
      const UserResponse = await this.getUsers();
      const request: IFSResetRequest = {
        id: id,
        email: email,
        resetcode: resetcode,
        createdDate: createdDate.toDateString(),
      };
      const user = UserResponse.find((user) => user.email === email);
      if (user != null) {
        const url = `${BASE_URL}reset-req`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        });
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const resetReq: string = `${BASE_URL}/reset/${resetcode}`;
        return resetReq;
      }
      throw new Error(`Error: User not found`);
    } catch (error) {
      throw error;
    }
  }
  async resetPassword(
    email: string,
    password: string,
    resetcode: string
  ): Promise<boolean> {
    const resetReq = await this.validateReset(email, resetcode);
    const UserResponse = await this.getUsers();
    const user = UserResponse.find((user) => user.email === email);
    if (resetReq != undefined && user != null) {
      const updatedUser = { ...user, password: password };
      try {
        const url = `${BASE_URL}${ROUTES.users}${updatedUser.id}`;
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedUser),
        });

        if (response.ok) {
          console.log("password has been reset");
          await this.deleteResetReq(resetReq.id);
          return true;
        } else {
          console.error("Failed to reset password:", await response.statusText);
          return false;
        }
      } catch (error) {
        console.error("Error during password reset:", error);
        throw error;
      }
    }
    return false;
  }
  async validateReset(
    email: string,
    resetcode: string
  ): Promise<IFSResetRequest | null> {
    try {
      const url = `${BASE_URL}reset-req/?email=${encodeURIComponent(
        email
      )}&resetcode=${encodeURIComponent(resetcode)}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        console.error(`Error: ${response.status}`);
        return null;
      }
      const result: IFSResetRequest[] = await response.json();
      return result.length > 0 ? result[0] : null;
    } catch (error) {
      console.error("Error during reset validation:", error);
      return null;
    }
  }
  async deleteResetReq(id: string) {
    try {
      const url = `${BASE_URL}reset-req/${id}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  async generateLicensePack(
    licenseRequest: IFSLicenseRequest
  ): Promise<string> {
    throw new Error("Not implemented in fake provider");
  }

  async deleteCustomer(deletedCustomer: IFSCustomer): Promise<string> {
    try {
      if (
        deletedCustomer.id === undefined ||
        deletedCustomer.id === "" ||
        deletedCustomer.id === null
      )
        return "Customer ID is required";
      await this.getCustomerUsers(deletedCustomer.id).then((data) => {
        data.forEach(async (user) => {
          await this.deleteCustomerUserRoles(user);
        });
      });
      const url = `${BASE_URL}${ROUTES.customers}${deletedCustomer.id}`;
      const response = await fetch(url, {
        method: "DELETE",
        body: JSON.stringify(deletedCustomer),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.log(response.status);
        throw new Error(`Error: ${response.status}`);
      }
      return response.statusText;
    } catch (error) {
      throw error;
    }
  }
  async deleteCustomerUserRoles(
    updatedCustomerUser: IFSCustomerUsers
  ): Promise<any> {
    try {
      const url = `${BASE_URL}${ROUTES.customerusers}${updatedCustomerUser.id}`;
      const options = {
        method: "DELETE",
        body: JSON.stringify(updatedCustomerUser),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async deleteUser(deletedUser: IFSUsers): Promise<any> {
    try {
      if (
        deletedUser.id === undefined ||
        deletedUser.id === "" ||
        deletedUser.id === null
      )
        return "Customer ID is required";
      await this.getUserCustomers(deletedUser.id).then((data) => {
        data.forEach(async (customer) => {
          await this.deleteCustomerUserRoles(customer);
        });
      });
      const url = `${BASE_URL}${ROUTES.users}${deletedUser.id}`;
      const response = await fetch(url, {
        method: "DELETE",
        body: JSON.stringify(deletedUser),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.log(response.status);
        throw new Error(`Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async getEula(): Promise<string> {
    throw new Error("Method not implemented.");
  }
}
