import { FSEnvironmentProvider, FSEnvironmentService } from "./FSTF.EnvironmentProvider";
import IFSUsers from "../types/IFSUsers";
import { IFSAuthProvider } from "./FSTF.AuthProvider";
import { FSLocalContentService } from "./FSTF.LocalContentProvider";
import { FSCustomerRoles, FSSiteRoles, IFSSiteRoles } from "../types/IFSRoles";


export class FSTFApiAuthProvider implements IFSAuthProvider {

    environmentService = new FSEnvironmentService();
    localContentService = new FSLocalContentService();
    
    async login(username: string, password: string): Promise<IFSUsers> {
        const apiVersion=this.environmentService.getEnvironment().ApiVersion;
        const loginUrl = `${this.environmentService.getEnvironment().AuthUrl}/api/v${apiVersion}/auth/login`;
        const userUrl = `${this.environmentService.getEnvironment().BaseUrl}/api/v${apiVersion}/users/user`;

        const authObj = { UserName: username, Password: password };

        try {
            const loginResponse = await fetch(loginUrl, {
                method: "POST",
                body: JSON.stringify(authObj),
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (!loginResponse.ok) {
                throw new Error(`Error: ${loginResponse.status}`);
            }

            const loginJson = await loginResponse.json();

            if (loginJson["accessToken"]) {
                const userResponse = await fetch(userUrl, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${loginJson["accessToken"]}`
                    }
                });

                if (!userResponse.ok) {
                    throw new Error(`Error: ${userResponse.status}`);
                }
                const ur=await userResponse.json();
                const userDetails = ur as IFSUsers;
                console.log("UserDetails",{"userDetails":userDetails,"ur":ur});
                userDetails.fullSiteRoles=(ur.siteRoles as string[])?.map(d=>new FSSiteRoles("",d,d));
                userDetails.siteRoles=ur.siteRoles;
                userDetails.companyRoles=[];
                userDetails.token = loginJson["accessToken"];
                userDetails.refreshToken = loginJson["refreshToken"];

                this.localContentService.SetUser(userDetails);
                this.localContentService.SetAccessToken(loginJson["accessToken"]); 
                this.localContentService.SetRefreshToken(loginJson["refreshToken"]);
                return userDetails;
            }

            throw new Error("No access token in response");

        } catch (error) {
            throw error;
        }
    }

}
