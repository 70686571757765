import {  StackLayout } from "@progress/kendo-react-layout";
import IFSLicense from "../../types/IFSLicense";

export const FSTFLicenseItem = (props: IFSLicense) => {
  return (
    <StackLayout orientation="horizontal" className="fs-licenseitem-bar">
      <div className="fs-inner">        
        <div className="fs-reference">{props.reference}</div>
        <div className="fs-info"><span className="fs-name">{props.name}</span><br/>
        <span className="fs-description">{props.description}</span></div>
      </div>
    </StackLayout>

  );
};
