//App Insights with ReactJS: https://docs.microsoft.com/azure/azure-monitor/app/javascript-react-plugin
//https://github.com/microsoft/applicationinsights-react-js/tree/main/sample/applicationinsights-react-sample

import {ApplicationInsights, ITelemetryItem} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

import { FSEnvironmentService} from '../providers/FSTF.EnvironmentProvider';

const environmentService=new FSEnvironmentService();

let environment= environmentService?.getEnvironment();
const instrumentationKey =  environment?.AppInsightsKey;
const siteIdentity=environment?.AppInsightsSiteId;
const connString=`instrumentationKey=${instrumentationKey};IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/`;

console.log("AppInsights Key: "+instrumentationKey);
console.log("Environment",environment);

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: connString,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
});
if(!environment?.AppInsightsDisabled){
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((env:ITelemetryItem) => {
      env.tags = env.tags || [];
      env.tags["site.id"] = siteIdentity;
  });
}else{
  console.log("App Insights is disabled by environment configuration");
}


export { reactPlugin, appInsights };