import { Card, CardBody } from "@progress/kendo-react-layout";
import { WrappedText } from "../atoms/FSTF.WrappedText";
import React from "react";

import IFSUsers from "../../types/IFSUsers";
import { IFSLocalContentService } from "../../providers/FSTF.LocalContentProvider";
import { IFSEnvironmentService } from "../../providers/FSTF.EnvironmentProvider";
import { IFSTFAppContextProps } from "../../providers/FSTF.Context";


export const withCard = (content: any) => {
    return (
      <Card>
        <CardBody>{content}</CardBody>
      </Card>
    );
  };


export const ShowLocalContent = (localContentService:IFSLocalContentService|undefined)=>{
    return withCard(<div>Local Content<br/>
      <ul>
      <li>Page:{localContentService?.GetLocalContent().CurrentPage}</li>
      <li>Token:<WrappedText>{localContentService?.GetLocalContent().AccessToken}</WrappedText></li>
      <li>RefreshToken:<WrappedText>{localContentService?.GetLocalContent().RefreshToken}</WrappedText></li>
      </ul>
      </div>)    
  }


  
  export const ShowCurrentUser =(user:IFSUsers|undefined)=>{
    return withCard(<div>Current User<br/>
      <ul>
      <li>Id:{user?.id}</li>
      <li>Email:{user?.email}</li>
      <li>SiteRoles:{user?.siteRoles?.join(",")}</li>
      </ul>
      </div>)
  }

  export const ShowEnvironment =(environmentService:IFSEnvironmentService|undefined)=>{    
    return withCard(<div>Environment<br/>
      <ul>
        <li>NodeEnv:{environmentService?.getEnvironment().NodeEnv}</li>
        <li>UIMode:{environmentService?.getEnvironment().UIMode}</li>
        <li>DevelopmentMode(isDev):{environmentService?.getEnvironment().DevelopmentMode}</li>
        <li>FakeAPI:{environmentService?.getEnvironment().FakeApi}</li>
        <li>FakeAUTH:{environmentService?.getEnvironment().FakeAuth}</li>
        <li>BaseUrl:{environmentService?.getEnvironment().BaseUrl}</li>
        <li>AuthUrl:{environmentService?.getEnvironment().AuthUrl}</li>      
      </ul>
      </div>)};
  
  
  export const ShowContext =(context:IFSTFAppContextProps|undefined)=>{
    return withCard(<div>Context<br/>
      <ul>
      <li>useFakeAuth:{context?.useFakeAuth}</li>
      <li>useFakeData:{context?.useFakeData}</li>
      </ul>
      </div>)};
