export const USERROLEPERMISSION = {
    ADDCustomer: "addcustomer",
    EDITCustomer: "editcustomer",
    DELETECustomer: "deletecustomer",
    VIEWCustomer: "viewcustomer",
    NOTESCustomer: "addcustomernotes",
    USER: "user",
    LICENSE: "license",
    MESSAGECustomer: "messagecustomer"
  };

  export const SITEPERMISSION = {
    siteadmin :"siteadmin"
  };