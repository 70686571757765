import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useRef } from "react";

export const LicenceTextInputFieldReadOnly = (fieldRenderProps: FieldRenderProps) => {
    const { id, label, required, validationMessage, ...others } =
      fieldRenderProps;
    
    const editorRef = useRef<any>(null);
    
    return (
      <FieldWrapper>
        <Label editorId={id}
        editorRef={editorRef}>
          {label}
        </Label>
        <div>
          <Input
            ref={editorRef}
            id={id}
            readOnly={true}
            {...others}
          />
        </div>
      </FieldWrapper>
    );
  };