import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FSAppContext } from "../../providers/FSTF.Context";
import { saveAs } from "@progress/kendo-file-saver";
import IFSLicenseAgreement from "../../types/IFSLicenseAgreement";

export const FSTFDownloadLicense = (props: any) => {

  const navigate = useNavigate();
  const { dataProvider, setPageTitle } = useContext(FSAppContext);
  const { licenseid } = useParams();
  const [downloadFinished, setDownloadFinished] = useState<boolean>(false);
  const [providerError, setProviderError] = useState<string|undefined>();
  const [userMessage, setUserMessage] = useState<string>("Checking your License Agreement status...");

  useEffect(() => {
    setPageTitle("License Download");
    if (downloadFinished) return;

    var timer : NodeJS.Timeout|undefined = undefined;

    const checkagreement : IFSLicenseAgreement = {
      licensePackId: licenseid!,
      agreementDateTime: ""
    }
    
    dataProvider?.checkLicenseAgreement(checkagreement).then((data) => {
      if (data == 403 || data == 400) {
        navigate('/');
      }

      //404 == needs to agree to license
      //200 == ok to download
      else if (data == 200) {
        setUserMessage("Downloading License...");

        dataProvider.downloadLicensePack(licenseid!).then((data) => {
          saveAs(data.blob, data.filename);
          setDownloadFinished(true);
          setUserMessage("Thank you for downloading your license...you will be redirected to the Licenses page shortly...");
          setProviderError(undefined);
      
          timer = setTimeout(() => {
            navigate('/licenses')
          }, 3000);
      
        }).catch((error) => {
          setProviderError("Unable to download License - try again later")
        });
      }

      else if (data == 404) {
        setUserMessage("You will be redirected to the License Agreement page shortly...");
        timer = setTimeout(() => {
          navigate('/licenses/eula/' + licenseid);
        }, 1500);
    
      }
      
      else {
        setProviderError("Unable to download License - try again later")
      }
    }).catch((error) => {
      setProviderError("Unable to check for license agreement status - try again later");
    });

    return () => clearTimeout(timer);

  }, [licenseid]);

  return (
    <FSTFDefaultTemplate>

      <h1>4-Secure License Download</h1>

      {providerError !== undefined && (
        <h3>{providerError}</h3>
      )}
      
      {providerError == undefined && (
        <h3>{userMessage}</h3>
      )}
      
    </FSTFDefaultTemplate>
  )
}
