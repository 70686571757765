import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { FormTextInput } from "../atoms/FSTF.TextAreaFormElement";
import { FormUpload } from "../atoms/FSTF.UploadFileFormElement";
import { FormInput } from "../atoms/FSTF.InputFieldFormElement";

const descriptionValidator = (value: string) => !value ? "Description is required" : "";
const versionValidator = (value: string) => !value ? "Version is required (e.g. 1.0.0)" : "";
const fileValidator = (value: any) => !value ? "File is required" : "";

interface FileFormProps {
  handleSubmit:(e: any) => void;
  fileUploadFieldLabel: string;
  fileDescriptionFieldLabel: string;
  fileVersionFieldLabel: string;
}

const FileUploadForm: React.FC<FileFormProps> = (props: FileFormProps) => {
  return (
    <Form 
      onSubmit={props.handleSubmit}
      render={
        (formRenderProps: FormRenderProps) => (
          <FormElement style={{ width: 500 }}>
            <Field
              id={"fileUploadField"}
              name={"fileUploadField"}
              label={props.fileUploadFieldLabel}
              component={FormUpload}
              validator={fileValidator} 
            />
                            
            <Field
              id={"description"}
              name={"description"}
              label={props.fileDescriptionFieldLabel}
              hint={"Enter a brief user facing description for this upload"}
              component={FormTextInput}
              validator={descriptionValidator}
            />

            <Field
              id={"version"}
              name={"version"}
              label={props.fileVersionFieldLabel}
              hint={"Enter the file version (e.g. 1.0.0)"}
              component={FormInput}
              validator={versionValidator}
            />
        
            <div className="k-form-buttons">
              <Button
                themeColor={"primary"}
                type={"submit"}
                disabled={!formRenderProps.allowSubmit}>
                  Upload
                </Button>
              </div>
            </FormElement>
        )
      }   
    />
  )
};

export default FileUploadForm;
