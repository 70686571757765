import { GridLayout } from "@progress/kendo-react-layout";
import "reflect-metadata";
import FSTFNavigation from "../organisms/FSTF.Navigation";
import { FSAppContext } from "../../providers/FSTF.Context";
import { useContext } from "react";

interface FSTFDefaultTemplateProps {
  children: React.ReactNode;
}

export const FSTFDefaultTemplate: React.FC<FSTFDefaultTemplateProps> = ({
  children,
}) => {

  const { showNavigation } = useContext(FSAppContext);

  return (
    <>
      {showNavigation ? (
        <GridLayout
          cols={[{ width: "15%" }, { width: "85%" }]}
          className="app-grid"
        >
          <FSTFNavigation />
          <div className="app-main layout-container k-m-4">{children}</div>
        </GridLayout>
      ) : (
        <div className="app-main">{children}</div>
      )}
    </>
  );
};
