import { useContext, useEffect, useState } from "react";
import IFSProduct from "../../types/IFSProduct";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FSAppContext } from "../../providers/FSTF.Context";
import { saveAs } from "@progress/kendo-file-saver";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Card, CardHeader, CardTitle, CardActions, PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import IFSAppDownload from "../../types/IFSAppDownload";
import FileUploadForm from "../molecules/FSTF.FileUploadForm";
import { NotificationPopup } from "../atoms/FSTF.NotificationPopup";
import { Loader } from "@progress/kendo-react-indicators";
import DownloadPanelBar, { Downloadable } from "../atoms/FSTF.DownloadPanelBar";

type UploadOutcome = {
  successful: boolean;
  message: string;
}

export const FSTFProductDownload = (props: any) => {
  const {id} = useParams();
  const {user,dataProvider, setPageTitle, isSiteAdmin} = useContext(FSAppContext);
  const [downloads, setDownloads] = useState<Downloadable[]>([]);
  const [uploadVisible, setUploadVisible] = useState<boolean>(false);
  const [dataProviderErr, setDataProviderError] = useState<String>();
  const [uploadOutcome, setUploadOutcome] = useState<UploadOutcome | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [search, setSearch] = useSearchParams();
  const [doAutoDownload, setDoAutoDownload] = useState<boolean>(true);
  const provider = dataProvider;
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const product = useLocation().state as IFSProduct;
  
  if (product === null || product === undefined
    || product.id !== id) {
    navigate("/products");
  }

  useEffect(() => {
    if (product !== null && product !== undefined) {
      setPageTitle(`${product.name} - Downloads`);
    }
    
    provider
      ?.getProductDownloads(String(id))
      .then((data) => {
        data.sort((a: IFSAppDownload, b: IFSAppDownload) => {
          return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
        });

        setDownloads(data.map((d) => ({ download: d, isDownloading: false })));
        setDataProviderError(undefined);
        setIsLoading(false);
      }).catch((reason) => {
        setDataProviderError("Unable to retrieve product downloads - try again later");
        setDownloads([]);
      });
  }, [id]);

  useEffect(() => {
    if (doAutoDownload && search.get("autodownload")) {
      const requested = downloads.find((doc: Downloadable) => doc.download.id === search.get("autodownload"));
      if (requested !== undefined) {
        handleDownload(requested);
        setDoAutoDownload(false);
      }
    }
  }, [downloads]);

  const canUpload =
    user?.siteRoles?.find((role) => role?.toLowerCase() === "siteadmin") !==
    undefined;

  const toggleUploadVisibility = () => {
    setUploadVisible(!uploadVisible);
  };

  const handleSubmit = (e: any) => {
    if (e.fileUploadField && e.fileUploadField.length > 0) {

      setIsUploading(true);
      toggleUploadVisibility();

      const theFile = e.fileUploadField[0].getRawFile() as File;

      const newUpload = {
        productId: product!.id,
        fileSource: theFile,
        description: e.description,
        filename: theFile.name,
        version: e.version,
      } as IFSAppDownload;

      provider
        ?.saveProductUpload(newUpload)
        .then((data) => {
          setIsUploading(false);
          setDownloads([{download: data, isDownloading: false}, ...downloads]);
          setUploadOutcome({
            successful: true, 
            message: `${newUpload.filename} uploaded successfully`
          });
        })
        .catch((error) => {
          setIsUploading(false);
          setUploadOutcome({
            message: `An error occurred while uploading ${newUpload.filename} - try again later`,
            successful: false
          });
        });
    }
  };

  const handleDownload = (requested: Downloadable) => {
    if (requested.isDownloading) {
      return;
    }

    setDownloads(downloads.map((d) => d.download.id === requested.download.id ? {download: d.download, isDownloading: true} : d));

    provider
      ?.downloadProductBinary(requested.download)
      .then((blobAndHeaders) => {
        const [blob, headers] = blobAndHeaders;

        const header = headers.get("Content-Disposition") || "";
        const filenameStarRegex = /filename\*=[^'']*''(.+)/;
        const matchFilenameStar = header.match(filenameStarRegex);
        let filename: string = "";
        if (matchFilenameStar && matchFilenameStar[1]) {
          filename = decodeURIComponent(matchFilenameStar[1]);
        }

        saveAs(blob, filename!);

      })
      .catch((error) => {
        window.alert(
          "An error occurred while downloading the application - try again later"
        );
      }).finally(() => {
        setDownloads(downloads.map((d) => d.download.id === requested.download.id ? {download: d.download, isDownloading: false} : d));
      });
  };

  const toggleActiveState = (download: Downloadable) => {
    provider?.toggleDownloadState(download.download)
      .then((result: IFSAppDownload) => {
        //exchange the item to preserve item order
        const exchanged = downloads.map((d) => d.download.id === result.id ? {download: result, isDownloading: false} : d);
        setDownloads(exchanged);
      })
      .catch((error) => {
        window.alert(
          "An error occurred while updating download state - try again later"
        );
      });
  };

  return (
    <FSTFDefaultTemplate>
      {dataProviderErr !== undefined ? (
        <div>{dataProviderErr}</div>
      ) : (
        <div className="k-card-list">
          <Card>
            <CardHeader>
              <CardTitle>Application Downloads for {product?.name}</CardTitle>
            </CardHeader>

            {canUpload && (
              <CardActions orientation="horizontal">
                <Button onClick={toggleUploadVisibility}>
                  Add New Version
                </Button>
              </CardActions>
            )}
          </Card>

          {canUpload && uploadVisible && (
            <Dialog title={"Add New Version"} onClose={toggleUploadVisibility}>
              <p>
                Select a file and add a brief description for users
              </p>
              <FileUploadForm
                handleSubmit={(e) => {
                  handleSubmit(e);
                }}
                fileDescriptionFieldLabel="Description"
                fileUploadFieldLabel="Application Binary"
                fileVersionFieldLabel="File Version"
              />

              <DialogActionsBar>
                <Button
                  rounded={"medium"}
                  fillMode={"solid"}
                  onClick={toggleUploadVisibility}
                >
                  Cancel
                </Button>
              </DialogActionsBar>
            </Dialog>
          )}

          { downloads.length > 0 && (
              <DownloadPanelBar
                activeClassName="liveDownloadItem"
                archivedClassName="archivedDownloadItem"
                downloadables={downloads}
                handleToggleActive={toggleActiveState}
                handleDownload={handleDownload}
                isSiteAdmin={isSiteAdmin}
              />
          )}

          { downloads.length === 0 && isLoading && (
             <div>Loading...please wait</div>  
          )}

          { downloads.length === 0 && !isLoading && (
             <div>No downloads found for product</div>  
          )}

          { isUploading && (
            <Dialog title={"Uploading File"} closeIcon={false}>
              <p>
                Please wait while the file is uploaded
              </p>
            </Dialog>
          )}

          {uploadOutcome !== undefined && (
            <NotificationPopup
              message={uploadOutcome.message}
              successful={uploadOutcome.successful}
              onClose={() => setUploadOutcome(undefined)}
            />
          )}
        </div>          
      )}
    </FSTFDefaultTemplate>
  );
};
