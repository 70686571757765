import { Card, CardBody, CardHeader, StackLayout } from "@progress/kendo-react-layout";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";

import { FSAppContext } from "../../providers/FSTF.Context";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const FSTFDashboard = (props: any) => {
    
  const { isLoggedIn, setPageTitle } = useContext(FSAppContext);
  const navigate = useNavigate();
  
  useEffect(()=>{
   
    if (isLoggedIn) {
      navigate("/dashboard-loggedin");
    }
    
    else {
      setPageTitle("SECUREpane");
    }
  })

  return (
    <FSTFDefaultTemplate>
      <StackLayout 
      className="fs-dash-container"
        orientation="vertical"
        gap={20}
        align={{ horizontal: `start` }}
      >   
        
        <div className="fs-hero">
          <img alt="TrustedFilter shield logo" src="../TrustedFilter-shield-whitet-redf.svg"></img>
          <p>CONNECTING THE DISCONNECTED<br/>
          CROSS-DOMAIN SECURITY</p>
        </div>
        
        {/* <div className="fs-dash-row">
          <div className="fs-dash-section fs-dash-section-1">
            <div>
                <h1>Bridging the air gap between disconnected networks</h1>
                <p>4Secure provides cutting-edge security solutions to guarantee the uninterrupted operation of the world's most critical 
                  industries and infrastructures.
                  This empowers organisations to fully harness data intelligence and increase their organisational efficiency.
                  </p>
              </div>
              <img alt="Cloud Computing logo" src="cloud-computing-illustration.png"></img>
          </div>
        </div> */}
        <Card className="fs-dash-row">
        <CardHeader>
        <h1>Bridging the air gap between disconnected networks</h1>
        </CardHeader>
        <CardBody>
          <StackLayout orientation="vertical" gap={20} >
            <div className="fs-dash-section">

                <p>4Secure provides cutting-edge security solutions to guarantee the uninterrupted operation of the world's most critical 
                  industries and infrastructures.
                  This empowers organisations to fully harness data intelligence and increase their organisational efficiency.
                  </p>

                 <img alt="Cloud Computing logo" src="cloud-computing-illustration.png"></img>
              </div>
            </StackLayout>
        </CardBody>
        </Card>   
        {/* <div className="fs-dash-row fs-dash-row-alt">
          <div className="fs-dash-section fs-dash-section-2">
              <img alt="Cloud Computing logo" src="tf-mini-diagram-ver1Artboard-12@2x.png"></img>
              <div>
                <h1>4Secure - 20 Years Connecting the disconnected</h1>
                <p>
                  4Secure's OT, IT and Cross-Domain Solutions combine architectural techniques and supporting technologies to create secure and 
                  fast connections between environments operating at different security classifications.
                  4Secure's offerings ensure impenetrable security, guaranteeing the secure integration
                  of IT/OT networks and offering complete protection against cyber threats.
                </p>
              </div>
          </div>
        </div> */}
        <Card className="fs-dash-row">
        <CardHeader>
        <h1>4Secure - 20 Years Connecting the disconnected</h1>
        </CardHeader>
        <CardBody>
          <StackLayout orientation="vertical" gap={20} >
            <div className="fs-dash-section">
            <img alt="Cloud Computing logo" src="tf-mini-diagram-ver1Artboard-12@2x.png"></img>
              <p>
                  4Secure's OT, IT and Cross-Domain Solutions combine architectural techniques and supporting technologies to create secure and 
                  fast connections between environments operating at different security classifications.
                  4Secure's offerings ensure impenetrable security, guaranteeing the secure integration
                  of IT/OT networks and offering complete protection against cyber threats.
              </p>
             
              </div>
            </StackLayout>
        </CardBody>
        </Card>  
        
      </StackLayout>
    </FSTFDefaultTemplate>
  );
};
