import { useContext, useEffect, useState } from "react";
import IFSProduct from "../../types/IFSProduct";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { Card, CardActions, CardBody, CardHeader, CardImage, CardTitle } from "@progress/kendo-react-layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { SVGIcon, cancelIcon, xIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FSAppContext } from "../../providers/FSTF.Context";
import IFSUsers from "../../types/IFSUsers";
import {loremIpsum} from "lorem-ipsum";

//List of Actions that can be performed on product
enum PRODUCT_ACTION {
  DELETE = "delete"
}

//type definition for mapping Action to Role(s)
type RoleBasedAction = {
  [key in PRODUCT_ACTION]: string[];
};

//this implements the mapping for Action and Role(s)
const ROLE_ACTION_MAP: RoleBasedAction = {
  [PRODUCT_ACTION.DELETE]: ["siteAdmin"]
};

//typedef for drop down options to be passed to drop down renderer
type DropDownAction = {
  actionText: string;
  actionIcon: SVGIcon;
  mappedAction: PRODUCT_ACTION;
}

//array of all dropdown options which could be shown. This is later filtered based on the user's role
const ALL_DROPDOWN_OPTIONS: DropDownAction[] = [
  { actionText: "Delete", actionIcon: xIcon, mappedAction: PRODUCT_ACTION.DELETE }
];

export const FSTFProduct = (props: any) => {
 
  const {id} = useParams();
  const {user,dataProvider, setPageTitle} = useContext(FSAppContext);

  const navigate = useNavigate();

  const [dropDownVisible, setDropDownVisible] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<String>();
  const [deleteVisible, setDeleteProductVisible] = useState<boolean>(false); 
  const [userActions, setUserActions] = useState<DropDownAction[]>([]); 

  const renderActionItems = (props: { item: DropDownAction}) => {
    return <div>{<SvgIcon icon={props.item.actionIcon} />} {props.item.actionText}</div>
  };

  const provider=dataProvider;
  
  const product = useLocation().state as IFSProduct;
  
  if (product === null || product === undefined
    || product.id !== id) {
    navigate("/products");
  }

  useEffect(() => {
    setPageTitle(product?.name);
  }, []);

  useEffect(() => {
    const checkUserPermission = (user: IFSUsers, permission: string): boolean => {
      if (user === undefined || user === null || user.siteRoles === undefined || user.siteRoles === null) {
        return false;
      }
  
      permission = permission.toLowerCase();
      
      return user.siteRoles.find((role: string) => role?.toLowerCase() === permission) !== undefined;
    }

    const checkActionAccess = (action: PRODUCT_ACTION): boolean => {
      return ROLE_ACTION_MAP[action].some((role: string) => checkUserPermission(user, role));
    }
  
    const filtered_actions = ALL_DROPDOWN_OPTIONS.map((action: DropDownAction) => {
      return checkActionAccess(action.mappedAction) ? action : undefined;
    }).filter(Boolean) as DropDownAction[];

    setUserActions(filtered_actions);
    setDropDownVisible(filtered_actions.length > 0);

  }, [user]);

  const navigateToDocs = () => {
    navigate(`/product/${product?.id}/documentation`, { state: {  ...product  }});
  }

  const navigateToDownloads = () => {
    navigate(`/product/${product.id}/download`, { state: {  ...product  }});
  }

  const toggleDeleteProductVisibility = () => {
    setDeleteError(undefined);
    setDeleteProductVisible(!deleteVisible);
  }

  const onClickProductAction = (e: any) => {
    switch (e.item.mappedAction) {
      case PRODUCT_ACTION.DELETE:
        toggleDeleteProductVisibility();
        break;
    }
  };

  const performProductDeletion = (productId: string | undefined) => {
    if (productId === undefined) return;

    provider?.deleteProduct(productId)
    .then(() => {
      toggleDeleteProductVisibility();
      navigate("/products");
    }).catch((error) => {
      setDeleteError("The request to delete this product failed");
    });
  }

  function GenerateDescription(name: string | undefined): import("react").ReactNode {
    let desc= "This is a description for " + name + " product.";  
  desc+=loremIpsum({count:15, units:"sentences"});
  return desc;
}

  return (
    <FSTFDefaultTemplate>
      
      <Card className="fs-product-card">
          <CardHeader>
            <CardTitle>{product?.name} 
              {dropDownVisible && (
                <DropDownButton
                  itemRender={renderActionItems}
                  items={userActions}
                  text="..."
                  fillMode={"flat"}
                  onItemClick={(e) => onClickProductAction(e)}
                /> 
              )}
            </CardTitle>          
          </CardHeader>
          <CardImage src={product?.image} className="fs-product-card-image" />  
          <CardBody className="fs-product-card-body">
            {product?.description == undefined?GenerateDescription(product?.name):product?.description}</CardBody>
          <CardActions>
              <Button
              themeColor={"tertiary"}
                onClick={() => {navigateToDownloads()}}>
                  Application Downloads..
              </Button>
              <Button      
              themeColor={"tertiary"}          
                onClick={() => {navigateToDocs()}}>
                  Documentation Downloads..
                </Button>

            </CardActions>      
        </Card>
      

      <> {
          deleteVisible && (
            <div> 
                <Dialog className="warning" closeIcon={true} title={`Confirm deletion`} onClose={toggleDeleteProductVisibility}>
                  <p style={{textAlign: "center"}}>Permanently delete this product</p>
                  <p style={{textAlign: "center", fontSize: "150%"}}>{product.name}</p>

                  <DialogActionsBar>
                    <Button svgIcon={cancelIcon} onClick={toggleDeleteProductVisibility}>Cancel</Button>
                    <Button themeColor={"warning"} svgIcon={xIcon} onClick={() => performProductDeletion(product.id)}>Delete</Button>
                  </DialogActionsBar>

                  { deleteError !== undefined && (
                      <div className="error">{deleteError}</div>
                    )
                  }                    
          
                </Dialog>
            </div>
          )
        }</>
    </FSTFDefaultTemplate>
  );
};
