import { useContext } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
import { IFSLicensePack } from "../../types/IFSLicensePack";
import { FSTFLicenseItem } from "./FSTF.LicenseItem";
import {  StackLayout, } from "@progress/kendo-react-layout";

export const FSTFLicensePack = (props: IFSLicensePack) => {

  const {isSiteAdmin} = useContext(FSAppContext);
  
  return (
    <StackLayout orientation="vertical" className="fs-licensepack-view">
      <StackLayout orientation="horizontal" className="fs-licensepack-top">
        <div className="fs-line"><span className="fs-label">Reference:</span> {props.siteReference}</div>
        <div className="fs-line-alt"><span className="fs-label">Created:</span> {new Date(props.creationDate).toDateString()}</div>
        </StackLayout>
        <h2>Licenses</h2>
              {props.licenses === undefined ? (
                <div>No Licenses/loading</div>
              ) : (
                props.licenses.map((license) => {
                  return <FSTFLicenseItem key={license.id} {...license} />;
                })
              )}

          <StackLayout orientation="horizontal" className="fs-license-metadata">
          {isSiteAdmin && (
              <div className="fs-metadata">
                <p>Sales Asset Reference: {props.salesAssetReference}</p>
                <p>Sales Customer Reference: {props.salesCustomerReference}</p>
                <p>Origin License Sets: {props.salesNotes}</p>
                </div>
              )}
                <div className="fs-expire"><span className="fs-title">Expires:</span> {new Date(props.expireDate).toDateString()}</div>
          </StackLayout>
      </StackLayout>
  );
};
