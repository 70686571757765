/* Update this object to enable testing */

export default interface IFSTestDetails{
    IsTest?:boolean;
    Flags?:[string,boolean][];
    Values?:[string,string][];
}

export const defaultTestDetails:IFSTestDetails = {
    IsTest:false,
    Flags:[],
    Values:[]
}