import { FSTFDataProvider } from "../../providers/FSTF.DataProvider";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { Label } from "@progress/kendo-react-labels";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldWrapper,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import IFSAdministrationConfiguration from "../../types/IFSAdministrationConfiguration";
import { useContext, useEffect, useState } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
import { Card, CardBody, CardFooter, CardHeader } from "@progress/kendo-react-layout";
import { ShowEnvironment } from "../molecules/FSTF.Debug.Components";

const FormInput = (fieldRenderProps: any) => {
  const { id, value, label, disabled, type, onChange, required } =
    fieldRenderProps;
  return (
    <FieldWrapper>
      <Label editorId={id}>{label}</Label>
      <div>
        <Input
          id={id}
          value={value}
          disabled={disabled}
          type={type}
          onChange={onChange}
          required={required}
          data-testid={id}
        ></Input>
      </div>
    </FieldWrapper>
  );
};

const FormSwitch = (fieldRenderProps: any) => {
  const { id, value, label, disabled, onChange, required } = fieldRenderProps;
  return (
    <FieldWrapper>
      <Label editorId={id}>{label}</Label>
      <div data-testid={id}>
        <Switch
          id={id}
          value={value}
          disabled={disabled}
          onChange={onChange}
          required={required}          
        ></Switch>
      </div>
    </FieldWrapper>
  );
};

export const FSTFAdministration = (props: any) => {
  const [adminConfig, setAdminConfig] =
    useState<IFSAdministrationConfiguration>();
    const { user,dataProvider,useFakeData, setPageTitle } = useContext(FSAppContext);
    
  useEffect(() => {
    setPageTitle("Administration");

    if (!adminConfig) {
      dataProvider?.getAdministrationConfiguration().then((data) => {
        setAdminConfig(data);
      });
    }
  }, [adminConfig]);
  
  const handleSubmit = (e: any) => {
    setAdminConfig(e);
  };

  const stats=()=>{
    return(<>
    <div>I HAVE SOME GREAT STATS HERE</div>
    </>)
  }
  const ShowEnvironment=()=>{

  }
  return (
    <FSTFDefaultTemplate>
      <div>Administration</div>
      {adminConfig === undefined ? (
        <div>Loading...</div>
      ) : (
        <Form
          initialValues={adminConfig}
          onSubmit={handleSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <>
            <FormElement>
              <fieldset className="k-form-fieldset">
                <Field
                  name={"email"}
                  id={"configEmail"}
                  label={"Configuration Email"}
                  component={FormInput}
                />
                <Field
                  name={"maintenanceMode"}
                  id={"configMaintenanceMode"}
                  label={"MaintenanceMode"}
                  component={FormSwitch}
                />
              </fieldset>
              <div className="k-form-buttons">
                <Button className="k-button" type={"submit"}>
                  Submit
                </Button>
              </div>
            </FormElement>
            <Card>
              <CardHeader>
                <h1>Administrative Actions</h1>
                </CardHeader>
              <CardBody>
                <Button onClick={()=>ShowEnvironment()}>Show Environment</Button>
              </CardBody>
              <CardFooter><>{stats()}</></CardFooter>
            </Card>
            </>
          )}
        />
      )}
    </FSTFDefaultTemplate>
  );
};
