
import { createContext, useState } from "react";
import { FSLoggingService, IFSLoggingService } from "./FSTF.LoggingProvider";
import { FSLocalContentService, IFSLocalContentService } from "./FSTF.LocalContentProvider";

export interface IFSEnvironment{
    BaseUrl:string;
    AuthUrl:string;
    UIMode:string;
    FakeAuth:boolean;
    FakeApi:boolean;
    NodeEnv:string;
    DevelopmentMode:boolean;
    ApiVersion:string;
    AppInsightsKey:string;
    AppInsightsSiteId:string;
    AppInsightsDisabled:boolean;
    AppVersion:string;
    AppBuildId:string;
}
export interface IFSEnvironmentService{
    getEnvironment():IFSEnvironment
}


export class FSEnvironmentService implements IFSEnvironmentService{
    /**
     * 
     * @returns  IFSEnvironment
     * @description Get the environment variables from the .env file
     * 
     * @remarks See the [Environment Variables](https://create-react-app.dev/docs/adding-custom-environment-variables/) documentation for more information.
     */
    getEnvironment(): IFSEnvironment{

        const defaultBaseUrl = "https://localhost:3001";
        return {
            UIMode:process.env.REACT_APP_UIMODE || "online",
            AuthUrl:process.env.REACT_APP_AUTHURL || defaultBaseUrl,
            BaseUrl:process.env.REACT_APP_APIURL || defaultBaseUrl,
            FakeApi:process.env.REACT_APP_FAKEAPI?.toLocaleLowerCase() === "true"||false,
            FakeAuth:process.env.REACT_APP_FAKEAUTH?.toLocaleLowerCase() === "true"||false,
            NodeEnv:process.env.NODE_ENV || "development",
            DevelopmentMode:process.env.REACT_APP_DEVMODE?.toLocaleLowerCase()==="true"||false,
            ApiVersion:process.env.REACT_APP_API?.toLocaleLowerCase() || "0.1",
            AppInsightsKey:process.env.REACT_APP_APPINSIGHTS_KEY || "",
            AppInsightsSiteId:process.env.REACT_APP_APPINSIGHTS_SITEID || "",
            AppInsightsDisabled:process.env.REACT_APP_APPINSIGHTS_DISABLE?.toLocaleLowerCase()==="true"||true,
            AppVersion:process.env.REACT_APP_VERSION?.toLocaleLowerCase() || "unknown version",
            AppBuildId:process.env.REACT_APP_BUILDID?.toLocaleLowerCase() || "unknown build",
             }
    }
}


export const FSEnvironmentProvider = (props: any) => {
    const [environmentService] = useState<FSEnvironmentService>(() => new FSEnvironmentService());
    const [loggingService] = useState<FSLoggingService>(new FSLoggingService());
    const [localContentService] = useState<FSLocalContentService>(() => new FSLocalContentService());

    const ctx={
        environmentService,
        loggingService,
        localContentService    }
    console.log("FSEnvironmentProvider",ctx)

    return (
        <FSEnvironmentContext.Provider value={ctx}>{props.children}</FSEnvironmentContext.Provider>
    );
}

var defaultEnvironmentContext={
    environmentService: undefined,
    loggingService:undefined,
    localContentService:undefined
}
export const FSEnvironmentContext =  createContext<IFSEnvironmentProps>(defaultEnvironmentContext);

export const FSEnvironmentConsumer = (props: any) => {
    return <FSEnvironmentContext.Consumer>{props.children}</FSEnvironmentContext.Consumer>;
};

export interface IFSEnvironmentProps {
    environmentService?:IFSEnvironmentService;
    setEnvironmentServiceValue?:(ep:IFSEnvironmentService)=>void;
    loggingService?:IFSLoggingService;
    setLoggingServiceValue?:(ep:IFSLoggingService)=>void;
    localContentService?:IFSLocalContentService;
    setLocalContentServiceValue?:(ep:IFSLocalContentService)=>void;

}

