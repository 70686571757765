import {
  DropDownButton,
  DropDownButtonItem,
} from "@progress/kendo-react-buttons";

interface AddMultipleSectionsButtonProps {
  sections?: Array<any>;
  onclick?: (section: any) => void;
}

const AddMultipleSectionsButton: React.FC<AddMultipleSectionsButtonProps> = ({
  sections,
  onclick,
}) => {
  const handleDomEvent = (e: any) => {
    const section = sections![e.itemIndex];
    if (onclick) {
      onclick(section.template);
    }
  };

  return (
    <div>
      {sections && (
        <DropDownButton
          text="Add New Section"
          icon="plus"
          onItemClick={(e) => handleDomEvent(e)}
        >
          {sections.map((section) => (
            <DropDownButtonItem
              key={section.name}
              text={section.name}
              icon="plus-circle"
            />
          ))}
        </DropDownButton>
      )}
    </div>
  );
};

export default AddMultipleSectionsButton;
