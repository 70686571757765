import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";

import { Input } from "@progress/kendo-react-inputs";
import { StackLayout } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { FSAppContext} from "../../providers/FSTF.Context";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@progress/kendo-react-dialogs";


export const FSTFResetPasswordReq = () => {
  const { dataProvider, setPageTitle} = useContext(FSAppContext);  
  const [message, setMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  
  useEffect(() => {
    setPageTitle("Password Reset Request");
  }, []);
  
  const navigate = useNavigate();
  const handleSubmit = async (e: any) => {
    dataProvider?.resetPasswordRequest(e.email).then((data) => {
      setMessage(`Password reset link has been sent to ${e.email}. Please check your email.`);
      setShowDialog(true);
    }).catch((error) => {
      console.error("Unable to send reset password link" + error.message);
      setMessage(`Unable to send reset password link. Please try again later.`);
      setShowDialog(true);
    });
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate('/login');
  };
  
  return (
    <FSTFDefaultTemplate>
      
      {showDialog && (
        <Dialog title={"Password Reset"} onClose={handleCloseDialog}>
          <p>{message}</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button  onClick={handleCloseDialog}>OK</Button>
          </div>
        </Dialog>
      )}
      <StackLayout orientation="horizontal" className="app-login">
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps: FormRenderProps) => {
            return(
            <FormElement>
              <fieldset className="app-login-form">
                <legend>Please enter registered email address:</legend>
                <Field name={"email"} component={Input} label={"Email"} type="email" required={true} />
              </fieldset>
              <Button type={"submit"}>Submit</Button>
            </FormElement>
            );
          }}
        />
      </StackLayout>
    </FSTFDefaultTemplate>
  );
};