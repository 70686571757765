import _ from "lodash";
import IFSUsers from "../types/IFSUsers";

export interface IFSLocalContent{
    UserDetails:IFSUsers|undefined;
    RefreshToken:string;
    AccessToken:string;
    CurrentPage:string;
}

export interface IFSLocalContentService{
    GetLocalContent():IFSLocalContent;
    SetUser(user:IFSUsers):void;
    SetRefreshToken(token:string):void;
    SetAccessToken(token:string):void;
    ClearLocalContent():void;
    SetCurrentPage(token:string):void;
}

export class FSLocalContentService{
    private _localContent:IFSLocalContent= {
        UserDetails: undefined,
        RefreshToken: "",
        AccessToken: "",
        CurrentPage:""
    }
    private _loggedInUserKey="loggedInUser";
    private _refreshTokenKey="refreshToken";
    private _accessTokenKey="accessToken";
    private _currentPageKey="currentPage";

    constructor(){
        this.RefreshValues();
    }
    RefreshValues(){
        let userRaw = localStorage.getItem(this._loggedInUserKey);
        let refreshToken = localStorage.getItem(this._refreshTokenKey);
        let accessToken = localStorage.getItem(this._accessTokenKey);
        let currentPage = localStorage.getItem(this._currentPageKey);

        let userDetails=_.isNil(userRaw) ? undefined : JSON.parse(userRaw);
        refreshToken = _.isNil(refreshToken) ? "" : refreshToken;
        accessToken = _.isNil(accessToken) ? "" : accessToken;
        currentPage = _.isNil(currentPage) ? "" : currentPage;
        this._localContent = {
            UserDetails: userDetails,
            RefreshToken: refreshToken,
            AccessToken: accessToken,
            CurrentPage: currentPage
        }
    }
    ClearLocalContent(){
        this._localContent = {
            UserDetails: undefined,
            RefreshToken: "",
            AccessToken: "",
            CurrentPage:""
        }
        localStorage.removeItem(this._accessTokenKey);
        localStorage.removeItem(this._loggedInUserKey);
        localStorage.removeItem(this._refreshTokenKey);
        localStorage.removeItem(this._currentPageKey);
    }
    GetLocalContent() : IFSLocalContent{
        this.RefreshValues();
        return this._localContent as IFSLocalContent;
    }
    SetUser(user:IFSUsers){
        localStorage.setItem(this._loggedInUserKey, JSON.stringify(user));
        this.RefreshValues();
    }
    SetRefreshToken(token:string){
        localStorage.setItem(this._refreshTokenKey, token);
        this.RefreshValues();
    }
    SetAccessToken(token:string){
        localStorage.setItem(this._accessTokenKey, token);
        this.RefreshValues();
    }
    SetCurrentPage(token:string){
        localStorage.setItem(this._currentPageKey, token);
        this.RefreshValues();
    }
    
}