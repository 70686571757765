import { Grid } from "@progress/kendo-react-grid";
import tickets from "../../data/support.json";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { FSAppContext } from "../../providers/FSTF.Context";
import { useContext, useEffect } from "react";

export const FSTFSupport = (props: any) => {
  const {setPageTitle} = useContext(FSAppContext);
  
  useEffect(() => { 
    setPageTitle("Support");
  }, []);
  
  return (
    <FSTFDefaultTemplate>
      <Grid
        style={{
          height: "400px",
        }}
        data={tickets}
      ></Grid>
    </FSTFDefaultTemplate>
  );
};
