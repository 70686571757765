import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";

import { Input } from "@progress/kendo-react-inputs";
import { Card, CardBody, CardHeader, CardTitle, StackLayout } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { FSAppContext, defaultAppContext } from "../../providers/FSTF.Context";
import { useNavigate } from "react-router-dom";
import { FSTFAuthProvider } from "../../providers/FSTF.AuthProvider";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import IFSCustomerUsers from "../../types/IFSCustomerUsers";
import { SITEPERMISSION } from "../../types/FSPermissionsDictionary";

export const FSTFLogin = () => {
  const { setIsDevValue,setIsSiteAdminValue,setIsValidCustomerAccessValue,setIsLoggedInValue, setPageTitle} = useContext(FSAppContext);
  const { dataProvider} = useContext(FSAppContext);
  const [loginFailed, setLoginFailed] = useState(false);

  const { setTokenValue ,setUserValue,user,useFakeAuth} = useContext(FSAppContext);

  const navigate = useNavigate();

  const auth=new FSTFAuthProvider(useFakeAuth);

  useEffect(() => { 
    setPageTitle("Login");
  }, []);
  
  useEffect(() => {
    if (setUserValue && setIsSiteAdminValue && setIsDevValue && setIsValidCustomerAccessValue && setIsLoggedInValue) {
      //clear user on entry
      setUserValue(defaultAppContext.user);
      setIsSiteAdminValue(false);
      setIsValidCustomerAccessValue(false);
      setIsDevValue(false);      
      setIsLoggedInValue(false);
    }
  },[setUserValue , setIsSiteAdminValue , setIsDevValue , setIsValidCustomerAccessValue, setIsLoggedInValue]);
  
  const handleResetPasswordRequest = () => {
    navigate("/resetpasswordreq");
  };

  const handleSubmit = async (e: any) => {
    auth.login(e.username, e.password).then((ud) => {
      //Set up the user state and set the appropriate flags for admin and dev
      if (
        ud !== undefined &&
        setTokenValue !== undefined &&
        setUserValue !== undefined &&
        setIsLoggedInValue !== undefined &&
        setIsSiteAdminValue !== undefined
      ) {
        setTokenValue(ud.token + "");
        setUserValue(ud);
        setIsLoggedInValue(true);
       
        if (setIsSiteAdminValue && setIsDevValue && setIsValidCustomerAccessValue ) {
          const isAdmin=ud.siteRoles?.find((r) => r?.toLocaleLowerCase() === SITEPERMISSION.siteadmin);
          if (isAdmin) {
            setIsSiteAdminValue(true);
          } 
          else {
            if (ud.id !== undefined) {
              dataProvider?.getUserCustomers(ud.id).then((data) => {
                const filtered = data.filter((customer) => customer.active !== false);
                const userRole: IFSCustomerUsers[] = [...filtered];
                
                const desiredRoles = ["companyadmin", "companymanager", "CompanyLogin","companyviewer"];
  
                const hasDesiredRole = userRole.some(item =>
                      item.roles?.some(role =>
                     desiredRoles.includes(role.toLowerCase())));
  
                setIsValidCustomerAccessValue(hasDesiredRole);
              }).catch((error) => {
                console.error("Unable to retrieve customers for logged in user" + error.message);
              });
            } else {
              // Handle the case where ud.id is undefined or not available
              setIsValidCustomerAccessValue(false);
            }
          }
          if (ud.siteRoles?.find((r) => r?.toLowerCase() === "developer")) {
            setIsDevValue(true);
          } else {
            setIsDevValue(false);
          }
        }
        setLoginFailed(false);
        navigate("/dashboard");
      }
      else {
        setLoginFailed(true);
      }
    }).catch((error) => {
      setLoginFailed(true);
    });
  };
  
  return (
    <FSTFDefaultTemplate>
    
      <StackLayout orientation="horizontal" className="app-login">
        <Card>
          <CardHeader>
            <CardTitle>
              Login
            </CardTitle>
          </CardHeader>
          <CardBody>
          <Form
          onSubmit={handleSubmit}
          render={(formRenderProps: FormRenderProps) => {
            return (
              <FormElement>
                <fieldset className="app-login-form">
                  { loginFailed && (
                    <p className={"error"}>Login failed. Please try again.</p>
                  )}
                  <legend>Please log in below:</legend>
                  <Field name={"username"} component={Input} label={"Username"} />
                  <Field name={"password"} component={Input} label={"Password"} type="password" />
                </fieldset>
                <br/>
                <StackLayout orientation="horizontal" gap={100}>

                <Button themeColor={"tertiary"} type={"submit"}>Submit</Button>
                <Button themeColor={"primary"} onClick={handleResetPasswordRequest}>Reset Password</Button>
                </StackLayout>
              </FormElement>
            );
          }}
        />
          </CardBody>
        </Card>
        
      </StackLayout>
    </FSTFDefaultTemplate>
  );
};