import { useRef } from "react";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import {
  Label,
  Error,
  Hint
} from "@progress/kendo-react-labels";


export const FormInput = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others
  } = fieldRenderProps;
  const editorRef = useRef<any>(null);

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        className="k-form-label"
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <Input
          ref={editorRef}
          valid={valid}
          id={id}
          {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};
