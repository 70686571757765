export class StringUtils {
  private static capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public static toHumanReadable(str: string): string {
    if (str === undefined) return str;
    const exceptions = ["IP"];
    const exceptionPattern = exceptions.join("|");
    const words =
      str.match(
        new RegExp(
          `(${exceptionPattern})|[A-Za-z1-9][a-z-1-9\:\-\_\s\{\}]*`,
          "g"
        )
      ) || [];
    return words.map(StringUtils.capitalize).join(" ");
  }
}
