import { useNavigate, useParams } from "react-router-dom";
import { IFSLicensePack } from "../../types/IFSLicensePack";
import { useEffect, useState, useContext } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { Button } from "@progress/kendo-react-buttons";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import IFSLicense from "../../types/IFSLicense";
import { FSAppContext } from "../../providers/FSTF.Context";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { LicenceTextInputField } from "../atoms/FSTF.LicenseTextInputField";
import { LicenceTextInputFieldReadOnly } from "../atoms/FSTF.LicenseTextInputField.ReadOnly";
import { LicenceDateInputFieldReadOnly } from "../atoms/FSTF.LicenseDateInputField.ReadOnly";

export const FSTFEditLicenses = () => {
  const { id } = useParams();
  const [licensePack, setLicensePack] = useState<IFSLicensePack>();
  const { dataProvider, setPageTitle } = useContext(FSAppContext);
  const [providerError, setProviderError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  
  const formTextValidator = (value: string) => !value ? "This field is required" : "";

  useEffect(() => {
    setPageTitle("Edit License Pack" + (licensePack === undefined ? "" : ` - ${licensePack.name}`));

    if (id !== undefined && licensePack === undefined) {
      dataProvider?.getLicensePack(id).then((data) => {
        setLicensePack(data);
        setProviderError(undefined);
      }).catch((error) => {
        setProviderError("Unable to load license pack - try again later");
      });
    }
  }, [id, licensePack]);

  const handleSubmit = (formData: any) => {
    const updatedLicensePack : IFSLicensePack = {
      id: licensePack!.id,
      createdBy: licensePack!.createdBy,
      creationDate: licensePack!.creationDate,
      expireDate: formData.expireDate,
      customerId: licensePack!.customerId,
      salesAssetReference: formData.salesAssetReference || '',
      salesCustomerReference: formData.salesCustomerReference || '',
      salesNotes: formData.salesNotes || '',
      siteReference: formData.siteReference,
      licenses: formData.licenses,
      name: formData.name,
    }
    
    dataProvider?.editLicensePack(licensePack!.id, updatedLicensePack)
    .then(() => {
      navigate(-1);
      setProviderError(undefined);
    }).catch(() => {
      setProviderError("Unable to edit license - try again later");
    })
  };

  return (
    <FSTFDefaultTemplate>
       <div className="layout-container k-m-4">
      {licensePack === undefined && providerError === undefined && (
        <Card><CardBody>Loading...</CardBody></Card>
      )}

      {licensePack === undefined && providerError !== undefined && (
        <Card><CardBody>{providerError}</CardBody></Card>
      )}

      {licensePack !== undefined && providerError === undefined && (
        <Card><CardBody>
        <Form
          initialValues={licensePack}
          onSubmit={handleSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <><h1>License Information</h1>
              <FormElement>
                <fieldset className={"k-form-fieldset"}>
                  <Field 
                    name={"name"} 
                    label={"Name"} 
                    component={LicenceTextInputFieldReadOnly} />

                  <Field 
                    name={"description"} 
                    label={"Description"} 
                    component={LicenceTextInputFieldReadOnly} />

                  <Field
                    name={"creationDate"}
                    label={"Creation Date"}
                    component={LicenceDateInputFieldReadOnly}
                  />

                  <Field
                    name={"expireDate"}
                    label={"Expiry Date"}
                    component={LicenceDateInputFieldReadOnly}
                  />
                  <Field
                    name={"siteReference"}
                    label={"Site Reference"}
                    component={LicenceTextInputFieldReadOnly}
                  />
                </fieldset>
                <h2>Metadata</h2>
                <Field
                    name={"salesAssetReference"}
                    label={"Sales Asset Reference"}
                    component={LicenceTextInputField}
                    validator={formTextValidator}
                  />

                  <Field
                    name={"salesCustomerReference"}
                    label={"Sales Customer Reference"}
                    component={LicenceTextInputField}
                    validator={formTextValidator}
                  />

                <h2>License Items</h2>
                <Field
                    name={"salesNotes"}
                    label={"Origin License Sets"}
                    component={LicenceTextInputFieldReadOnly}
                  />
                  <br />
                <PanelBar expandMode={"single"}>
                  {formRenderProps
                    .valueGetter("licenses")!
                    .map((license: IFSLicense, index: number) => {
                      return (
                        <PanelBarItem className="fs-license-baritem"
                          key={index}
                          title={license.name}
                          expanded={false}
                        >
                          <fieldset className={"k-form-fieldset"} key={index}>
                            <Field
                              name={`licenses[${index}].name`}
                              label={"Name"}
                              component={LicenceTextInputFieldReadOnly}
                            />
                            <Field
                              name={`licenses[${index}].reference`}
                              label={"Reference"}
                              component={LicenceTextInputFieldReadOnly}
                            />
                            <Field
                              name={`licenses[${index}].description`}
                              label={"Description"}
                              component={LicenceTextInputFieldReadOnly}
                            />
                            <Field
                              name={`licenses[${index}].dateAssigned`}
                              label={"Assigned Date"}
                              component={LicenceDateInputFieldReadOnly}
                            />
                            <Field
                              name={`licenses[${index}].endDate`}
                              label={"End Date"}
                              component={LicenceDateInputFieldReadOnly}
                            />
                            <Field
                              name={`licenses[${index}].version`}
                              label={"Version"}
                              component={LicenceTextInputFieldReadOnly}
                            />                     
                          </fieldset>
                        </PanelBarItem>
                      );
                    })}
                </PanelBar>
                
                <br/>
                <Button themeColor={"primary"} type={"submit"}>
                  Update Metadata
                </Button>
              </FormElement>
            </>
          )}
        />
        </CardBody></Card>
      )}
      </div>
    </FSTFDefaultTemplate>
  );
};
