import { Upload } from "@progress/kendo-react-upload";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import {
  Label,
  Error,
  Hint
} from "@progress/kendo-react-labels";


export function FormUpload(fieldRenderProps: FieldRenderProps) {
  const {
    value, id, optional, label, hint, validationMessage, touched, ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const onChangeHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  const onRemoveHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };

  return (
    <FieldWrapper>
      <Label
        id={labelId}
        editorId={id}
        optional={optional}
        className="k-form-label"
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <Upload
          id={id}
          autoUpload={false}
          showActionButtons={false}
          multiple={false}
          files={value}
          onAdd={onChangeHandler}
          onRemove={onRemoveHandler}
          {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
}
