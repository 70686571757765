import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";

export type NotificationProps = {
  successful: boolean;
  message: string;
  onClose: () => void;
}

export const NotificationPopup = (props: NotificationProps) => {
 
  const notificationPosition = {
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)"
  };

  return (
    <NotificationGroup 
      style={notificationPosition}>
        <Notification 
          type={{ style: (props.successful ? "success" : "error"), icon: true }}
          closable={true}
          onClose={props.onClose}>
            <span>{props.message}</span>
        </Notification>
    </NotificationGroup>  
  );
};
