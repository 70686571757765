import { StackLayout } from "@progress/kendo-react-layout";
import ConfigEditorWrapper from "./FSTF.Config.EditorWrapper";
import ConfigPluginBar from "../molecules/FSTF.Config.PlugBar";

interface FSTFConfigurationGroupProps {
  configData: { [key: string]: any };
  simpleProperties: { [key: string]: any };
  complexProperties: { [key: string]: any };
  onDataChange: (path: string, value: any) => void;
  onAddSection: (
    path: string,
    sectionType: string,
    sectionTitle: string,
    sectionValue: string,
    sectionName: string,
    mode?: string
  ) => void;
  onRemoveSection: (path: string) => void;
  onAddPlugin: (path: string) => void;
}

const FSTFConfigurationGroup: React.FC<FSTFConfigurationGroupProps> = ({
  configData,
  simpleProperties,
  complexProperties,
  onDataChange,
  onAddSection,
  onRemoveSection,
  onAddPlugin,
}) => {
  const handleDataChange = (path: string, value: any) =>
    onDataChange(path, value);

  const handleAddSection = (
    path: string,
    sectionType: string,
    sectionTitle: string,
    sectionValue: string,
    sectionName: string,
    mode?: string
  ) =>
    onAddSection(
      path,
      sectionType,
      sectionTitle,
      sectionValue,
      sectionName,
      mode
    );

  const handleRemoveSection = (path: string) => onRemoveSection(path);

  const handleAddPlugin = (pluginType: string) => onAddPlugin(pluginType);

  const components: React.JSX.Element[] = [];

  return (
    <div className="configuration-group">
      <StackLayout orientation="vertical" gap={15} align={{ vertical: `top` }}>
        <div key={"root-items"}>
          <ConfigEditorWrapper
            title={"Configuration Editor"}
            configData={simpleProperties}
            rootPath={""}
            sectionName={""}
            expanded={true}
            onDataChange={onDataChange}
            onAddSection={handleAddSection}
            onRemoveSection={handleRemoveSection}
          />
        </div>

        {Object.keys(complexProperties).map((key, index) => {
          const value = complexProperties[key];
          const isArrayItem = Array.isArray(value);
          const isObjectItem = typeof value === "object";

          if (key === "plugins") {
            //This is the plugin section, we want to add in a button to create new plugins
            components.push(
              <ConfigPluginBar
                key="pluginBar"
                pluginTypes={[
                  {
                    filetransfer: {
                      value: "File Transfer",
                      icon: "k-i-folder-up",
                    },
                  },
                  {
                    contentvalidation: {
                      value: "Content Validation",
                      icon: "k-i-check-outline",
                    },
                  },
                ]}
                onAddPlugin={handleAddPlugin}
              />
            );
          }

          if (isObjectItem && !isArrayItem) {
            let editor = (
              <div key={`${key}:${index}`}>
                <ConfigEditorWrapper
                  title={`${key}`}
                  configData={complexProperties[key]}
                  rootPath={key}
                  sectionName={""}
                  onDataChange={handleDataChange}
                  onAddSection={handleAddSection}
                  onRemoveSection={handleRemoveSection}
                />
              </div>
            );
            components.push(editor);
          } else if (isArrayItem) {
            return Object.keys(configData[key]).map((subkey, subIndex) => {
              let subData = configData[key];
              let editor = (
                <div key={`${key}:${subkey}:${subIndex}`}>
                  <ConfigEditorWrapper
                    title={`${subData[subkey].name}`}
                    rootPath={`${key}[${subkey}]`}
                    sectionName={subData[subkey].name.toLowerCase()}
                    configData={subData[subkey]}
                    onDataChange={handleDataChange}
                    onAddSection={handleAddSection}
                    onRemoveSection={handleRemoveSection}
                  />
                </div>
              );
              components.push(editor);
            });
          } else {
            let subData: any = {};
            subData[key] = configData[key];
            let editor = (
              <div key={`${key}:${index}`}>
                <ConfigEditorWrapper
                  key={key}
                  title={`${key}`}
                  configData={subData}
                  rootPath={key}
                  sectionName={""}
                  onDataChange={handleDataChange}
                  onAddSection={handleAddSection}
                  onRemoveSection={handleRemoveSection}
                />
              </div>
            );
            components.push(editor);
          }

          return components;
        })}
      </StackLayout>
    </div>
  );
};

export default FSTFConfigurationGroup;
