import { DatePicker } from "@progress/kendo-react-dateinputs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";

export const LicenceDateInputField = (fieldRenderProps: any) => {
  const { id, label, onChange, value } =
    fieldRenderProps;

    const dateChangeHandler = (e: any)  => {
      //Submit button will not be clickable unless this event is propogated
      onChange(e);
    }

  return (
    <FieldWrapper>
      <Label editorId={id}>{label}</Label>
      <div>
        <DatePicker 
          placeholder="Choose a date..." 
          format={"dd-MMM-yyyy"}
          onChange={e => dateChangeHandler(e)}
          defaultValue={value !== undefined ? new Date(value) : undefined}
          required={true} />
      </div>
    </FieldWrapper>
  );
};