import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FSAppContext } from "../../providers/FSTF.Context";
import { defaultUserDetails } from "../../types/IFSUsers";

export const FSTFLogout = () => {
  const {
    localContentService,
    setIsLoggedInValue,
    setIsSiteAdminValue,
    setIsValidCustomerAccessValue,
    setPageTitle
  } = useContext(FSAppContext);
  const { setUserValue } = useContext(FSAppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      setUserValue &&
      setIsLoggedInValue &&
      setIsSiteAdminValue &&
      setIsValidCustomerAccessValue
    ) {
      setPageTitle("Logout"); 
      setIsSiteAdminValue(false);
      setIsValidCustomerAccessValue(false);
      setUserValue(defaultUserDetails);
      setIsLoggedInValue(false);
    }

    localContentService?.ClearLocalContent();
    navigate("/login");
  });
  return null;
};
