import React from "react";
import { Input, NumericTextBox, Checkbox } from "@progress/kendo-react-inputs";
import RemoveSectionButton from "../molecules/FSTF.Config.RemoveSectionButton";

import AddNewSection from "../molecules/FSTF.Config.AddNewSection";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Card,
  CardBody,
} from "@progress/kendo-react-layout";
import { StringUtils } from "../../Utilities/FSTF.StringUtils";

interface ConfigEditorProps {
  configData: { [key: string]: any };
  rootPath: string;
  sectionName: string;
  onChange: (path: string, value: any) => void;
  onAddSection: (
    mode: string,
    path: string,
    sectionTitle: string,
    sectionValue: string,
    section?: any
  ) => void;
  onRemoveSection: (path: string) => void;
}

const ConfigEditor: React.FC<ConfigEditorProps> = ({
  configData,
  rootPath,
  sectionName,
  onChange,
  onAddSection,
  onRemoveSection,
}) => {
  const handleChange = (path: string, value: any) => {
    onChange(path, value);
  };

  const handleAddSection = (
    mode: string,
    path: string,
    sectionTitle: string,
    sectionValue: string,
    section?: any
  ): void => {
    onAddSection(mode, path, sectionTitle, sectionValue, section);
  };

  const handleRemoveSection = (path: string): void => {
    onRemoveSection(path);
  };

  const renderField = (
    key: string,
    value: any,
    path: string,
    sectionName: string,
    padding: number = 0
  ) => {
    const fullPath = path
      ? key.startsWith("[")
        ? `${path}${key}`
        : `${path}.${key}`
      : key;

    var isObjectRoot = padding < 10;

    const title = StringUtils.toHumanReadable(
      key.startsWith("[") ? value.id : key
    );

    const isArrayItem = Array.isArray(value);
    const isBasicArray =
      Array.isArray(value) &&
      value.every(
        (item) =>
          typeof item === "string" ||
          typeof item === "number" ||
          typeof item === "boolean"
      );

    const isObjectItem = typeof value === "object";

    const titleComponent = (items?: React.JSX.Element[]) =>
      isArrayItem || (isObjectItem && title !== undefined) ? (
        <AppBar>
          <AppBarSection>
            <h3>{title}</h3>
          </AppBarSection>
          <AppBarSpacer
            style={{
              width: 32,
            }}
          />

          <AppBarSection>
            {isArrayItem && isObjectItem ? (
              <>
                <AddNewSection
                  nodeKey={key}
                  title={title ?? "section"}
                  onAddSection={handleAddSection}
                  fullPath={fullPath}
                  sectionName={sectionName}
                />
                <AppBarSpacer
                  style={{
                    width: 32,
                  }}
                />
              </>
            ) : (
              <></>
            )}
            <AddNewSection
              nodeKey={key}
              title={title}
              onAddSection={handleAddSection}
              fullPath={fullPath}
              sectionName={sectionName}
              propertyNameRequired={isBasicArray ? false : true}
              mode={"property"}
            />

            <AppBarSpacer
              style={{
                width: 32,
              }}
            />
            {items}
          </AppBarSection>
        </AppBar>
      ) : !isArrayItem && isObjectItem ? (
        <AppBar>
          <AppBarSection>
            <AddNewSection
              nodeKey={key}
              title={title}
              onAddSection={handleAddSection}
              fullPath={fullPath}
              sectionName={sectionName}
              propertyNameRequired={isBasicArray ? false : true}
              mode={"property"}
            />
            <AppBarSpacer
              style={{
                width: 32,
              }}
            />
            {items}
          </AppBarSection>
        </AppBar>
      ) : (
        <></>
      );

    const newPadding = 20;
    const fieldStyle = {
      paddingLeft: `${padding}px`,
      marginLeft: "2px",
      marginTop: `${padding / 2}px`,
      borderLeft: !isObjectRoot ? "5px solid #CCCCCC" : "",
    };

    if (isObjectItem && !isArrayItem && value !== null) {
      return title === undefined ? (
        <Card
          key={`card-${key}-${title}-${fullPath}`}
          style={{
            boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
            borderWidth: "1px",
            margin: "10px",
          }}
        >
          <CardBody key={`cardbody-${key}-${title}-${fullPath}`}>
            {titleComponent([
              <RemoveSectionButton
                key={`remove-${key}-${sectionName}-${fullPath}`}
                path={fullPath}
                onClick={handleRemoveSection}
              />,
            ])}
            {Object.keys(value).map((subKey) =>
              renderField(
                subKey,
                value[subKey],
                fullPath,
                sectionName,
                newPadding
              )
            )}
          </CardBody>
        </Card>
      ) : (
        <div key={key} style={fieldStyle}>
          {titleComponent([
            <RemoveSectionButton
              key={`remove-${key}-${sectionName}-${fullPath}`}
              path={fullPath}
              onClick={handleRemoveSection}
            />,
          ])}
          {Object.keys(value).map((subKey) =>
            renderField(
              subKey,
              value[subKey],
              fullPath,
              sectionName,
              newPadding
            )
          )}
        </div>
      );
    } else if (isObjectItem && !isArrayItem) {
      return (
        <div key={key} style={fieldStyle}>
          {titleComponent([
            <RemoveSectionButton
              key={`remove-${key}-${sectionName}-${fullPath}`}
              path={fullPath}
              title={title}
              onClick={handleRemoveSection}
            />,
          ])}
        </div>
      );
    } else if (isArrayItem) {
      if (isBasicArray) {
        return (
          <div key={key} style={fieldStyle}>
            {titleComponent([
              <RemoveSectionButton
                key={`remove-${key}-${title}-${fullPath}`}
                path={fullPath}
                title={title}
                onClick={handleRemoveSection}
              />,
            ])}
            {value.map((item, index) => {
              return renderField(
                "[" + (index === 0 ? "0" : `${index}`) + "]",
                item,
                fullPath,
                sectionName,
                newPadding
              );
            })}
          </div>
        );
      } else {
        return (
          <div key={key} style={fieldStyle}>
            {titleComponent([
              <RemoveSectionButton
                key={`remove-${key}-${title}-${fullPath}`}
                path={fullPath}
                title={title}
                onClick={handleRemoveSection}
              />,
            ])}
            {value.map((item, index) =>
              renderField(`[${index}]`, item, fullPath, sectionName, newPadding)
            )}
          </div>
        );
      }
    } else {
      return (
        <div key={key}>
          {renderInputField(value, fullPath, title)}
          {title !== undefined &&
          (title.toLowerCase() === "id" ||
            title.toLowerCase() === "name" ||
            title.toLowerCase() === "type") ? (
            <></>
          ) : (
            <RemoveSectionButton
              key={`remove-${key}-${title}-${fullPath}`}
              path={fullPath}
              inline={true}
              onClick={handleRemoveSection}
            />
          )}
        </div>
      );
    }
  };

  const renderInputField = (value: any, path: string, title: string) => {
    switch (typeof value) {
      case "string":
        return (
          <Input
            label={title === undefined ? " " : title}
            value={value}
            onChange={(e) => handleChange(path, e.target.value)}
          />
        );
      case "number":
        return (
          <NumericTextBox
            label={title === undefined ? " " : title}
            format={"0"}
            min={0}
            spinners={false}
            value={value}
            onChange={(e) => handleChange(path, e.value)}
          />
        );
      case "boolean":
        return (
          <Checkbox
            label={title === undefined ? " " : title}
            checked={value}
            onChange={(e) => handleChange(path, e.value)}
          />
        );
      default:
        return <span>Unsupported type</span>;
    }
  };

  return (
    <div>
      <>
        <AppBar>
          <AddNewSection
            nodeKey={""}
            title={""}
            onAddSection={handleAddSection}
            fullPath={rootPath}
            sectionName={sectionName}
            mode="property"
          />
          {sectionName?.length > 0 ? (
            <RemoveSectionButton
              key={`remove-${sectionName}`}
              path={rootPath}
              onClick={handleRemoveSection}
            />
          ) : (
            <></>
          )}
        </AppBar>
      </>
      {Object.keys(configData).map((key) =>
        renderField(key, configData[key], rootPath, sectionName, 0)
      )}
    </div>
  );
};

export default ConfigEditor;
