import React, { useContext, useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { StackLayout } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { FSAppContext } from "../../providers/FSTF.Context";
import { useNavigate, useParams } from "react-router-dom";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";

export const FSTFResetPassword = () => {
  const { dataProvider, setPageTitle } = useContext(FSAppContext);
  const navigate = useNavigate();
  const {resetcode} = useParams()
  const [message, setMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false); // New state to track reset success  
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  
  useEffect(() => {
    setPageTitle("Reset Password");
  }, []);
  
   const handleSubmit = async (e: any) => {   
    setPasswordValidationMessage("");
    if (e.password.length < 6 || !/\W/.test(e.password) || !/\d/.test(e.password) || !/[A-Z]/.test(e.password)){
      setPasswordValidationMessage("*Password must have least 6 chars,symbol,digit,upper-lowercase combination");
      return;
    } 
    if (e.password !== e.confirmPassword) {
      setMessage("Passwords do not match!");
      setShowDialog(true);
    } else {
      try {
        if (dataProvider) {
          const data = await dataProvider.resetPassword(e.email, e.password, resetcode || '');
          if (data === true) {
            setMessage('Your password has been reset successfully. Please Login.');
            setResetSuccessful(true); 
          } else {
            setMessage('Failed to reset password. Please ensure your reset link is correct and try again.');
            setResetSuccessful(false); 
          }
          setShowDialog(true);
        } else {
          throw new Error('DataProvider is undefined.');
        }
      } catch (error:any) {
        setMessage(`Unable to reset password: ${error.message}`);
        setShowDialog(true);
      }
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    if (resetSuccessful) {
      navigate('/login'); // Navigate to login page if reset was successful
    }
  };

  return (
    <FSTFDefaultTemplate>
      {showDialog && (
        <Dialog title={"Message"} onClose={handleCloseDialog}>
          <p style={{ margin: "25px", textAlign: "center" }}>{message}</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button  onClick={handleCloseDialog}>OK</Button>
          </div>
        </Dialog>
      )}
      <StackLayout orientation="horizontal" className="app-login">
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className="app-login-form">
                <legend>Please enter details below:</legend>
                <Field name="email" component={Input} label="Email" type="email" required={true} />
                <Field name="password" component={Input} label="Password" type="password" required={true}/>
                {passwordValidationMessage && (
                    <div style={{ color: 'red' , fontSize: '0.6rem'}}>{passwordValidationMessage}</div>
                  )}
                <Field name="confirmPassword" component={Input} label="Confirm Password" type="password" required={true} />
              </fieldset>
              <Button type="submit" >Reset</Button>
            </FormElement>
          )}
        />
      </StackLayout>
    </FSTFDefaultTemplate>
  );
};