import { Route, Routes } from "react-router-dom";
import { FSTFConfiguration } from "../pages/FSTF.Configuration";
import { useContext } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
import { FSTFAbout } from "../pages/FSTF.About";
import { FSTFAdministration } from "../pages/FSTF.Administration";
import { FSTFCustomers } from "../pages/FSTF.Customers";
import { FSTFDashboard } from "../pages/FSTF.Dashboard";
import { FSTFLicenses } from "../pages/FSTF.Licenses";
import { FSTFLogin } from "../pages/FSTF.Login";
import { FSTFLogout } from "../pages/FSTF.Logout";
import { FSTFPackages, } from "../pages/FSTF.Packages";
import { FSTFSupport } from "../pages/FSTF.Support";
import { FSTFDebugging } from "./FSTF.Debugging";
import { FSTFProducts } from "../pages/FSTF.Products";
import { FSTFProduct } from "../pages/FSTF.Product";
import { FSTFProductDocumentation } from "../pages/FSTF.Product.Documentation";
import { FSTFEditLicenses } from "../pages/FSTF.EditLicenses";
import { FSTFProductDownload } from "../pages/FSTF.Product.Download";
import { FSTFAddLicenses } from "../pages/FSTF.AddLicenses";
import { FSTFUsers } from "../pages/FSTF.Users";
import { FSTFResetPasswordReq } from "../pages/FSTF.ResetPasswordReq";
import { FSTFResetPassword } from "../pages/FSTF.ResetPassword";
import { FSTFDashboardLoggedIn } from "../pages/FSTF.Dashboard-LoggedIn";
import { FSTFDownloadLicenseEula } from "../pages/FSTF.DownloadLicenseEula";
import { FSTFDownloadLicense } from "../pages/FSTF.DownloadLicense";

const FSTFRoutes = () => {
  const { showNavigation,isLoggedIn } = useContext(FSAppContext);

  return (
    <Routes>
      {showNavigation ? (
        <>
          <Route path="/administration" Component={FSTFAdministration} />
          <Route path="/licenses" Component={FSTFLicenses} />
          <Route path="/licenses/:customerId" Component={FSTFLicenses} />
          <Route path="/licenses/add" Component={FSTFAddLicenses} />
          <Route path="/licenses/add/:customerId" Component={FSTFAddLicenses} />
          <Route path="/licenses/edit/:id" Component={FSTFEditLicenses} />
          <Route path="/licenses/eula/:licenseid" Component={FSTFDownloadLicenseEula} />
          <Route path="/licenses/download/:licenseid" Component={FSTFDownloadLicense} />
          <Route path="/customers" Component={FSTFCustomers} />
          <Route path="/users" Component={FSTFUsers} />
          <Route path="/configuration" Component={FSTFConfiguration} />
          <Route path="/products" Component={FSTFProducts} />
          <Route path="/product/:id" Component={FSTFProduct} />
          <Route
            path="/product/:id/documentation"
            Component={FSTFProductDocumentation}
          />
          <Route path="product/:id/download" Component={FSTFProductDownload} />
          <Route path="/about" Component={FSTFAbout} />
          <Route path="/support" Component={FSTFSupport} />
          <Route path="/logout" Component={FSTFLogout} />
          <Route path="/login" Component={FSTFLogin} />
          <Route path="/packages" Component={FSTFPackages} />
          
          <Route path="/" Component={FSTFDashboard} />
          <Route path="/dashboard-loggedin" Component={FSTFDashboardLoggedIn} />

          <Route path="*" Component={FSTFDashboard} />
          <Route path="/debugging" Component={FSTFDebugging} />
          <Route path="/resetPasswordreq" Component={FSTFResetPasswordReq} />
          <Route path="/reset/:resetcode" Component={FSTFResetPassword} />
        </>
      ) : (
        <Route path="/" Component={FSTFConfiguration} />
      )}
    </Routes>
  );
};

export default FSTFRoutes;
