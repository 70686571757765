import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../service/FSTF.AppInsights";
import { useContext } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
export function WithAppInsights(comp:any){
    const {environmentService} = useContext(FSAppContext);
    let env= environmentService?.getEnvironment();

    if(env!=undefined && (env.UIMode==="online" && env.AppInsightsDisabled===false)){
        return (<AppInsightsContext.Provider value={reactPlugin}>{comp}</AppInsightsContext.Provider>);
    }else{
        return comp;
    }
}