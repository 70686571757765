import { createContext } from "react";


export interface IFSLoggingService{
    LogConsole(msg:string):void;
}
export class FSLoggingService implements IFSLoggingService{
    constructor(){
        
    }
    LogConsole(msg:string):void{
        console.log(msg);
    }
}

export const FSLoggingProvider = (props: any) => {

    
    return(props.children);
}

var defaultLoggingContext={
    LogConsole:(msg:string)=>{console.log(msg)},
}
export const FSLoggingContext =  createContext<IFSLoggingProps>(defaultLoggingContext);

export const FSLoggingConsumer = (props: any) => {
    return <FSLoggingContext.Consumer>{props.children}</FSLoggingContext.Consumer>;
};
export interface IFSLoggingProps {
    LogConsole:any;
}
export default FSLoggingProvider;