import { FieldWrapper } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useRef } from "react";

export const LicenceDateInputFieldReadOnly = (fieldRenderProps: any) => {
  const { id, label, required, value, ...others } =
      fieldRenderProps;
    
    const editorRef = useRef<any>(null);

    return (
      <FieldWrapper>
        <Label editorId={id}
        editorRef={editorRef}>
          {label}
        </Label>
        <div>
          <Input
            ref={editorRef}
            id={id}
            readOnly={true}
            //give a format which matches the Add Licenses page
            value={new Date(value).toLocaleDateString(undefined, {year: 'numeric', month: 'short', day: '2-digit', }).replaceAll(' ', '-')}
            {...others}
          />
        </div>
      </FieldWrapper>
    );
};