import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { Hint } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import AddMultipleSectionsButton from "../atoms/FSTF.AddMutlipleSectionsButton";
import { ConfigurationTemplateManager } from "../../providers/FSTF.ConfigurationTemplateManager";
import { ConfigurationTemplate } from "../../models/FSTF.ConfigurationTemplate";
import { plusIcon } from "@progress/kendo-svg-icons";

interface ConfigAddNewSectionProps {
  nodeKey: string;
  title: string;
  fullPath: string;
  sectionName: string;
  propertyNameRequired?: boolean;
  mode?: string;
  onAddSection: (
    mode: string,
    path: string,
    sectionTitle: string,
    sectionValue: string,
    section?: any
  ) => void;
}

const ConfigAddNewSection: React.FC<ConfigAddNewSectionProps> = ({
  nodeKey,
  title,
  fullPath,
  sectionName,
  mode = "section",
  propertyNameRequired = true,
  onAddSection,
}) => {
  const [visible, setVisible] = React.useState(false);
  const [sectionTitle, setSectionTitle] = React.useState<any>();
  const [sectionValue, setSectionValue] = React.useState<any>();
  const [sectionValueType, setSectionValueType] = React.useState<any>("text");
  const [sectionTemplateExists, setsectionTemplateExists] =
    React.useState<ConfigurationTemplate>(new ConfigurationTemplate(false));

  React.useEffect(() => {
    new ConfigurationTemplateManager()
      .GetTemplates(sectionName, title, mode)
      .then(setsectionTemplateExists);
  }, []);

  const toggleDialog = () => {
    setVisible(!visible);
    setSectionTitle(undefined);
    setSectionValue(undefined);
    setSectionValueType("text");
  };

  const handleAddSection = (
    mode: string,
    path: string,
    sectionTitle: string,
    sectionValue: string,
    section?: any
  ): void => {
    setSectionTitle(undefined);
    setSectionValue(undefined);
    setSectionValueType("text");
    onAddSection(mode, path, sectionTitle, sectionValue, section);
  };

  const valueTypes = ["text", "number", "boolean", "list"];

  function isValid() {
    let sectionValueTypeIsValid =
      sectionValueType == "text" ||
      sectionValueType == "number" ||
      sectionValueType == "boolean" ||
      sectionValueType == "list";

    let sectionValueIsValid =
      sectionValue !== undefined && sectionValue.length > 0;
    let sectionTitleIsValid =
      propertyNameRequired === false ||
      (sectionTitle !== undefined && sectionTitle.length > 0);

    let isValidValue = false;

    switch (sectionValueType) {
      case "number":
        isValidValue =
          sectionValue != undefined && !Number.isNaN(parseInt(sectionValue));
        break;
      case "boolean":
        isValidValue =
          sectionValue != undefined &&
          (sectionValue.toLowerCase() === "true" ||
            sectionValue.toLowerCase() === "false");
        break;
      case "list":
        isValidValue = true;
        break;
      default:
        isValidValue = true;
        break;
    }

    return (
      sectionValueTypeIsValid &&
      sectionValueIsValid &&
      sectionTitleIsValid &&
      isValidValue
    );
  }

  function dataTypeHint() {
    let hintMsg = "Allowed values: Any";
    switch (sectionValueType) {
      case "number":
        hintMsg = "Allowed values: valid numbers";
        break;
      case "boolean":
        hintMsg = "Allowed values: true, false";
        break;
      case "list":
        hintMsg =
          "Values must be as comma separated: value01, value01, value03\nAll values from will be treated as strings";
        break;
    }
    return hintMsg;
  }

  return (
    <div>
      {mode.toLowerCase() === "property" ? (
        <Button
          key={nodeKey}
          svgIcon={plusIcon}
          title={`Add ${title}`}
          onClick={() => toggleDialog()}
          size={"small"}
        >
          Add Property
        </Button>
      ) : sectionTemplateExists.exists ? (
        sectionTemplateExists.type === "single" ? (
          <Button
            key={nodeKey}
            svgIcon={plusIcon}
            title={`Add ${title}`}
            onClick={() =>
              handleAddSection(
                mode,
                fullPath,
                "",
                "",
                sectionTemplateExists.data![0]
              )
            }
            size={"small"}
          >
            {`Add ${title} Section`}
          </Button>
        ) : (
          <AddMultipleSectionsButton
            sections={sectionTemplateExists.data}
            onclick={(section) => {
              handleAddSection(mode, fullPath, "", "", section);
            }}
          />
        )
      ) : (
        <></>
      )}

      {visible && (
        <Dialog
          title={
            mode.toLowerCase() === "property" ? "Add Property" : `Add ${title}`
          }
          onClose={toggleDialog}
        >
          <div
            style={{
              margin: "25px",
              marginTop: "",
              textAlign: "center",
            }}
          >
            <div>
              <ComboBox
                style={{
                  width: "300px",
                }}
                label="Type"
                data={valueTypes}
                defaultValue={sectionValueType}
                onChange={(e) => setSectionValueType(e.target.value)}
              />
            </div>
            {propertyNameRequired && sectionTemplateExists ? (
              <div>
                <Input
                  style={{
                    width: "300px",
                  }}
                  label="Name:"
                  value={sectionTitle}
                  onChange={(e) => setSectionTitle(e.target.value)}
                />
              </div>
            ) : (
              <></>
            )}
            <div>
              <Input
                style={{
                  width: "300px",
                }}
                label="Value:"
                value={sectionValue}
                onChange={(e) => setSectionValue(e.target.value)}
              />
              <Hint id="inputHint" className="line-breaks">
                {dataTypeHint()}
              </Hint>
            </div>
          </div>

          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDialog}
            >
              Cancel
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              disabled={!isValid()}
              onClick={() => {
                let value: any = undefined;

                switch (sectionValueType) {
                  case "number":
                    const intValue = parseInt(sectionValue);
                    value = Number.isNaN(intValue) ? 0 : intValue;
                    break;
                  case "boolean":
                    value = sectionValue.toLowerCase() === "true";
                    break;
                  case "list":
                    value = sectionValue
                      .split(",")
                      .map((s: string) => s.trim());
                    break;
                  default:
                    value = sectionValue;
                    break;
                }

                handleAddSection(mode, fullPath, sectionTitle, value);
                toggleDialog();
              }}
            >
              Create
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default ConfigAddNewSection;
