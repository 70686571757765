import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Card, CardBody, CardFooter, CardHeader, StackLayout } from "@progress/kendo-react-layout";
import {  useContext, useEffect, useState } from "react";
import IFSUsers from "../../types/IFSUsers";
import { IFSSiteRoles,IFSCustomerRoles, IFSRoles } from "../../types/IFSRoles";
import { FSAppContext } from "../../providers/FSTF.Context";
import IFSCustomer from "../../types/IFSCustomer";
import { SvgIcon } from "@progress/kendo-react-common";
import { editToolsIcon, trashIcon } from "@progress/kendo-svg-icons";
import { FSTFUserDetailForm} from "../molecules/FSTF.UserDetailForm";
import IFSCustomerUsers from "../../types/IFSCustomerUsers";


export const FSTFCustomerUsers = (props: any) => {
  const { user,setUserValue,useFakeData,dataProvider } =useContext(FSAppContext); 
  const [userDetail, setUserDetail] = useState<IFSUsers | null>(null);
  const [roles, setRoles] = useState<IFSRoles[]>([]);
  const [siteRoles, setSiteRoles] = useState<IFSSiteRoles[]>([]);
  const [showUserDetailForm, setShowUserDetailForm] = useState(false);
  const [selectedCustomerUsers, setSelectedCustomerUsers] = useState<IFSUsers[]>([]);
  const [selectedCustomerUsersRole, setSelectedCustomerUsersRole] = useState<string[]>();
  const [providerError, setProviderError] = useState<String>();
  const selectedCustomer=props.selectedCustomer as IFSCustomer;
  const [customerUsersRole,setCustomerUsersRole]=useState<IFSCustomerUsers[]>([]);
  const [type, setType] = useState("Add");
 
  const getUsers = () => {
    dataProvider?.getCustomerUsers(selectedCustomer!.id!).then((data) => {
      const filtered = data.filter(item => item.active !== false);
      setCustomerUsersRole(filtered);

      return Promise.all(filtered.map(user => {
        const userId = user.userId ? user.userId : "";
        return dataProvider?.getUser(userId).then(userData => {
          return Array.isArray(userData) ? userData[0] : userData;
        });
      }));
    }).then(usersData => {
      const activeUsers = usersData.filter(userData => userData && userData.active !== false);
      setSelectedCustomerUsers(activeUsers);
    }).catch((error) => {
      setProviderError("Unable to retrieve selected customer's active user list "+error.message);
    });
  };

  useEffect(() => { 
    
    try{
      getUsers();
      dataProvider?.getCustomerRoles().then((roledata) => {
        setRoles(roledata);
        setProviderError(undefined);
      }).catch((error) => {
        setProviderError("Unable to retrieve roles "+error.message);
      }); 

      dataProvider?.getSiteRoles().then((siteRoledata) => {
        setSiteRoles(siteRoledata);
        setProviderError(undefined);
      }).catch((error) => {
        setProviderError("Unable to retrieve siteRoles "+error.message);
      }); 
    }catch(error){
      console.log("ERR",error)
    }      
  }, [selectedCustomer.id]);

  const handleAddUserClick = () => { 
    setProviderError(undefined); 
    setType("Add");
    setShowUserDetailForm(true);
  };  

  const handleFormSubmit = (finalUserDetail: IFSUsers, customerRoles:IFSCustomerRoles[]) => {
    const customerRolesList=customerRoles.map(role=>role.roleName);
    if(finalUserDetail){       
      finalUserDetail.active=true;     
      finalUserDetail.createdByUserName=user.userName;
      finalUserDetail.modifiedByUserName=user.userName;
      if(finalUserDetail.id===undefined || finalUserDetail.id===null|| finalUserDetail.id===""){         
        dataProvider?.postUser(finalUserDetail)
          .then((newUser)  =>  {   
            const newCustomerUser = {userId:newUser.id,customerId:selectedCustomer.id,customerName:selectedCustomer.name,roles:customerRolesList};
            dataProvider?.postCustomerUserRoles(newCustomerUser).then((data) => { 
              if(data!=null){             
                setCustomerUsersRole(activeUserRoles => [newCustomerUser, ...activeUserRoles ]);
                newUser.companyRoles = data;
                setProviderError(undefined);
              }
              newUser.fullSiteRoles=finalUserDetail.fullSiteRoles;
              setSelectedCustomerUsers(activeUsers => [newUser, ...activeUsers]);
            })
            .catch((error)=>{
              setProviderError("Unable to update roles "+error.message);
            })
          })
          .catch((error) => {
            setProviderError("Unable to add new user "+error.message);
          });
      }
      else {     
        finalUserDetail.password="Password@123" // to make dto valid
        const matchingRecord = customerUsersRole.find(u => 
          u.customerId === selectedCustomer.id && u.userId === finalUserDetail.id && u.active !== false);
        const existingId= matchingRecord? matchingRecord.id : "";         
        const newCustomerUser = {id:existingId,userId:finalUserDetail.id,customerId:selectedCustomer.id,customerName:selectedCustomer.name,roles:customerRolesList};

           dataProvider?.putCustomerUser(finalUserDetail)
            .then((serverResponse) => {
              setProviderError(undefined)
              return serverResponse;

            }).then((updatedUser) => {
              dataProvider?.putCustomerUserRoles(newCustomerUser)
                .then((data) => {               
                  setCustomerUsersRole(activeUserRoles => 
                    activeUserRoles.filter(customerUserRole => customerUserRole.customerId !== newCustomerUser.customerId 
                    && customerUserRole.userId !== newCustomerUser.userId)
                  );

                  setCustomerUsersRole(activeUserRoles => [newCustomerUser, ...activeUserRoles ]);    
            
                  const mapped = selectedCustomerUsers.map(user => 
                      {
                        if (user.id === updatedUser.id) {
                          const retUser = {...updatedUser };
                          retUser.companyRoles = data;
                          return retUser;
                      } 
                      
                      else {
                        return user;
                      }});

                  setSelectedCustomerUsers(mapped);
          });

            setProviderError(undefined)
          })
        .catch((error) => {
          setProviderError("Unable to update user role "+error.message);
        }); 
      } 
    }
    setUserDetail(null);
    setShowUserDetailForm(false);    
  };
  
  const handleCancel = () => {    
    setProviderError(undefined);
    setUserDetail(null);
    setShowUserDetailForm(false);
    setSelectedCustomerUsersRole([]);    
  };

  const EditCell = (props:any) => (      
    <td>   
    <div title="Update User">
        <SvgIcon  icon={editToolsIcon} onClick={() => handleEditClick(props.dataItem)} />
    </div> 
  </td> 
  );
  
  const handleEditClick = (item: IFSUsers) => {
    setProviderError(undefined);
    setUserDetail(item);

    console.log("ITEM",item);
    const matchingRecord = customerUsersRole.find(u => 
      u.customerId === selectedCustomer.id && u.userId === item.id && u.active !== false);
      
    const existingRole= matchingRecord? matchingRecord.roles : null
    if(existingRole!==null){
      setSelectedCustomerUsersRole(existingRole);
    }
    setType("Update");
    setShowUserDetailForm(true);
  }
  const DeleteCell = (props:any) => (  
    <td>   
      <div title="Delete User">
        <SvgIcon onClick={() => handleDeleteClick(props.dataItem)} icon={trashIcon}>
         </SvgIcon>

      </div> 
    </td> 
  );
  
  const handleDeleteClick = (item: IFSUsers) => {   
    setProviderError(undefined); 
    const confirmDelete = window.confirm(`Are you sure to delete user for selected company : ${item.firstName} ${item.lastName?item.lastName:""}?`);
    if (confirmDelete) {
      const matchingRecord = customerUsersRole.find(u => 
        u.customerId === selectedCustomer.id && u.userId === item.id && u.active !== false);

      const existingId= matchingRecord? matchingRecord.id : "test"  // applicable to only fake api     

      setSelectedCustomerUsers(activeusers=> activeusers.filter(activeuser => activeuser.id !== item.id));

      const updatedCustomerUser = {id:existingId,userId:item.id,customerId:selectedCustomer.id,active:false,modifiedBy:user.userName};
      if(updatedCustomerUser!==null && updatedCustomerUser!==undefined)
      {
        dataProvider?.deleteCustomerUserRoles(updatedCustomerUser)
          .then(() => 
          setCustomerUsersRole(activecustomerUserRoles => activecustomerUserRoles.filter(
            customerUserRole => customerUserRole.customerId !== updatedCustomerUser.customerId 
            && customerUserRole.userId !== updatedCustomerUser.userId && customerUserRole.active !== false)
        ))
        .catch((error) => {
          setProviderError("Unable to delete user "+error.message);
        });    
      }
    }
    setShowUserDetailForm(false);
  }
  const RolesCell = (props:any) => {
    const { dataItem } = props;
    const selectedCompanyRoles = dataItem.companyRoles.filter((c: IFSCustomerRoles) => c.companyId === selectedCustomer.id);

    const roles = selectedCompanyRoles ? selectedCompanyRoles.map((role: IFSCustomerRoles) => role.roleName).join(', ') : '';
    return (
      <td>
        {roles}
      </td>
    );
  };
  return (
    <>
     <StackLayout orientation="vertical" >
      <Card className="fs-users">
        <CardHeader>
        Pane Login Accounts for {selectedCustomer.name}
        {providerError && (
          <div className="error">
            <h3>{providerError}</h3>
          </div>
          )} 
        </CardHeader>
        <CardBody className="fs-users-body">       
        {selectedCustomerUsers && selectedCustomerUsers.length >0 && (
          <Grid data={selectedCustomerUsers} 
              resizable={true}
              scrollable={"scrollable"}
              sortable={true}
              size={"small"}
              className="fs-users-grid"> 
            <Column cell={DeleteCell} className="fs-icon-button" width={"50px"}/>      
            <Column cell={EditCell} className="fs-icon-button" width={"50px"}/> 
            <Column field="firstName" title="FirstName"/>
            <Column field="lastName" title="LastName"  />
            <Column field="userName" title="UserName"/>
            <Column field="email" title="Email" /> 
            <Column field="mobile" title="Mobile" /> 
            <Column field="companyRoles" title="Roles" cell={RolesCell} />
          </Grid>
          )}
        </CardBody>
        <CardFooter>
        <Button onClick={handleAddUserClick} themeColor={"tertiary"}>Add User</Button>
        </CardFooter>
      </Card>
        
      {showUserDetailForm && (
        <FSTFUserDetailForm onSubmit={handleFormSubmit} onCancel={handleCancel} selectedUser={userDetail?userDetail:{}} roles={roles} siteRoles={siteRoles} customerId={selectedCustomer.id?selectedCustomer.id:""} assignedRole={selectedCustomerUsersRole} type={type}/>
        )}
    </StackLayout>  
    </> 
  );
};