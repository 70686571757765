export interface IFSRoles {
    roleId: string;
    roleName: string;
    description?: string;
}

export interface IFSSiteRoles extends IFSRoles {
    //just imports properties and does not add
}

export class FSSiteRoles implements IFSSiteRoles {
    roleId: string;
    roleName: string;
    description?: string;
    constructor(id: string, name: string, description?: string) {
        this.roleId = id;
        this.roleName = name;
        this.description = description;
    }
}

export interface IFSCustomerRoles extends IFSRoles {
    companyName?:string;
    companyId:string;
}

export class FSCustomerRoles implements IFSCustomerRoles {
    roleId: string;
    roleName: string;
    companyName?:string;
    companyId:string;
    description?: string;
    constructor(roleId: string, roleName: string, companyId:string, companyName?:string,description?: string) {
        this.roleId = roleId;
        this.roleName = roleName;
        this.companyName = companyName;
        this.companyId = companyId
    }
}
