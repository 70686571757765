
import { IFSSiteRoles,IFSCustomerRoles } from "../types/IFSRoles";
export interface IFSPermission {
    canView: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canAdd: boolean;
    canShare:boolean;
}
  
export interface IFSSiteRolePermissionsService {
    getRolePermission(perm:IFSSiteRolePermissionRequest):IFSPermission;
    getRolesPermission(perm:IFSSiteRolesPermissionRequest):IFSPermission;
    
}

export interface IFSSiteRolePermissionRequest{
    role:IFSSiteRoles;
    component:string;
    context:string|undefined | null;
}
export interface IFSSiteRolesPermissionRequest{
    roles:IFSSiteRoles[]|undefined;
    component:string;
    context:string|undefined | null;
}

export const USERROLEPERMISSION = {
    ADDCustomer: "addcustomer",
    EDITCustomer: "editcustomer",
    DELETECustomer: "deletecustomer",
    VIEWCustomer: "viewcustomer",
    NOTESCustomer: "addcustomernotes",
    USER: "user",
    LICENSE: "license",
    MESSAGECustomer: "messagecustomer"
};

export const SITEPERMISSION = {
    siteadmin :"siteadmin"
};

export interface IFSPermissionRoleComponentPermission{
    role:string;
    component:string;
    permissions:IFSPermission;
}
export interface IFSPermissionMatrix{
    permissions:IFSPermissionRoleComponentPermission[];
}