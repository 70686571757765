import React, { useState,useMemo, useContext, useEffect } from "react";

import { FSAppContext, defaultAppContext } from "./FSTF.Context";
import IFSUsers from "../types/IFSUsers";
import IFSTestDetails from "../types/IFSTestDetails";
import { IFSDataProvider } from "../types/IFSDataProvider";
import { FSTFDataProvider as FSDataProvider } from "./FSTF.DataProvider";
import { FSTFAuthProvider, IFSAuthProvider } from "./FSTF.AuthProvider";
import { SITEPERMISSION } from "../types/FSPermissionsDictionary";
import { FSEnvironmentContext } from "./FSTF.EnvironmentProvider";

//https://felixgerschau.com/react-typescript-context/
const FSAppContextProvider = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(defaultAppContext.isLoggedIn);
  const[loginChecked,setLoginChecked]=useState<boolean>(false);

  const [token, setToken] = useState<string>(defaultAppContext.token);
  const [refreshToken, setRefreshToken] = useState<string>(defaultAppContext.refreshToken);
  
  const [user, setUser] = useState<IFSUsers>(defaultAppContext.user);

  const [currentPage, setCurrentPage] = useState<string>(
    defaultAppContext.currentPage
  ); 
  const [testDetails, setTestDetails] = useState<IFSTestDetails>(
    defaultAppContext.testDetails
  );
  const [useFakeAuth, setUseFakeAuth] = useState<boolean>(
    defaultAppContext.useFakeAuth
  );
  const [useFakeData, setUseFakeData] = useState<boolean>(
    defaultAppContext.useFakeData
  );
 
  const [isDev, setIsDev] = useState<boolean>(defaultAppContext.isDev);
  const [isSiteAdmin, setIsSiteAdmin] = useState<boolean>(defaultAppContext.isSiteAdmin);
  const [isValidCustomerAccess, setIsValidCustomerAccess] = useState<boolean>(defaultAppContext.isValidCustomerAccess);

  const [showNavigation, setShowNavigation] = useState<boolean>(defaultAppContext.showNavigation);
  const [uiMode, setUIMode] = useState<string>(defaultAppContext.uiMode);

  const [dataProvider, setDataProvider] = useState<IFSDataProvider>(new FSDataProvider(useFakeData));
  const [authProvider, setAuthProvider] = useState<IFSAuthProvider>(new FSTFAuthProvider(useFakeAuth));
  
  const { environmentService,loggingService,localContentService }=useContext(FSEnvironmentContext);
  const setUserValue = (ud: IFSUsers) => {
    setUser(ud);
    
    localContentService?.SetUser(ud);
  };
  const setTokenValue = (str: string) => {
    setToken(str);
    localContentService?.SetAccessToken(str);
  };
  const setRefreshTokenValue = (str: string) => {
    setRefreshToken(str);
    localContentService?.SetRefreshToken(str);
  };
  const setIsLoggedInValue = (b: boolean) => {
    setIsLoggedIn(b);
  };
  const setTestDetailsValue = (td: IFSTestDetails) => {
    setTestDetails(td);
  };

  const setCurrentPageValue = (cp: string) => {
    setCurrentPage(cp);
    localStorage.setItem("currentPage", cp);
  };
  const setUseFakeAuthValue = (b: boolean) => {
    setUseFakeAuth(b);
  };
  const setUseFakeDataValue = (b: boolean) => {
    setUseFakeData(b);
  };
  const setIsDevValue = (b: boolean) => {
    setIsDev(b);
  };
  const setIsSiteAdminValue = (b: boolean) => {
    setIsSiteAdmin(b);
  };
  const setIsValidCustomerAccessValue = (b: boolean) => {
    setIsValidCustomerAccess(b);
  };

  // Providers available in context
  const setDataProviderValue=(dp:IFSDataProvider)=>{
    setDataProvider(dp);
  }

  const setAuthProviderValue=(ap:IFSAuthProvider)=>{
    setAuthProvider(ap);
  }
 
  const forceLogout=()=>{
    //navigate("/logout")
    console.log("FORCE LOGOUT");
  }

  const [title, setTitle] = useState<string>("TrustedFilter™ SECUREpane");

  const setPageTitle = (newTitle: string) => {
    if (newTitle !== title) {
      setTitle(newTitle);
    }
  }

  const ctx = {
    user,
    setUserValue,
    token,
    setTokenValue,
    refreshToken,
    setRefreshTokenValue,
    isLoggedIn,
    setIsLoggedInValue,
    testDetails,
    setTestDetailsValue,
    currentPage,
    setCurrentPageValue,
    useFakeAuth,
    setUseFakeAuthValue,
    useFakeData,
    setUseFakeDataValue,
    isDev,
    setIsDevValue,
    isSiteAdmin,
    setIsSiteAdminValue,
    isValidCustomerAccess,
    setIsValidCustomerAccessValue,
    showNavigation,
    setShowNavigation,
    uiMode,
    setUIMode,
    forceLogout,
    dataProvider,
    setDataProviderValue,
    setAuthProvider,
    setAuthProviderValue,
    
    loggingService,
    environmentService,
    localContentService,
    setPageTitle
  };

  useMemo(() => {
    if (loginChecked) return;
    

    if(environmentService?.getEnvironment().DevelopmentMode){
      setIsDev(true);
    }

    if(localContentService!=null && 
      environmentService!=null && 
      !isLoggedIn){
      const localContent=localContentService?.GetLocalContent();
      const currentPage = localContent.CurrentPage;
      setCurrentPage(currentPage+"")
      
      const loggedInUser = localContent.UserDetails;
      
      if (loggedInUser && loggedInUser?.id?.length != 0) {
        const token = localContent.AccessToken;
        const refreshToken = localContent.RefreshToken;
          
        setIsLoggedIn(true);
        setUser(loggedInUser);
        setToken(token+"");
        setRefreshToken(refreshToken+"");
        if (loggedInUser.siteRoles?.find((r) => r.toLowerCase() === SITEPERMISSION.siteadmin)) {
          setIsSiteAdminValue(true);
        }         
      }  else {
        setIsLoggedIn(false);
        setUser(defaultAppContext.user);
        localContentService.ClearLocalContent();
        setIsSiteAdminValue(false);
      }
      
      const uiModeFromEnv = environmentService.getEnvironment().UIMode; //online|config or not set
      setUIMode(uiModeFromEnv);
      setShowNavigation(uiModeFromEnv === "online");

      //prevent multiple checks
      setLoginChecked(true);
    }
    

  }, [ isLoggedIn, loginChecked,environmentService,localContentService]);


  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <FSAppContext.Provider value={ctx}>{props.children}</FSAppContext.Provider>
  );
};

export const FSAppContextConsumer = (props: any) => {
  return <FSAppContext.Consumer>{props.children}</FSAppContext.Consumer>;
};

export default FSAppContextProvider;
