import { createContext } from "react";
import IFSUsers, { defaultUserDetails } from "../types/IFSUsers";
import IFSTestDetails, { defaultTestDetails } from "../types/IFSTestDetails";
import { IFSDataProvider } from "../types/IFSDataProvider";
import { IFSAuthProvider } from "./FSTF.AuthProvider";
import { FSEnvironmentService,FSEnvironmentContext, IFSEnvironmentService } from "./FSTF.EnvironmentProvider";
import { IFSLocalContentService } from "./FSTF.LocalContentProvider";
import { IFSSiteRolePermissionsService } from "../types/FSPermissionTypes";
import { IFSLoggingService } from "./FSTF.LoggingProvider";
import { IFSSiteServices } from "./FSTF.SiteServices";
//https://dmitripavlutin.com/react-context-and-usecontext
//https://www.youtube.com/watch?v=XLJN4JfniH4
//https://www.youtube.com/watch?v=3XaXKiXtNjw

//https://blog.logrocket.com/how-to-use-react-context-typescript/

/* When using an interface, don't get confused with the setXYZ values 
from the useState hook, the functions defined in the interface 
are for updating the context via these methods here */

export interface IFSTFAppContextProps {
  isLoggedIn: boolean;
  setIsLoggedInValue?: (b: boolean) => void;
  isDev: boolean;
  setIsDevValue?: (b: boolean) => void;
  isSiteAdmin: boolean;
  setIsSiteAdminValue?: (b: boolean) => void;
  isValidCustomerAccess: boolean;
  setIsValidCustomerAccessValue?: (b: boolean) => void;
  token: string;
  setTokenValue?: (str: string) => void;
  refreshToken: string;
  setRefreshTokenValue?: (str: string) => void;
  user: IFSUsers;
  setUserValue?: (user: IFSUsers) => void;
  testDetails: IFSTestDetails;
  setTestDetailsValue?: (td: IFSTestDetails) => void;
  currentPage: string;
  setCurrentPageValue?: (cp: string) => void;
  useFakeAuth?: boolean;
  setUseFakeAuthValue?: (b: boolean) => void;
  useFakeData?: boolean;
  setUseFakeDataValue?: (b: boolean) => void;
  showNavigation?: boolean;
  setShowNavigation?: (b: boolean) => void;
  uiMode?: string;
  setUIMode?: (b: string) => void;

  authProvider?:IFSAuthProvider;
  setAuthProviderValue?:(ap:IFSAuthProvider)=>void;

  dataProvider?:IFSDataProvider;
  setDataProviderValue?:(dp:IFSDataProvider)=>void;

  environmentService?:IFSEnvironmentService;
  setEnvironmentServiceValue?:(es:IFSEnvironmentService)=>void;
  loggingService?:IFSLoggingService;
  setLoggingServiceValue?:(ls:IFSLoggingService)=>void;
  localContentService?:IFSLocalContentService;
  setLocalContentServiceValue?:(lcs:IFSLocalContentService)=>void;
  
  sitePermissionsService?:IFSSiteRolePermissionsService;
  setSitePermissionsServiceValue?:(sp:IFSSiteRolePermissionsService)=>void;
  setPageTitle:(title:string) => void;

}


const env = new FSEnvironmentService().getEnvironment(); //online|config or not set

export const defaultAppContext = {
  testDetails: defaultTestDetails,
  isLoggedIn: false,
  token: "",
  refreshToken:"",
  user: defaultUserDetails,
  currentPage: "",
  useFakeAuth: env.FakeApi,
  useFakeData: env.FakeAuth,
  isDev: false,
  isSiteAdmin: false,
  isValidCustomerAccess: false,
  uiMode: env.UIMode,
  showNavigation: env.UIMode==="online", //default to true for online mode

  dataProvider:undefined,
  authProvider:undefined,
  
  sitePermissionsService:undefined,

  environmentService:undefined,
  localContentService:undefined,
  loggingService:undefined,

  siteServices:undefined,
  setPageTitle: (newTitle: string) => {}
};

export const FSAppContext =
  createContext<IFSTFAppContextProps>(defaultAppContext);
