import { useContext, useEffect } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
import { FSTFLogout } from "../pages/FSTF.Logout";
import { StackLayout } from "@progress/kendo-react-layout";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";

import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import React from "react";
import {ShowContext, ShowCurrentUser, ShowEnvironment, ShowLocalContent, withCard } from "../molecules/FSTF.Debug.Components";




export const FSTFDebugging = (props: any) => {
  const {
    user,
    useFakeAuth,
    useFakeData,
    setUseFakeAuthValue,
    setUseFakeDataValue,
    isDev,
    localContentService,
    environmentService,
    setPageTitle
  } = useContext(FSAppContext);

  useEffect(() => {
    setPageTitle("Debugging");
  }, []);
  
  const ctx = useContext(FSAppContext);
  const isAnAdmin=user.siteRoles?.find((r) => r?.toLowerCase() === "admin");
  if ( !isDev && !isAnAdmin) {
    return (
      <>
        <div>Not authorized</div>
        <FSTFLogout />
      </>
    );
  }
  
  const handleSubmit = (e: any) => {
    if (setUseFakeAuthValue && setUseFakeDataValue) {
      setUseFakeAuthValue(e.auth);
      setUseFakeDataValue(e.data);
      console.log("debugging values set");
    } else {
      console.log("set values failed");
    }
  };
  
  
  return (
    <StackLayout orientation="vertical" gap={15}>
      {ShowLocalContent(localContentService)}
      {ShowCurrentUser(user)}
      {ShowEnvironment(environmentService)}
      {ShowContext(ctx)}

      {withCard(<Form
        initialValues={{ auth: useFakeAuth, data: useFakeData }}
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <fieldset>
              <legend>Fake Providers:</legend>
              <Field
                name={"auth"}
                component={Checkbox}
                label={"Auth"}
                value={true}
              />
              <Field name={"data"} component={Checkbox} label={"Data"} />
            </fieldset>
            <br/>
            <Button type={"submit"}>Update</Button>
          </FormElement>
        )}
      />)}
    </StackLayout>
  );
};
