import { IFSLicensePack } from "../types/IFSLicensePack";
import IFSAdministrationConfiguration from "../types/IFSAdministrationConfiguration";
import { IFSDataProvider } from "../types/IFSDataProvider";
import IFSDocumentation from "../types/IFSDocumentation";
import IFSProduct from "../types/IFSProduct";
import { FSTFFakeDataProvider } from "./FSTF.Fake.Provider";
import IFSUsers from "../types/IFSUsers";
import { IFSSiteRoles, IFSCustomerRoles, IFSRoles } from "../types/IFSRoles";
import IFSAppDownload from "../types/IFSAppDownload";
import IFSCustomer from "../types/IFSCustomer";
import IFSNote from "../types/IFSNote";
import IFSMessage from "../types/IFSMessage";
import IFSCustomerUsers from "../types/IFSCustomerUsers";
import { FSTFApiProvider } from "./FSTF.ApiProvider";
import {
  FSEnvironmentService,
  IFSEnvironmentService,
} from "./FSTF.EnvironmentProvider";
import { IFSLicenseSet } from "../types/IFSLicenseSet";
import { IFSLicenseRequest } from "../types/IFSLicenseRequest";
import IFSLicenseAgreement from "../types/IFSLicenseAgreement";
import IFSDashboard from "../types/IFSDashboard";

export class FSTFDataProvider implements IFSDataProvider {
  defaultDP = new FSTFApiProvider();
  fakeDP = new FSTFFakeDataProvider();
  private _dataProvider: IFSDataProvider;
  private _overrideFakeData: boolean;
  private environmentService: IFSEnvironmentService =
    new FSEnvironmentService();

  constructor(useFakeData: boolean = true) {
    this._dataProvider = useFakeData ? this.fakeDP : this.defaultDP;
    if (this.environmentService.getEnvironment().FakeApi) {
      this._dataProvider = this.fakeDP;
    }
    this._overrideFakeData = useFakeData;
  }

  async setFakeDataFlag(b: boolean) {
    this._overrideFakeData = b;
    this._dataProvider = b ? this.fakeDP : this.defaultDP;
  }
  async setDataProvider(dp: IFSDataProvider) {
    this._dataProvider = dp;
  }
  async saveProductUpload(newUpload: IFSAppDownload): Promise<IFSAppDownload> {
    return this._dataProvider.saveProductUpload(newUpload);
  }
  async getProductDownloads(id: string): Promise<IFSAppDownload[]> {
    return this._dataProvider.getProductDownloads(id);
  }
  async getLicensePack(id: string): Promise<IFSLicensePack> {
    return this._dataProvider.getLicensePack(id);
  }
  async getLicensePacksForCompany(
    companyId: string
  ): Promise<IFSLicensePack[]> {
    return this._dataProvider.getLicensePacksForCompany(companyId);
  }
  async addLicensePack(licensePack: IFSLicensePack): Promise<IFSLicensePack> {
    return this._dataProvider.addLicensePack(licensePack);
  }
  async deleteLicensePack(id: string): Promise<void> {
    return this._dataProvider.deleteLicensePack(id);
  }

  async editLicensePack(
    id: string,
    licensePack: IFSLicensePack
  ): Promise<IFSLicensePack> {
    return this._dataProvider.editLicensePack(id, licensePack);
  }

  async downloadLicensePack(
    id: string
  ): Promise<{ filename: string; blob: Blob }> {
    return this._dataProvider.downloadLicensePack(id);
  }

  async getReleases(): Promise<any> {
    return this._dataProvider.getReleases();
  }

  async getUsers(): Promise<IFSUsers[]> {
    return this._dataProvider.getUsers();
  }

  async getUser(id: string): Promise<IFSUsers> {
    return this._dataProvider.getUser(id);
  }

  async getAdministrationConfiguration(): Promise<IFSAdministrationConfiguration> {
    return this._dataProvider.getAdministrationConfiguration();
  }

  async getProduct(id: string): Promise<IFSProduct> {
    return this._dataProvider.getProduct(id);
  }

  async getProducts(): Promise<IFSProduct[]> {
    return this._dataProvider.getProducts();
  }

  async getProductDocumentation(id: string): Promise<IFSDocumentation[]> {
    return this._dataProvider.getProductDocumentation(id);
  }

  async getCustomers(): Promise<IFSCustomer[]> {
    return this._dataProvider.getCustomers();
  }

  async getCustomerRoles(): Promise<IFSCustomerRoles[]> {
    return this._dataProvider.getCustomerRoles();
  }

  async saveProductDocumentation(
    newDoc: IFSDocumentation
  ): Promise<IFSDocumentation> {
    return this._dataProvider.saveProductDocumentation(newDoc);
  }

  async addProduct(newProd: IFSProduct): Promise<IFSProduct> {
    return this._dataProvider.addProduct(newProd);
  }

  async postCustomer(newCustomer: IFSCustomer): Promise<IFSCustomer> {
    return this._dataProvider.postCustomer(newCustomer);
  }

  async putCustomer(updatedCustomer: IFSCustomer): Promise<IFSCustomer> {
    return this._dataProvider.putCustomer(updatedCustomer);
  }

  async postUser(newUser: IFSUsers): Promise<IFSUsers> {
    return this._dataProvider.postUser(newUser);
  }

  async putCustomerUser(updatedCustomerUser: IFSUsers): Promise<IFSUsers> {
    return this._dataProvider.putCustomerUser(updatedCustomerUser);
  }

  async getSiteRoles(): Promise<IFSSiteRoles[]> {
    return this._dataProvider.getSiteRoles();
  }

  async deleteProduct(productId: string): Promise<boolean> {
    return this._dataProvider.deleteProduct(productId);
  }

  async addCustomerNote(note: IFSNote): Promise<IFSNote> {
    return this._dataProvider.addCustomerNote(note);
  }

  async getCustomerNotes(customerId: string): Promise<IFSNote[]> {
    return this._dataProvider.getCustomerNotes(customerId);
  }

  async getUserCustomers(userId: string): Promise<IFSCustomerUsers[]> {
    return this._dataProvider.getUserCustomers(userId);
  }

  async putCustomerUserRoles(
    updatedCustomerUser: IFSCustomerUsers
  ): Promise<IFSCustomerRoles[]> {
    return this._dataProvider.putCustomerUserRoles(updatedCustomerUser);
  }

  async postCustomerUserRoles(
    newCustomerUser: IFSCustomerUsers
  ): Promise<IFSCustomerRoles[]> {
    return this._dataProvider.postCustomerUserRoles(newCustomerUser);
  }

  async getCustomerUsers(customerId: string): Promise<IFSCustomerUsers[]> {
    return this._dataProvider.getCustomerUsers(customerId);
  }

  async getCustomer(customerId: string): Promise<IFSCustomer> {
    return this._dataProvider.getCustomer(customerId);
  }

  async getCustomerMessages(customerId: string): Promise<IFSMessage[]> {
    return this._dataProvider.getCustomerMessages(customerId);
  }

  async sendCustomerMessage(
    customerId: string,
    message: IFSMessage
  ): Promise<IFSMessage> {
    return this._dataProvider.sendCustomerMessage(customerId, message);
  }

  async downloadProductBinary(
    download: IFSAppDownload
  ): Promise<[Blob, Headers]> {
    return this._dataProvider.downloadProductBinary(download);
  }

  async downloadProductDocumentation(
    documentation: IFSDocumentation
  ): Promise<Blob> {
    return this._dataProvider.downloadProductDocumentation(documentation);
  }

  async getLicenseSets(): Promise<IFSLicenseSet[]> {
    return this._dataProvider.getLicenseSets();
  }
  async resetPasswordRequest(email: string): Promise<string> {
    return this._dataProvider.resetPasswordRequest(email);
  }
  async resetPassword(
    email: string,
    password: string,
    resetcode: string
  ): Promise<boolean> {
    return this._dataProvider.resetPassword(email, password, resetcode);
  }
  async generateLicensePack(
    licenseRequest: IFSLicenseRequest
  ): Promise<string> {
    return this._dataProvider.generateLicensePack(licenseRequest);
  }
  async deleteCustomer(updatedCustomer: IFSCustomer): Promise<string> {
    return this._dataProvider.deleteCustomer(updatedCustomer);
  }
  async deleteCustomerUserRoles(
    updatedCustomerUser: IFSCustomerUsers
  ): Promise<any> {
    return this._dataProvider.deleteCustomerUserRoles(updatedCustomerUser);
  }
  async deleteUser(deletedUser: IFSUsers): Promise<any> {
    return this._dataProvider.deleteUser(deletedUser);
  }

  async checkLicenseAgreement(
    licenseAgreement: IFSLicenseAgreement
  ): Promise<number> {
    return this._dataProvider.checkLicenseAgreement(licenseAgreement);
  }

  async addLicenseAgreement(
    licenseAgreement: IFSLicenseAgreement
  ): Promise<IFSLicenseAgreement> {
    return this._dataProvider.addLicenseAgreement(licenseAgreement);
  }

  async getEula(): Promise<string> {
    return this._dataProvider.getEula();
  }

  async toggleDownloadState(download: IFSAppDownload): Promise<IFSAppDownload> {
    return this._dataProvider.toggleDownloadState(download);
  }

  async toggleDocumentationState(download: IFSDocumentation): Promise<IFSDocumentation> {
    return this._dataProvider.toggleDocumentationState(download);
  }

  async getDashboard(): Promise<IFSDashboard> {
    return this._dataProvider.getDashboard();
  }
}
