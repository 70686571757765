import { useContext } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
import { Tooltip } from '@progress/kendo-react-tooltip';
interface DebugOutputProps{
}

export const FSDebugOutputPanel: React.FC<DebugOutputProps> = (props: DebugOutputProps) =>{
  const { isDev,environmentService,localContentService,user,token,refreshToken,useFakeAuth,useFakeData } = useContext(FSAppContext);

  const grinEmoticon=String.fromCodePoint(0x1F600);
  const devilEmoticon=String.fromCodePoint(0x1F608);

  function currentUser(){
    if(user.id && user.email){
      return (<span>USR[{user.id}:{user.email}]</span>);
    }else{
        return (<span>USR[Not set/not logged in]</span>);
    }
  }
  function currentEnv(){
    if(environmentService){
      return (<span>ENV[{environmentService.getEnvironment().NodeEnv}]</span>);
    }else{
        return (<span>ENV[Not set]</span>);
    }
  
  }
  function currentPage(){
    if(localContentService){
      return (<span>PAGE[{localContentService.GetLocalContent().CurrentPage}]</span>);
    }else{
        return (<span>PAGE[Not set]</span>);
    }
  }
  function currentURLs(){
    if(environmentService){
      return (
        <>
        <span>AUTH{useFakeAuth?(<>{devilEmoticon}</>):(<>{grinEmoticon}</>)}[{environmentService?.getEnvironment().AuthUrl}]</span>
        &nbsp;
        <span>DATA{useFakeData?(<>{devilEmoticon}</>):(<>{grinEmoticon}</>)}[{environmentService?.getEnvironment().BaseUrl}]</span>
        </>
      );
    }else{
        return (<span>URL[Not set]</span>);
    }
  }
  
  return (isDev?(
    <div className="fs-debug-output-panel">
        <span>DEV PANEL : {currentUser()} : {currentEnv()} : {currentPage()} : {currentURLs()}</span>&nbsp;
    </div>
  ):(<></>));

}