import { Button } from "@progress/kendo-react-buttons";
import { useContext, useEffect, useState } from "react";
import { Input} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import IFSUsers from "../../types/IFSUsers";
import { IFSSiteRoles,IFSCustomerRoles, IFSRoles, FSCustomerRoles } from "../../types/IFSRoles";
import { FSAppContext } from "../../providers/FSTF.Context";
import { StackLayout } from "@progress/kendo-react-layout";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
export const FSTFUserDetailForm = ({ onSubmit, onCancel,selectedUser,roles,siteRoles,customerId,assignedRole,type}:any) => {     
  
  const { isSiteAdmin } = useContext(FSAppContext);
  const [userDetail, setUserDetail] = useState<IFSUsers>({});

  const [selectedRoles, setSelectedRoles] = useState<IFSCustomerRoles[]>();
  const [selectedSiteRoles, setSelectedSiteRoles] = useState<IFSSiteRoles[]>();
  
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);    
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  
  const [validationSiteRoleError, setValidationSiteRoleError] = useState(false);
  const [validationCompanyRoleError, setValidationCompanyRoleError] = useState(false);

  useEffect(() => {    
    if (Object.keys(selectedUser).length > 0) {
      setUserDetail({...selectedUser});

      let fullSiteRoles=siteRoles.filter((role:IFSSiteRoles)=>selectedUser.siteRoles.includes(role.roleName));
      setSelectedSiteRoles(fullSiteRoles);

      var selectedRoleNames = 
        selectedUser.companyRoles?.filter((companyRole: FSCustomerRoles) => companyRole.companyId == customerId)
          .map((role:IFSCustomerRoles)=>role.roleName);
      
      let fullRoles=roles?.filter((role:IFSCustomerRoles)=>selectedRoleNames?.includes(role.roleName));

      setSelectedRoles(fullRoles);

    }  
    
    else {
      setSelectedSiteRoles(siteRoles.filter((role:IFSSiteRoles)=>role.roleName==='sitelogin'));
      setSelectedRoles(roles?.filter((role:IFSCustomerRoles)=>role.roleName==='companyviewer'));
    }

  }, [selectedUser,roles,siteRoles,customerId,assignedRole]);

  const handleChange = (e:any) => {
    if(e.target.name==="userName"){
      setUserDetail({ ...userDetail, [e.target.name]: e.target.value.toLowerCase() });
    }
    else if(e.target.name==="password"){
      setPassword(e.target.value);
      if(confirmPassword !== '') {
        setPasswordsMatch(e.target.value === confirmPassword);
      }
      setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
    }
    else {
      setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
    }
  };

  const validatePassword = (password: string) => {
    let messages='' ;
    if (password.length < 6 || !/\W/.test(password) || !/\d/.test(password) || !/[A-Z]/.test(password)){
      messages=
      "*Password must have least 6 chars,symbol,digit,upper-lowercase combination";
    }
    return messages;
  };

  const handlePasswordBlur = () => {
    const validationMessage = validatePassword(password);
    setPasswordValidationMessage(validationMessage);
  };

  const handleConfirmPasswordChange = (event:any) => {
    setConfirmPassword(event.target.value);
    setPasswordsMatch(password === event.target.value);
  };
  const handleSubmit = (event:any) => {   
    event.preventDefault(); 
    if(validationSiteRoleError ||selectedSiteRoles===undefined ||selectedSiteRoles?.length===0){
      setValidationSiteRoleError(true);
      return;
    }
    else
    {
      setValidationSiteRoleError(false);
    }
    if(customerId!="" && (validationCompanyRoleError ||selectedRoles===undefined ||selectedRoles?.length===0)){
      setValidationCompanyRoleError(true);
      return;
    }
    else
    {
      setValidationCompanyRoleError(false);
    }
    if(passwordValidationMessage) {
      alert("Please correct the password format before submitting.");
      return; 
    }
    setUserDetail({...userDetail,fullSiteRoles:selectedSiteRoles});
    const modifiedUser : IFSUsers = {
          ...userDetail, 
          fullSiteRoles: selectedSiteRoles, 
          siteRoles: selectedSiteRoles?.map((r: IFSSiteRoles) => r.roleName)
    };
    if(selectedRoles){
      console.log("Has company roles",{"SiteRoles":selectedSiteRoles,"Roles":selectedRoles});
      onSubmit(modifiedUser,selectedRoles);
    }else{
      console.log("No company roles");
      onSubmit(modifiedUser);
    }
  };
  const onSiteRoleSelectionChange=(e:any)=>{
    const newSelectedSiteRoles = e.target.value; 
    setSelectedSiteRoles(newSelectedSiteRoles);   

    setValidationSiteRoleError(newSelectedSiteRoles === undefined || newSelectedSiteRoles.length === 0);
  };
  const onCompanyRoleSelectionChange=(e:any)=>{
    const newSelectedRoles = e.target.value; 
    setSelectedRoles(newSelectedRoles);   

    setValidationCompanyRoleError(newSelectedRoles === undefined || newSelectedRoles.length === 0);
  };
  
  return (
    <Dialog title={type} onClose={onCancel} className="fs-dialog-edit-user">
      
      <form onSubmit={handleSubmit}>
        <StackLayout orientation="vertical">
            <Label editorId="firstName">FirstName:</Label>
            <Input name="firstName" value={userDetail.firstName || ''} onChange={handleChange} required={true} />
            <Label editorId="lastName">LastName:</Label>
            <Input name="lastName" value={userDetail.lastName || ''} onChange={handleChange} />
            <Label editorId="email">Email:</Label>
            <Input name="email" value={userDetail.email || ''} onChange={handleChange} type={"email"} />
            <Label editorId="phoneNumber">Phone:</Label>
            <Input name="phoneNumber" value={userDetail.phoneNumber || ''} onChange={handleChange} />
            <Label editorId="mobile">Mobile:</Label>
            <Input name="mobile" value={userDetail.mobile || ''} onChange={handleChange} />
        </StackLayout>

        { isSiteAdmin && (<>
           <Label editorId="siteRole">Site Roles</Label> 
           <MultiSelect
           name="siteRole"
           data={siteRoles}
           onChange={onSiteRoleSelectionChange}
           value={selectedSiteRoles}
           placeholder="Please select ..."
           textField="roleName"
           dataItemKey="roleId"
         /> 
          {validationSiteRoleError && (
            <div style={{ color: 'red', fontSize: '0.8rem' }}>
              Please select at least one site role.
            </div>
          )}
        </> )}
        { customerId!=="" && (
          <>
          <Label editorId="companyRole">Company Roles</Label>  
          <MultiSelect
          name="companyRole"
          data={roles}
          onChange={onCompanyRoleSelectionChange}
          value={selectedRoles}
          placeholder="Please select ..."
          textField="description"
          dataItemKey="roleId"
        />
        {validationCompanyRoleError && (
                        <div style={{ color: 'red', fontSize: '0.8rem' }}>
                            Please select at least one company role.
                        </div>
                    )} 
        </>
        )}  
        {type==="Add" && (
          <div>
            <div>
              <Label editorId="userName">UserName:</Label>
              <Input name="userName" onChange={handleChange}/>
            </div> 
            <div>
              <Label editorId="password">Password:</Label>
              <Input name="password" 
                  onChange={handleChange} 
                  onBlur={handlePasswordBlur} // Trigger validation on blur
                  required={true} type="password" />
              {passwordValidationMessage && (
                <div style={{ color: 'red' , fontSize: '0.6rem'}}>{passwordValidationMessage}</div>
              )}
            </div>      
            <div>
              <Label editorId="reTypePassword">Confirm Password:</Label>
              <Input name="reTypePassword" onChange={handleConfirmPasswordChange} required={true} type="password" />
            </div> 
          </div>
          )}
          {!passwordsMatch && (
            <div style={{ color: 'red' , fontSize: '0.6rem'}}>*Passwords do not match!</div>
        )}

          <DialogActionsBar>
            <Button type="submit">{type}</Button>
            <Button onClick={onCancel}>Cancel</Button>
          </DialogActionsBar> 
       
      </form>         
    </Dialog>
  );
};


