import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import React, { useState } from "react";
import { StringUtils } from "../../Utilities/FSTF.StringUtils";
import { Button } from "@progress/kendo-react-buttons";
import {chevronDownIcon} from "@progress/kendo-svg-icons";

interface ConfigCardProps {
  title: string;
  children: React.JSX.Element;
  expanded?: boolean;
}

function ConfigCard({ title, children, expanded = false }: ConfigCardProps) {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const toggleCard = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card>
      <CardHeader>
        <div className="card-header-content" onClick={toggleCard}>
          <h2>{StringUtils.toHumanReadable(title)}</h2>
          <Button
            fillMode="flat"
            svgIcon={chevronDownIcon}
            className={`chevron-icon
             ${isExpanded ? "expanded" : ""}`}
          />
        </div>
      </CardHeader>
      <CardBody
        style={{ display: isExpanded ? "block" : "none" }}
        className={`card-body ${isExpanded ? "expanded" : ""}`}
      >
        {children}
      </CardBody>
    </Card>
  );
}

export default ConfigCard;
