import IFSCustomerUsers from "./IFSCustomerUsers";
import { IFSCustomerRoles, IFSSiteRoles } from "./IFSRoles";

export default interface IFSUsers{
    id?:string;
    name?:string;
    email?:string;
    phoneNumber?:string;
    mobile?:string;
    userName?:string;
    password?:string;
    token?:string;  
    refreshToken?:string;
    active?:boolean;
    siteRoles?:string[]; 
    fullSiteRoles?:IFSSiteRoles[];
    companyRoles?:IFSCustomerRoles[];
    testId?:string;
    createdByUserName?:string;
    createdBy?:string;
    createdDate?:string;
    modifiedBy?:string;
    modifiedByUserName?:string;
    modifiedDate?:string;
    lastName?:string;
    firstName?:string;
    data1?:string;
    data2?:string;
    data3?:string;
    notes?:string;
}
export const defaultUserDetails:IFSUsers = {
    id:"",
    name:"",
    email:"",
    phoneNumber:"",
    mobile:"",
    userName:"",
    password:"",
    token:"",
    active:true,
    siteRoles:[],
    testId:""
}