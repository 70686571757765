import React from "react";
import _ from "lodash";
import ConfigEditor from "./FSTF.Config.Editor";
import ConfigCard from "../molecules/FSTF.Config.CardSection";

interface ConfigEditorWrapperProps {
  title: string;
  configData: { [key: string]: any };
  rootPath: string;
  sectionName: string;
  expanded?: boolean;
  onDataChange: (path: string, value: any) => void;
  onAddSection: (
    mode: string,
    path: string,
    sectionTitle: string,
    sectionValue: string,
    section?: any
  ) => void;
  onRemoveSection: (path: string) => void;
}

const ConfigEditorWrapper: React.FC<ConfigEditorWrapperProps> = ({
  title,
  configData,
  rootPath,
  sectionName,
  expanded = false,
  onDataChange,
  onAddSection,
  onRemoveSection,
}) => {
  return (
    <div className="config-editor-wrapper">
      <ConfigCard title={title} expanded={expanded}>
        <ConfigEditor
          rootPath={rootPath}
          sectionName={sectionName}
          configData={configData}
          onChange={onDataChange}
          onAddSection={onAddSection}
          onRemoveSection={onRemoveSection}
        />
      </ConfigCard>
    </div>
  );
};

export default ConfigEditorWrapper;
